import { FunctionsTwoTone as FunctionsIcon } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";

import { ImageButtonBase, Root } from "./SelectAlgorithm.styles";
import {
  ImageBackdrop,
  ImageButton,
  ImageMarked,
  ImageSrc,
  OrgTitle
} from "./common/styles";

const algos = [
  {
    id: "procnew",
    title: "Generate Scan Result",
    initials: "SCRES"
  },
  {
    id: "uploadscres",
    title: "Upload .scres File",
    initials: "SCRES"
  },
  {
    id: "longtasks",
    title: "Long Tasks",
    initials: "Tasks"
  },
  {
    id: "quantification",
    title: "Quantification",
    initials: "Quant"
  }
];

const SelectAlgorithm = () => {
  return (
    <Root>
      <div style={{ width: "100%" }}>
        <Typography
          align="center"
          variant="h3"
          style={{
            color: "grey",
            marginBottom: "10%"
          }}
        >
          Select an algorithm to continue
        </Typography>
      </div>
      {algos.map(algo => (
        <ImageButtonBase
          component={NavLink}
          to={`algorithms/${algo.id}`}
          focusRipple
          key={algo.id}
        >
          <ImageSrc />
          <ImageBackdrop />
          <ImageButton>
            <Typography component="span" variant="h5" color="inherit">
              <FunctionsIcon />
              <ImageMarked />
            </Typography>
          </ImageButton>
          <OrgTitle component="span" variant="subtitle1">
            {algo.title}
          </OrgTitle>
        </ImageButtonBase>
      ))}
    </Root>
  );
};
export default SelectAlgorithm;
