import * as types from "../actions/actionTypes";

import initialState from "./initialState";

function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === "_SUCCESS";
}

export default function ajaxStatusReducer(
  state = initialState.ajaxCallsInProgress,
  action
) {
  if (action.type === types.BEGIN_AJAX_CALL) {
    console.log(`%c ajaxCallsInProgress +1: ${state + 1}`, "color: #FF6433");
    return state + 1;
  } else if (
    action.type === types.AJAX_CALL_ERROR ||
    actionTypeEndsInSuccess(action.type)
  ) {
    console.log(
      `%c ajaxCallsInProgress  -1: ${state > 0 ? state - 1 : state}`,
      "color: #FF6433"
    );
    return state > 0 ? state - 1 : state;
  }
  // console.log(`%c ajaxCallsInProgress: ${state}`,"color: #FF6433")

  return state;
}
