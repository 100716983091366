export default class CameraLocation {
  constructor(location) {
    if (location) {
      this.latitude = +location.latitude || 0;
      this.longitude = +location.longitude || 0;
      this.building = location.building;
      this.floor = location.floor;
    }
  }
  latitude = 0;
  longitude = 0;
  building = "";
  floor = "";

  createFromDBObject(db_location) {}
}
