import { Button, DialogContentText } from "@mui/material";
import { styled } from "@mui/system";

export const Form = styled("form")(({ theme }) => ({
  "& > *": {
    margin: theme.spacing(1)
  }
}));

export const UserId = styled(DialogContentText)({
  marginTop: "-10px",
  background: "#65676e",
  color: "#1e212a",
  padding: "5px"
});

export const DeleteBtn = styled(Button)({
  margin: "10px",
  width: "150px",
  color: "#FF4500",
  border: "0.5px solid #FF4500"
});
