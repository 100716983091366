import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import CameraVersions from "~/components/common/CameraVersions";
import { versionList } from "~/utils/constants";
const PREFIX = "Versions";

const classes = {
  root: `${PREFIX}-root`
};

const StyledTableContainer = styled(TableContainer)({
  [`.${classes.root}`]: {
    height: "100vh"
  }
});

const Versions = ({ cameras }) => {
  return (
    <StyledTableContainer className={classes.root}>
      <Table className={classes.table} stickyHeader size="small">
        <TableHead>
          <TableRow>
            {versionList.map(item => (
              <TableCell key={item} sx={{ backgroundColor: "#000" }}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {cameras.map(camera => (
            <CameraVersions key={camera.deviceId} camera={camera} />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

Versions.propTypes = {
  cameras: PropTypes.array.isRequired
};

export default Versions;
