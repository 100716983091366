export const versionList = [
  "Camera ID",
  "Camera Name",
  " CameraApp Version ",
  " FPGA Version ",
  " Supply Board Version ",
  " edgeDaemon Runtime Version ",
  " edgeAgent Version ",
  " edgeAgent Runtime Status ",
  " edgeAgent Last Start Time ",
  " edgeAgent Last Exit Time ",
  " edgeHub Version ",
  " edgeHub Connected ",
  " edgeHub Runtime Status ",
  " edgeHub Last Start Time ",
  " edgeHub Last Exit Time ",
  " CameraApp Connected ",
  " CameraApp Runtime Status ",
  " CameraApp Last Start Time ",
  " CameraApp Last Exit Time ",
  " CameraApp Last Scan Completed ",
  " Scan Subsystem Status ",
  " Supply Board Status ",
  " RGB Camera Status ",
  " Local Blob Storage Status ",
  " Most Recent Device Twin Sync ",
  " PythonAlgos Connected ",
  " PythonAlgos Version ",
  " AlgoPipeline Version ",
  " AlgoPipeline Last Reset ",
  " AlgoPipeline Frames Since Last Reset ",
  " PythonAlgos Runtime Status ",
  " PythonAlgos Last Start Time ",
  " PythonAlgos Last Exit Time ",
  " Blob Storage Module Connected ",
  " Blob Storage Module Runtime Status ",
  " Blob Storage Module Last Start Time ",
  " Blob Storage Module Last Exit Time "
];

export const initialValues = {
  serialId: "",
  embeddedId: "",
  kuvaAppV: 0.0,
  algoAppV: 0.0,
  hardwareV: 0.0,
  hardwareRev: 0.0,
  osVersion: 0.0
};

export const serialList = [
  "Serial #",
  "Apolis #",
  "Kuva app version",
  "Algo app version",
  "Hardware version",
  "Hardware revision",
  "Os version",
  "Edit",
  "Delete"
];
