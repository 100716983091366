import PropTypes from "prop-types";
import { Tooltip, Fab, CircularProgress } from "@mui/material";
import { CloudDownload as DownloadIcon } from "@mui/icons-material/";

const DownloadButton = ({ isDisabled, downloading, handleDownload }) => {
  return (
    <Tooltip title={downloading ? "Downloading" : "Download"}>
      <div style={{ position: "relative" }}>
        <Fab
          aria-label="download"
          color="primary"
          onClick={handleDownload}
          disabled={isDisabled}
        >
          <DownloadIcon />
        </Fab>
        {downloading && (
          <CircularProgress
            size={68}
            style={{
              position: "absolute",
              top: -6,
              left: -6
            }}
          />
        )}
      </div>
    </Tooltip>
  );
};

DownloadButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleDownload: PropTypes.func.isRequired
};

export default DownloadButton;
