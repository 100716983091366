import { useAuth0 } from "@auth0/auth0-react";
import {
  AccountBox as AccountBoxIcon,
  CameraAlt as CameraAltIcon,
  Code as CodeIcon,
  DashboardRounded as DashboardRoundedIcon,
  ExitToApp as ExitToAppIconIcon,
  LiveHelp as LiveHelpIcon,
  LooksOne as LooksOneIcon,
  LooksTwo as LooksTwoIcon,
  PollRounded as PollRoundedIcon,
  Search as SearchIcon,
  TableChart as TableChartIcon
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { NavLink, Route, Switch } from "react-router-dom";

import { useOrganizations } from "../../../contentProviders/OrganizationsContextProvider";
import { kuvaColors } from "../../../theme";
import Can from "../Can";
import AlgorithmsSelectNav from "../navigation/AlgorithmsSelectNav";
import CameraSelectNav from "../navigation/CameraSelectNav";
import OrganizationSelect from "../OrganizationSelect";

import { classes, DrawerContent, DrawerHeader, Version } from "./style";

const LeftDrawer = ({ cameras }) => {
  const [expanded, setExpanded] = useState(false);
  const { selectedOrg } = useOrganizations();
  const { user } = useAuth0();

  const handleExpanded = panel => () => {
    setExpanded(expanded === panel ? "" : panel);
  };

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={true}
      sx={{
        width: 300,
        flexShrink: 0,
        backgroundColor: "#131313",
        "& .MuiDrawer-paper": {
          width: 300,
          boxSizing: "border-box"
        }
      }}
    >
      <DrawerHeader>
        <OrganizationSelect showAll />
      </DrawerHeader>
      <DrawerContent>
        <div className={classes.navWrapper}>
          <NavLink
            to="/dashboard"
            className={classes.nav}
            activeClassName={classes.selected}
            onClick={handleExpanded(false)}
          >
            <DashboardRoundedIcon className={classes.expansionColoring} />
            <Typography className={classes.expansionColoring}>
              Dashboard
            </Typography>
          </NavLink>
        </div>

        <Accordion
          className={classes.expansionPanel}
          expanded={expanded === "panel1"}
          onChange={handleExpanded("panel1")}
        >
          <AccordionSummary className={classes.expansionPanelSummary}>
            <NavLink
              to="/cameras"
              className={classes.nav}
              activeClassName={classes.selected}
            >
              <CameraAltIcon className={classes.expansionColoring} />
              <Typography className={classes.expansionColoring}>
                Cameras
              </Typography>
            </NavLink>
          </AccordionSummary>
          <AccordionDetails>
            <Switch>
              <Route
                path="/cameras/"
                render={() => (
                  <CameraSelectNav
                    selectedOrg={selectedOrg}
                    cameras={cameras}
                    base="cameras"
                    toRoute="main"
                    menu
                  />
                )}
              />
            </Switch>
          </AccordionDetails>
        </Accordion>
        <Can I="manage" a="Algorithms">
          <Accordion
            className={classes.expansionPanel}
            expanded={expanded === "panel3"}
            onChange={handleExpanded("panel3")}
          >
            <AccordionSummary className={classes.expansionPanelSummary}>
              <NavLink
                to="/algorithms"
                className={classes.nav}
                activeClassName={classes.selected}
              >
                <PollRoundedIcon className={classes.expansionColoring} />
                <Typography className={classes.expansionColoring}>
                  Algorithms
                </Typography>
              </NavLink>
            </AccordionSummary>
            <AccordionDetails>
              <Switch>
                <Route
                  path="/algorithms/:algorithm/:camera"
                  render={() => <CameraSelectNav base={"algorithms/"} back />}
                />
                <Route
                  path="/algorithms/:algorithm"
                  exact
                  render={() => <CameraSelectNav base={"algorithms/"} back />}
                />
                <Route
                  path="/algorithms"
                  render={() => <AlgorithmsSelectNav base="/algorithms" />}
                  exact
                />
              </Switch>
            </AccordionDetails>
          </Accordion>
        </Can>
        <Accordion
          className={classes.expansionPanel}
          expanded={expanded === "panel4"}
          onChange={handleExpanded("panel4")}
        >
          <AccordionSummary className={classes.expansionPanelSummary}>
            <NavLink
              to="/documentation"
              className={classes.nav}
              activeClassName={classes.selected}
            >
              <SearchIcon className={classes.expansionColoring} />
              <Typography className={classes.expansionColoring}>
                Documentation
              </Typography>
            </NavLink>
          </AccordionSummary>
          <AccordionDetails>
            <List component="div" className={classes.nestedList} disablePadding>
              <ListItem button className={classes.nestedListItem}>
                <LiveHelpIcon className={classes.drawerIcon}>
                  <ExitToAppIconIcon className={classes.expansionColoring} />
                </LiveHelpIcon>
                <ListItemText primary="Item One" />
              </ListItem>
              <ListItem button className={classes.nestedListItem}>
                <LiveHelpIcon className={classes.drawerIcon}>
                  <ExitToAppIconIcon className={classes.expansionColoring} />
                </LiveHelpIcon>
                <ListItemText primary="Item Two" />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.expansionPanel}
          expanded={expanded === "usersPanel"}
          onChange={handleExpanded("usersPanel")}
        >
          <AccordionSummary className={classes.expansionPanelSummary}>
            <NavLink
              to="/users"
              className={classes.nav}
              activeClassName={classes.selected}
            >
              <AccountBoxIcon className={classes.expansionColoring} />
              <Typography className={classes.expansionColoring}>
                Users
              </Typography>
            </NavLink>
          </AccordionSummary>
          <AccordionDetails>
            <List component="div" className={classes.nestedList} disablePadding>
              <ListItem
                component={NavLink}
                to="/account"
                button
                className={classes.nestedListItem}
                activeClassName={classes.selected}
              >
                <Avatar
                  style={{ height: 30, width: 30, marginRight: 10 }}
                  alt={user.nickname}
                  src={user.picture}
                />
                <ListItemText primary="Account" />
              </ListItem>
              <ListItem
                component={NavLink}
                to="/logout"
                button
                className={classes.nestedListItem}
              >
                <ListItemIcon
                  className={classes.drawerIcon}
                  style={{ color: kuvaColors.kuvaGray }}
                >
                  <ExitToAppIconIcon className={classes.expansionColoring} />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  style={{ color: kuvaColors.kuvaGray }}
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Can I="manage" a="Debug">
          <Accordion className={classes.expansionPanel}>
            <AccordionSummary className={classes.expansionPanelSummary}>
              <NavLink
                to="/debug"
                className={classes.nav}
                activeClassName={classes.selected}
              >
                <CodeIcon className={classes.expansionColoring} />
                <Typography className={classes.expansionColoring}>
                  Debug
                </Typography>
              </NavLink>
            </AccordionSummary>
          </Accordion>
        </Can>
        <Can I="manage" a="Camera">
          <Accordion className={classes.expansionPanel}>
            <AccordionSummary className={classes.expansionPanelSummary}>
              <NavLink
                to="/versions"
                className={classes.nav}
                activeClassName={classes.selected}
              >
                <TableChartIcon className={classes.expansionColoring} />
                <Typography className={classes.expansionColoring}>
                  Versions
                </Typography>
              </NavLink>
            </AccordionSummary>
            <AccordionDetails>
              <List
                component="div"
                className={classes.nestedList}
                disablePadding
              >
                <ListItem button className={classes.nestedListItem}>
                  <NavLink
                    to="/versions"
                    className={classes.nav}
                    activeClassName={classes.selected}
                  >
                    <LooksOneIcon className={classes.drawerIcon} />
                    <Typography className={classes.expansionColoring}>
                      Versions
                    </Typography>
                  </NavLink>
                </ListItem>
                <ListItem button className={classes.nestedListItem}>
                  <NavLink
                    to="/serial"
                    className={classes.nav}
                    activeClassName={classes.selected}
                  >
                    <LooksTwoIcon className={classes.drawerIcon} />
                    <Typography className={classes.expansionColoring}>
                      Serial
                    </Typography>
                  </NavLink>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Can>
      </DrawerContent>
      <Version>{`v${process.env.REACT_APP_VERSION}`}</Version>
    </Drawer>
  );
};

LeftDrawer.propTypes = {
  cameras: PropTypes.array.isRequired
};

export default LeftDrawer;
