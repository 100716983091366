import produce from "immer";

import * as types from "../actions/actionTypes";

import initialState from "./initialState";

export default function SET_SIGNALR_CONNECTION(
  state = initialState.signalR,
  action
) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_SIGNALR_ISLOADING:
        draft.isLoading = action.isLoading;
        break;

      case types.SET_SIGNALR_CONNECTION:
        draft.connection = action.connection;
        break;

      case types.SET_SUBSCRIPTION_DETAILS:
        draft.subscriptionDetails = action.subscriptionDetails;
        break;

      default:
        break;
    }
  });
}
