import { Typography } from "@mui/material";
import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import OrganizationAPI from "~/apis/OrganizationAPI";
import {
  FormDialog,
  Header,
  List
} from "~/components/account/Users/components";
import UsersDataTable from "~/components/account/Users/components/List/Utils/UsersDataTable";
import { useOrganizations } from "~/contentProviders/OrganizationsContextProvider";

import { initialValues } from "./InitialValues";
import { Root } from "./Users.styles";
import { escapeRegExp, snackBar } from "./Utils";

const Users = () => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedOrg } = useOrganizations();

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]); /* Data source */
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [initialUserFormValues, setInitialUserFormValues] =
    useState(initialValues);
  const [searchText, setSearchText] = useState("");

  /* Handle data changes e.g(Pagination, Filter, Search, ) */
  const [userTableRows, setUserTableRows] = useState(users);

  /* Users - [users] Data source */
  useEffect(() => {
    const source = axios.CancelToken.source();
    let isGetUserSubscribed = true;
    getUsers(selectedOrg, isGetUserSubscribed, source.token);

    return () => {
      isGetUserSubscribed = false;
      source.cancel();
    };
  }, [selectedOrg]);

  /* CRUD side effects - Sync users data to our Data grid/table */
  useEffect(() => {
    setUserTableRows(users);
  }, [users]);

  const requestSearch = searchValue => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = users.filter(row => {
      return Object.keys(row).some(field => {
        return searchRegex.test(row[field].toString());
      });
    });
    setUserTableRows(filteredRows);
  };

  /* Event handlers */
  const onClickEdit = ({ row }) => {
    setInitialUserFormValues({
      id: row?.id,
      name: row?.name,
      email: row?.email,
      roles: row?.app_metadata?.roles?.[0],
      org: selectedOrg?.id,
      nickname: row?.nickname,
      picture: row?.picture
    });
    setOpenFormDialog(true);
  };

  const handleSubmit = payload => {
    if (payload.id) {
      updateUser(payload);
    } else {
      createUser(payload);
    }
  };

  const handleDeleteUser = payload => {
    confirm({
      title: "Delete User",
      description: `Are you really sure you want to delete "${payload?.email}"? This cannot be undone!`
    })
      .then(() => deleteUser(payload))
      .catch(() => console.log("User deletion cancelled."));
  };

  const handleResetPassord = payload => {
    confirm({
      title: "Reset User Password",
      description: `Are you really sure you want to reset "${payload?.email}"`
    })
      .then(() => resetPassword(payload))
      .catch(() => console.log("Reset password cancelled."));
  };

  const handleCancel = (event, reason) => {
    /* The ONLY way to close the edit Dialog is through the cancel button */
    /* disableBackdropClick and disableEscapeKeyDown of ForwardRef(Dialog) are deprecated 
    so we need to make an if statment to prevent these events*/
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return false;
    }
    setInitialUserFormValues(initialValues);
    setOpenFormDialog(false);
  };
  /* end Event Handlers*/

  /* Operations or Side Effects - TODO move to a Middleware/SAGA to interact with the STATE and making HTTP requests */
  const createUser = payload => {
    payload.roles = [payload?.roles];
    setIsLoading(true);
    OrganizationAPI.createUser(selectedOrg?.id, payload)
      .then(res => {
        searchText !== "" && setSearchText("");
        setOpenFormDialog(false);
        setInitialUserFormValues(initialValues);
        setUsers(UsersDataTable.create({ res, users }));
        snackBar(enqueueSnackbar, "User created successfully.");
      })
      .catch(e => {
        console.log(e);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUsers = (org, isGetUserSubscribed, cancelToken) => {
    setIsLoading(true);
    org &&
      OrganizationAPI.listUsers(org?.id, cancelToken)
        .then(result => {
          if (isGetUserSubscribed) {
            setUserTableRows(result?.data);
            setUsers(result?.data);
          }
        })
        .catch(e => {
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  const updateUser = payload => {
    const { id: userId } = payload;
    payload.roles = [payload?.roles];
    setIsLoading(true);
    OrganizationAPI.updateUser(selectedOrg?.id, userId, payload)
      .then(res => {
        searchText !== "" && setSearchText("");
        setOpenFormDialog(false);
        setInitialUserFormValues(initialValues);
        setUsers(UsersDataTable.update({ res, users }));
        snackBar(enqueueSnackbar, "Your changes have been saved.");
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteUser = payload => {
    const { id: userId } = payload;
    setIsLoading(true);
    OrganizationAPI.deleteUser(selectedOrg?.id, userId)
      .then(res => {
        searchText !== "" && setSearchText("");
        setOpenFormDialog(false);
        setInitialUserFormValues(initialValues);
        setUsers(UsersDataTable.delete({ userId, res, users }));
        snackBar(enqueueSnackbar, "Deleted user successfully.");
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetPassword = payload => {
    setIsLoading(true);
    OrganizationAPI.resetPassword(payload?.email)
      .then(res => {
        setOpenFormDialog(false);
        setInitialUserFormValues(initialValues);
        snackBar(
          enqueueSnackbar,
          "Email to reset password has been successfuly sent."
        );
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  /* end Oprations or Side effects */

  return selectedOrg ? (
    <Root>
      <Header
        orgId={selectedOrg?.id}
        orgName={selectedOrg?.orgname}
        orgLogoUrl={selectedOrg?.logoURL}
      />
      <List
        users={userTableRows}
        loading={isLoading}
        setOpenFormDialog={setOpenFormDialog}
        onClickEdit={onClickEdit}
        searchText={searchText}
        requestSearch={requestSearch}
      />
      <FormDialog
        title={initialUserFormValues?.name}
        show={openFormDialog}
        actionLabel={!initialUserFormValues.id ? "Create" : "Change"}
        onClose={handleCancel}
        handleSubmit={handleSubmit}
        initialValues={initialUserFormValues}
        onDeleteUser={handleDeleteUser}
        onResetPassword={handleResetPassord}
        loading={isLoading}
      />
    </Root>
  ) : (
    <Typography style={{ padding: "30px" }}>No Org Selected</Typography>
  );
};

export default Users;
