import { Route, Switch } from "react-router-dom";

import FileDrop from "../fileDrop/FileDrop";

import { Root } from "./Algorithms.styles";
import SelectAlgorithm from "./SelectAlgorithm";
import SelectCamera from "./SelectCamera";
import LongProcesses from "./frames/LongProcesses";
import ProcSettings from "./frames/ProcSettings";
import SelectFrames from "./frames/SelectFrames";
import Quantification from "./quantification/Quantification";

const Algorithms = () => {
  return (
    <Root>
      <Switch>
        <Route
          path="/algorithms/uploadscres"
          render={() => <FileDrop />}
          exact
        />
        <Route
          path="/algorithms/longtasks"
          render={() => <LongProcesses />}
          exact
        />
        <Route
          path="/algorithms/longtasks/:camera"
          render={() => <LongProcesses />}
          exact
        />
        <Route
          path="/algorithms/process/:camera"
          render={() => <SelectFrames goToSet={() => {}} />}
          exact
        />
        <Route
          path="/algorithms/procnew/:camera"
          render={() => <ProcSettings />}
          exact
        />
        <Route
          path="/algorithms/quantification/:camera"
          render={() => <Quantification />}
          exact
        />
        <Route
          path="/algorithms/:algorithm"
          render={() => <SelectCamera />}
          exact
        />
        <Route path="/algorithms" render={() => <SelectAlgorithm />} exact />
      </Switch>
    </Root>
  );
};

Algorithms.propTypes = {};

export default Algorithms;
