import { AbilityBuilder, Ability } from "@casl/ability";

export const AppAbility = Ability;

export default function defineRulesFor(roles) {
  const { can, rules } = new AbilityBuilder(AppAbility);
  if (roles?.includes("superuser")) {
    can("manage", "all");
  } else if (roles?.includes("admin")) {
    can("manage", "Camera");
    can("manage", "Frame");
    can("manage", "User");
  } else if (roles?.includes("collaborator")) {
    // can("read", "Algorithm");
    can("read", "Camera");
    can("read", "Frame");
  } else {
    can("read", "Camera");
    can("read", "Frame");
    can("read", "User");
  }
  return rules;
}

export function buildAbilityFor(user) {
  const roles = user["kcc/roles"];
  return new AppAbility(defineRulesFor(roles), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // detectSubjectType: (object) => object!.type
  });
}

export function updateAbility(ability, user) {
  // const { can, rules } = new AbilityBuilder(AppAbility);
  const roles = user["kcc/roles"];
  ability.update(defineRulesFor(roles));
}
