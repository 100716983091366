import { Table as MUITable } from "@mui/material";
import { styled } from "@mui/system";

const PREFIX = "Serial";

export const classes = { table: `${PREFIX}-table`, add: `${PREFIX}-add` };

export const Table = styled(MUITable)({
  background: "#131313",

  [`.${classes.add}`]: {
    margin: 4,
    width: 20,
    height: 20,
    minHeight: 20,
    "& .MuiSvgIcon-root": {
      fontSize: 18
    }
  }
});
