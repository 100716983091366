import { alpha, styled } from "@mui/material/styles";

const drawerWidth = 300;

const PREFIX = "LeftDrawer";

export const classes = {
  autoCompleteRoot: `${PREFIX}-autoCompleteRoot`,
  autoCompleteTag: `${PREFIX}-autoCompleteTag`,
  autoCompleteInputRoot: `${PREFIX}-autoCompleteInputRoot`,
  label: `${PREFIX}-label`,
  formControl: `${PREFIX}-formControl`,
  select: `${PREFIX}-select`,
  smallSelect: `${PREFIX}-smallSelect`,
  chips: `${PREFIX}-chips`,
  chip: `${PREFIX}-chip`,
  smallLabel: `${PREFIX}-smallLabel`,
  expansionPanel: `${PREFIX}-expansionPanel`,
  expansionColoring: `${PREFIX}-expansionColoring`,
  button: `${PREFIX}-button`,
  searchContainer: `${PREFIX}-searchContainer`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  tabs: `${PREFIX}-tabs`,
  tab: `${PREFIX}-tab`,
  inputInput: `${PREFIX}-inputInput`,
  version: `${PREFIX}-version`,
  expansionPanelSummary: `${PREFIX}-expansionPanelSummary`,
  nav: `${PREFIX}-nav`,
  navWrapper: `${PREFIX}-navWrapper`,
  selected: `${PREFIX}-selected`,
  nestedList: `${PREFIX}-nestedList`,
  nestedListItem: `${PREFIX}-nestedListItem`,
  drawerIcon: `${PREFIX}-drawerIcon`
};

export const DrawerHeader = styled("div")({
  padding: 16
});

export const DrawerContent = styled("div")(({ theme }) => ({
  overflow: "auto",

  [`.${classes.navWrapper}`]: {
    color: "white",
    width: "100%",
    display: "flex",
    padding: "10px 16px",
    fontWeight: "bold",
    textDecoration: "none"
  },
  [`.${classes.autoCompleteRoot}`]: {
    backgroundColor: "#39404B",
    borderRadius: "5px",
    marginBottom: "10px",
    width: "85%",
    "&:hover": {
      backgroundColor: "#515760"
    },
    marginLeft: "10px"
  },
  [`.${classes.autoCompleteTag}`]: {
    color: "white",
    backgroundColor: "grey"
  },
  [`.${classes.autoCompleteInputRoot}`]: {
    color: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "green"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey"
    },
    ".Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    }
  },
  [`.${classes.drawerContent}`]: {
    overflow: "auto",
    height: "100vh"
  },
  [`.${classes.label}`]: {
    color: "white",
    width: "80%",
    marginLeft: "22px",
    marginBottom: "11px",
    marginTop: "35px"
  },
  [`.${classes.formControl}`]: {
    //margin: theme.spacing(1),
    width: drawerWidth * 0.9
  },
  [`.${classes.select}`]: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    marginBottom: "11px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  [`.${classes.smallSelect}`]: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    color: "white",
    marginRight: 0,
    marginLeft: 0,
    marginBottom: "11px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  [`.${classes.chips}`]: {
    display: "flex",
    flexWrap: "wrap"
  },
  [`.${classes.chip}`]: {
    margin: 2
  },
  [`.${classes.smallLabel}`]: {
    color: "white",
    width: "80%",
    marginLeft: "22px",
    marginBottom: "11px"
  },
  [`.${classes.expansionPanel}`]: {
    backgroundColor: "inherit",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    }
    // "&$expanded": {
    //   margin: "auto",
    // },
  },
  [`.${classes.expansionColoring}`]: {
    // color: "white",
    marginLeft: "12px"
  },
  [`.${classes.button}`]: {
    width: drawerWidth * 0.3,
    height: 40,
    margin: "10px",
    color: "white",
    backgroundColor: "#88AB75",
    "&:hover": {
      backgroundColor: "#05BCD6"
    }
  },
  [`.${classes.searchContainer}`]: {
    backgroundColor: "inherit",
    color: "white"
  },
  [`.${classes.search}`]: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginBottom: "11px",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  [`.${classes.searchIcon}`]: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  [`.${classes.inputRoot}`]: {
    color: "inherit"
  },
  [`.${classes.tabs}`]: {
    width: "100%",
    color: "white"
  },
  [`.${classes.tab}`]: {
    borderLeft: "2px solid #DBD56E",
    width: "100%",
    height: "10px"
  },
  [`.${classes.inputInput}`]: {
    //padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  [`.${classes.expansionPanelSummary}`]: {
    margin: "0 !important"
  },
  [`.${classes.nav}`]: {
    display: "flex",
    color: "white",
    fontWeight: "bold",
    textDecoration: "none",
    width: "100%",
    padding: "10px 0"
  },
  [`.${classes.selected}`]: {
    color: theme.palette.primary.main
  },
  [`.${classes.nestedList}`]: {
    width: "100%"
  },
  [`.${classes.nestedListItem}`]: {
    paddingLeft: theme.spacing(4),
    backgroundColor: "inherit"
    // color: "#fff",
  },
  [`.${classes.drawerIcon}`]: {
    color: "inherit",
    minWidth: 40
    // ".selected": {
    //   color: "#FFF  !important"
    // }
  }
}));

export const Version = styled("small")({
  position: "absolute",
  right: 10,
  bottom: 0,
  color: "gray"
});
