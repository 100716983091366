import { useAuth0 } from "@auth0/auth0-react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
  styled
} from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { connect, shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { useStartSignalR } from "@kuva/ui-helpers";

import {
  getCamerasAction,
  setCameraReportedAttributes
} from "~/actions/cameraActions";
import Users from "~/components/account/Users/Users";
import { useOrganizations } from "~/contentProviders/OrganizationsContextProvider";
import { getOrgCameras } from "~/selectors/CameraSelector";
import { disconnect } from "~/utils/events";
import { isEmpty } from "~/utils/objects";
import { findOrganizationAndDescendant, getOrgIds } from "~/utils/organization";

import { darkTheme } from "../../theme";
import Account from "../account/Account";
import Algorithms from "../algorithms/Algorithms";
import CameraSubRoutes from "../cameras/CameraSubRoutes";
import Dashboard from "../dashboard/Loadable";
import Documentation from "../documentation/Documentation";

import DebugPanel from "./DebugPanel";
import LeftDrawer from "./LeftDrawer";
import Serial from "./Serial";
import Versions from "./Versions";
import SnackbarNotification from "./widgets/SnackbarNotification";

const PREFIX = "MainContent";

const classes = {
  content: `${PREFIX}-content`
};

const Root = styled("div")(() => ({
  display: "flex",

  [`.${classes.content}`]: {
    flexGrow: 1,
    marginLeft: LeftDrawer.drawerWidth,
    overflow: "auto",
    backgroundColor: "#242E3B"
  }
}));

const theme = createTheme(darkTheme);

const MainContent = ({ setReportedAttributes, getCameras }) => {
  const { logout } = useAuth0();

  const { organizations, selectedOrg } = useOrganizations();
  const [remainingTime, setRemainingTime] = useState(Number.MAX_SAFE_INTEGER);

  /* Find organization and descendant and return as an Object.*/
  const organizationWithDescendant = useMemo(
    () => findOrganizationAndDescendant(organizations, selectedOrg?.id),
    [selectedOrg]
  );
  /* Return org ids in a flatten array from organizationWithDescendant object
   * e.g(['kuva-usa', 'kuva-massachusetts', 'kuva-texas', 'kuva-lubbok'])
   * or return ['all'] if "All" is selected */
  const orgIds =
    !isEmpty(selectedOrg) &&
    !isEmpty(organizationWithDescendant) &&
    selectedOrg?.id !== "all"
      ? getOrgIds(organizationWithDescendant)
      : ["all"];

  const cameras = useSelector(getOrgCameras(orgIds), shallowEqual);

  const handleOnIdle = event => {
    console.log("user is idle", event);
    disconnect().finally(() => {
      logout({ logoutParams: { returnTo: window.location.origin } });
    });
  };

  const handleOnActive = () => {
    //   console.log("user resumed", event);
  };

  const handleOnAction = event => {
    if (remainingTime < 60) {
      setRemainingTime(Number.MAX_SAFE_INTEGER);
      console.log("user resumed", event);
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: process.env.REACT_APP_TIMEOUT,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const seconds = Math.round(getRemainingTime() / 1000);
      if (seconds < 60) {
        setRemainingTime(seconds);
        console.log("remainingTime", remainingTime);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [getRemainingTime, remainingTime]);

  useEffect(() => {
    // watches selectedOrg & sessionToken changes
    if (selectedOrg) getCameras(selectedOrg?.id);
  }, [selectedOrg]);

  // TODO: remove this after apim is fully implemented and just call useStartSignalR(setReportedAttributes);
  const SignalRView = () => {
    useStartSignalR(setReportedAttributes);
    return <Root></Root>;
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {selectedOrg && <SignalRView />}
        {remainingTime < 60 && (
          <SnackbarNotification
            message={`Logging you out due to inactivity in ${remainingTime} seconds`}
          />
        )}
        <Root>
          <CssBaseline />
          <LeftDrawer cameras={cameras} />
          <main className={classes.content}>
            <Switch>
              <Route path="/dashboard" render={() => <Dashboard />} />
              <Route path="/cameras/:camera" component={CameraSubRoutes} />
              <Route path="/cameras" component={CameraSubRoutes} />
              <Route path="/algorithms" render={() => <Algorithms />} />
              <Route path="/documentation" render={() => <Documentation />} />
              <Route path="/account" render={() => <Account />} />
              <Route path="/users" render={() => <Users />} />
              <Route path="/debug" render={() => <DebugPanel />} />
              <Route
                path="/versions"
                render={() => <Versions cameras={cameras} />}
              />
              <Route path="/serial" render={() => <Serial />} />
              <Redirect exact from="/" to="/dashboard" />
            </Switch>
          </main>
        </Root>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    // cameraTwins: state.cameras,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setReportedAttributes: attr => dispatch(setCameraReportedAttributes(attr)),
    getCameras: attr => dispatch(getCamerasAction(attr))
  };
};

MainContent.propTypes = {
  setReportedAttributes: PropTypes.func.isRequired,
  getCameras: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);
