export const getFloatingNumber = (value = null, to = 1) => {
  const float = parseFloat(value).toFixed(to);

  if (float === "NaN") return "N/A";
  return float;
};

export const writeToCache = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));
export const readFromCache = key =>
  JSON.parse(localStorage.getItem(key)) || null;

export const getPois = (scans = []) => {
  const poiSet = new Set(["All"]);
  scans.forEach(scan => {
    if (scan.poiOrientation || parseInt(scan.poiOrientation) === 0) {
      poiSet.add(parseInt(scan.poiOrientation));
    }
  });

  return Array.from(poiSet);
};

export const getLongTaskTypes = (tasks = []) => {
  const typeSet = new Set(["All", ...tasks.map(t => t.type)]);

  return Array.from(typeSet);
};

const panoWidth = 970;
const rgbWidth = 143.754; //14.82%
const homeWidth = 48.5; //5%

export const convertImgXtoPanPosition = imageX => {
  let ratio = panoWidth / 2 / 180;
  let panPos = (imageX - panoWidth / 2) / ratio;
  return panPos;
};

export const convertPanPositiontoImageX = panPos => {
  let ratio = panoWidth / 2 / 180;
  let imageX = panPos * ratio + panoWidth / 2;
  return imageX;
};

export const handleImgPosition = panPosition => {
  // Convert pan position (-180 -> 180) to image coordinates (0 -> 970)
  let imageX = convertPanPositiontoImageX(panPosition);
  // Center red box
  imageX = imageX - rgbWidth / 2;
  // Handle react-draggable bug:
  // A <Draggable/> component's coordinates start AFTER x previous <Draggable/>s
  // imageX = imageX - index * rgbWidth;
  return Number(imageX.toFixed(0));
};

export const handleHomeImgPosition = panPosition => {
  // Convert pan position (-180 -> 180) to image coordinates (0 -> 970)
  let imageX = convertPanPositiontoImageX(panPosition);
  // Center red box
  imageX = imageX - homeWidth / 2;
  // Handle react-draggable bug:
  // A <Draggable/> component's coordinates start AFTER x previous <Draggable/>s
  //   imageX = imageX - index * rgbWidth;
  return Number(imageX.toFixed(0));
};

export const headCellsAlgo = [
  {
    id: "img",
    numeric: false,
    disablePadding: true,
    label: "Image"
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: true,
    label: "file name"
  },
  {
    id: "createdOn",
    numeric: false,
    disablePadding: true,
    label: "Date (UTC)"
  },
  {
    id: "createdOnTime",
    numeric: false,
    disablePadding: true,
    label: "Time (UTC)"
  }
];

export const headCells = [
  ...headCellsAlgo,
  {
    id: "poiOrientation",
    numeric: false,
    disablePadding: true,
    label: "POI"
  },
  {
    id: "windSpeed",
    numeric: false,
    disablePadding: true,
    label: "Wind Speed (m/s)"
  },
  {
    id: "windDirection",
    numeric: false,
    disablePadding: true,
    label: "Wind Direction (degrees)"
  }
];

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const generateFormData = queryParams => {
  if (!queryParams) {
    return null;
  }
  const formData = new FormData();
  Object.keys(queryParams).forEach(key => {
    formData.append(key, queryParams[key]);
  });

  return formData;
};
