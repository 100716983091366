/* Return org ids in a flatten array from organizationWithDescendant object 
   e.g(['kuva-usa', 'kuva-massachusetts', 'kuva-texas', 'kuva-lubbok']) */
const getOrgIds = ({ id, children }) =>
  children ? [id, ...children.flatMap(getOrgIds)] : id;

/* Find organization and descendant and return as an Object(organizationWithDescendant). */
function findOrganizationAndDescendant(orgs, id) {
  // recursive func
  let i, temp;
  // loop to parent object
  for (i = 0; i < orgs.length; i++) {
    if (orgs[i].id === id) {
      return orgs[i];
    }
    // Find children / descendant
    if (orgs[i].children.length > 0) {
      // Find children recursively
      temp = findOrganizationAndDescendant(orgs[i].children, id);
      if (temp) {
        return temp;
      }
    }
  }
  return null;
}

function moveResourceGroupToTopLevel(orgs) {
  let i,
    temp = [];
  // loop to parent object
  for (i = 0; i < orgs?.length; i++) {
    const parentOrg = { ...orgs[i], children: [] };
    const parentOrgHubConnection =
      orgs[i]?.bootstrap?.REACT_APP_EVENT_HUB_CONNECTION_STRING;
    if (orgs[i]?.children?.length > 0) {
      for (let j = 0; j < orgs[i]?.children.length; j++) {
        const childHubConnection =
          orgs[i]?.children[j]?.bootstrap
            ?.REACT_APP_EVENT_HUB_CONNECTION_STRING;
        /* Move to top-level if the org child has its own event hub connection otherwise retain to parent*/
        if (
          childHubConnection &&
          parentOrgHubConnection !== childHubConnection
        ) {
          temp.push(orgs[i]?.children[j]);
        } else {
          parentOrg.children.push(orgs[i]?.children[j]);
        }
      }
    }
    temp.unshift(parentOrg);
  }
  return temp;
}

function getChildren(org) {
  let children = [
    {
      ...org,
      /* Tree View Components key(s) */
      parent: org?.parent ?? 0,
      droppable: true
    }
  ];

  if (org.children) {
    if (!(org.children instanceof Array)) {
      org.children = [org.children];
    }
    for (let x of org.children) {
      children = children.concat(getChildren(x));
    }
  }

  /* return flat array */
  /* eg. [{ id: "kuva", parent: 0 , ... }, { id: "kuva-usa", parent: "kuva" , ... }] */
  return children;
}

function createOneLevelOrgsArrray(rawOrgs) {
  let orgs = [];
  for (let org of rawOrgs) {
    orgs = orgs.concat(getChildren(org));
  }

  /* Return all orgs as one level array */
  /* eg. [{ id: "kuva", parent: 0 , ... }, { id: "kuva-usa", parent: "kuva" , ... }, ...all orgs and children] */
  return orgs;
}

export {
  findOrganizationAndDescendant,
  getOrgIds,
  moveResourceGroupToTopLevel,
  createOneLevelOrgsArrray
};
