import { styled } from "@mui/material/styles";

const PREFIX = "HorizontalScrollSwirs";

export const classes = {
  imgRow: `${PREFIX}-imgRow`,
  selectedImg: `${PREFIX}-selectedImg`,
  img: `${PREFIX}-img`,
  loadingStatus: `${PREFIX}-loadingStatus`,
  loadingStatusFont: `${PREFIX}-loadingStatusFont`
};

export const Root = styled("div")({
  width: "100%",
  padding: 30,

  [`.${classes.imgRow}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    // justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    height: "100px",
    flexGrow: 1,
    overflow: "auto"
  },
  [`.${classes.selectedImg}`]: {
    height: "90px",
    filter: "brightness(130%)",
    width: "auto",
    display: "flex",
    margin: "5px",
    border: "1px solid white"
  },
  [`.${classes.img}`]: {
    height: "90px",
    width: "auto",
    display: "flex",
    margin: "5px",
    border: "1px solid grey"
  },
  [`.${classes.loadingStatus}`]: {
    backgroundColor: "black",
    opacity: ".7",
    border: "1px solid grey",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    right: 0
  },
  [`.${classes.loadingStatusFont}`]: {
    fontSize: ".5rem"
  }
});
