import { styled } from "@mui/system";

export const SpinnerContainer = styled("div")({
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  position: "fixed"
});

export const Spinner = styled("div")({
  transform: "translate(-50%, -50%)",
  margin: 0,
  position: "absolute",
  top: "50%",
  left: "50%"
});
