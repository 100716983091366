import { useAuth0 } from "@auth0/auth0-react";
import {
  ChevronLeftTwoTone as BackIcon,
  CameraTwoTone as CameraIcon
} from "@mui/icons-material";
import { Avatar, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import clsx from "clsx";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

import { selectDeviceStatus } from "~/selectors/DeviceAttrSelector";

import Loading from "../Loading";

import { LoadingHolder, Root, classes } from "./CameraSelectNav.styles";

const CameraSelectNav = ({
  selectedOrg,
  cameras = [],
  base = "cameras",
  toRoute = "",
  back,
  menu
}) => {
  const { user } = useAuth0();
  const { algorithm, camera } = useParams();
  const deviceStatus = useSelector(selectDeviceStatus, shallowEqual);

  if (!cameras?.length) {
    return (
      <LoadingHolder>
        <Loading />
      </LoadingHolder>
    );
  }
  return (
    <Root component="div" disablePadding>
      {back && (
        <ListItem
          component={NavLink}
          to={`/${base}${camera ? algorithm : ""}`}
          button
          className={classes.nestedListItem}
          activeStyle={{
            // fontWeight: "bold",
            color: "white"
          }}
        >
          <ListItemIcon className={classes.drawerIcon}>
            <BackIcon />
          </ListItemIcon>
          <ListItemText primary="Back" />
        </ListItem>
      )}

      {
        // show only cameras belonging to the selected org

        cameras.map((twin, i) => (
          <ListItem
            key={i}
            component={NavLink}
            to={`/${base}${algorithm || ""}/${twin.deviceId}/${toRoute}`}
            params={{ camera: twin.deviceId }}
            button
            className={classes.nestedListItem}
            activeClassName={classes.selected}
          >
            <ListItemIcon className={classes.drawerIcon}>
              <Avatar
                style={{
                  backgroundColor:
                    deviceStatus[twin?.deviceId]?.connectionState ===
                    "Connected"
                      ? "#8DBB1D"
                      : "lightgray"
                }}
                className={classes.small}
                aria-label="rgb"
              >
                <CameraIcon style={{ color: "black" }} />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={twin.name}
              className={clsx(classes.cameraTitle, classes.cameraTitleSelected)}
            />
            {menu && (
              <ListItemIcon className={classes.drawerIconRight}>
                {/* <MenuIcon /> */}
              </ListItemIcon>
            )}
          </ListItem>
        ))
      }
      {
        // if the user is a super user also show orphaned cameras
        user["kcc/roles"]?.includes("superuser") &&
          selectedOrg?.id === "all" && (
            <>
              {cameras.filter(twin => !twin.orgUUID).length > 0 && (
                <ListItem button activeClassName={classes.selected}>
                  <ListItemText
                    primary={`${
                      cameras.filter(twin => !twin.orgUUID).length
                    } Cameras without org`}
                    className={classes.cameraTitle}
                  />
                </ListItem>
              )}
              {cameras
                .filter(twin => !twin.orgUUID)
                .map((twin, i) => (
                  <ListItem
                    key={i}
                    component={NavLink}
                    // to={(location, prop2) => {console.log("location",location);console.log("prop2",prop2);return({ ...location, pathname: `${location.pathname}/${twin.deviceId}/${toRoute}` })}}

                    to={`/${base}${algorithm || ""}/${
                      twin.deviceId
                    }/${toRoute}`}
                    params={{ camera: twin.deviceId }}
                    button
                    className={classes.nestedListItem}
                    activeClassName={classes.selected}
                  >
                    <ListItemIcon className={classes.drawerIcon}>
                      <Avatar
                        style={{
                          backgroundColor:
                            deviceStatus[twin?.deviceId]?.connectionState ===
                            "Connected"
                              ? "#8DBB1D"
                              : "lightgray"
                        }}
                        className={classes.small}
                        aria-label="rgb"
                      >
                        <CameraIcon style={{ color: "black" }} />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={twin.name}
                      className={classes.cameraTitle}
                    />
                    {menu && (
                      <ListItemIcon className={classes.drawerIconRight}>
                        {/* <MenuIcon /> */}
                      </ListItemIcon>
                    )}
                  </ListItem>
                ))}
            </>
          )
      }
    </Root>
  );
};

CameraSelectNav.propTypes = {
  selectedOrg: PropTypes.object,
  cameras: PropTypes.array,
  base: PropTypes.string.isRequired,
  toRoute: PropTypes.string,
  back: PropTypes.bool,
  menu: PropTypes.bool
};

export default CameraSelectNav;
