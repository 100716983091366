import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

/** @function DeleteDialog
 * The Delete Dialog returns a draggable dialog that confirms a delete function for processed frames.
 * 
 * @param {boolean} show: determines when the dialog box should display
   @param {func} onDelete: function to be called when 'Delete' button is pressed
   @param {func} onCancel: function to be called when 'Cancel' button is pressed
   @param {string} tile: header for the dialog box
   @param {string} text: text for the dialog box
 */

export default function DeleteDialog(props) {
  return (
    <div>
      <Dialog
        open={props.show}
        onClose={props.handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {props.tile}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            role="closeDeleteDialog"
            autoFocus
            onClick={props.onCancel}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            role="deleteButtonDialog"
            onClick={props.onDelete}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteDialog.propTypes = {
  show: PropTypes.bool,
  tile: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};
