class Organization {
  constructor(id, orgname, logoURL, children) {
    this.id = id;
    this.orgname = orgname;
    this.logoURL = logoURL;
    this.children = children;
  }

  toJson() {
    return JSON.stringify(this);
  }

  static deserialize(input) {
    const instance = new Organization(
      input.id,
      input.orgname,
      input.logoURL,
      input.children
    );
    return instance;
  }
}

export default Organization;

// use like:
// let json = {
//   "id": "kuva-usa",
//   "orgname": "Kuva USA",
//   "logoURL": "https://mssdevstorage.blob.core.windows.net/website-images/Kuva_Logo_PMS.png"
// };

// let instance = Organization.deserialize(json);
