import CameraAPI from "../apis/CameraAPI";

import CameraLocation from "./CameraLocation";

export default class Camera {
  constructor(camera) {
    this.name = "";
    this.deviceId = "";
    this.location = new CameraLocation();
    this.orgUUID = "";
    this.status = "";
    this.connectionState = "";
    this.version = 0;
    this.lastTwinUpdate = new Date().getTime();
    this.lastHeartBeat = 0;
    this.lastTwinUpdate = 0;
    this.board_temperature = 0;
    this.sensor_temperature = 0;

    if (camera) {
      this.deviceId = camera?.deviceId;
      this.name = camera?.tags?.name || camera?.deviceId;
      this.location = camera?.tags
        ? new CameraLocation(camera?.tags?.location)
        : {};
      this.orgUUID = camera?.tags?.organization || "";
      this.status = camera?.status;
      this.version = camera?.version;

      this.scanInterval = 0;
      this.connectionState = camera?.connectionState || "Disconnected";
      this.schedule = camera?.tags?.schedule;
    }
    return this;
  }

  createFromDBObject() {}

  // updateReportedAttributes = (reportedAttributes, updateTime) => {
  //   // check if this update is the latest
  //   let updateObject = {};
  //   if (this.lastTwinUpdate < updateTime && reportedAttributes.camera) {
  //     updateObject.scanning = reportedAttributes.camera.scanning || false;
  //     updateObject.illuminating = reportedAttributes.camera.illuminating || false;
  //     if (reportedAttributes.camera.scan_interval)
  //       updateObject.scanInterval = reportedAttributes.camera.scan_interval;

  //     if (reportedAttributes.camera.last_dark_caled)
  //       updateObject.last_dark_caled =
  //         reportedAttributes.camera.last_dark_caled;

  //     if (reportedAttributes.camera.last_known_pan_pos)
  //       updateObject.last_known_pan_pos =
  //         reportedAttributes.camera.last_known_pan_pos;

  //     if (reportedAttributes.camera.stream_rgb)
  //       updateObject.stream_rgb = reportedAttributes.camera.stream_rgb;

  //     if (reportedAttributes.camera.camera_app_version)
  //       updateObject.camera_app_version =
  //         reportedAttributes.camera.camera_app_version;

  //     if (reportedAttributes.camera.fw_versions)
  //       updateObject.fw_versions = reportedAttributes.camera.fw_versions;

  //     updateObject.lastTwinUpdate = updateTime;
  //   }

  //   return updateObject;
  // };

  updateHeartbeat = reportedAttributes => {
    // board_temperature: 0
    // sensor_temperature: -14.23297119140625
    // timestamp: "2020-05-29T03:41:45Z"

    let updateObject = {};
    updateObject.lastHeartBeat = reportedAttributes.dt;
    updateObject.board_temperature =
      reportedAttributes.supply_board_temperature;
    updateObject.sensor_temperature =
      reportedAttributes.sensor_board_temperature;

    updateObject.lastTwinUpdate = new Date(
      reportedAttributes.timestamp
    ).getTime();

    return updateObject;
  };

  /** sends one vertical line from mirror swing for diagnostic purposes */
  linescan = () => {
    //call a direct method
  };

  /** */
  darkcal = () => {
    //request a dark cal
    console.log("darkcal", this.deviceId);
    return CameraAPI.darkcal(this.deviceId);

    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(true);
    //   }, 36000);
    // });
  };

  /** can use for single diagnostic scan  */
  capture = () => {
    //request a single scan

    console.log("capture", this.deviceId);
    return CameraAPI.capture(this.deviceId);
  };

  resetalgo = () => CameraAPI.resetalgo(this.deviceId);

  panleft = amount => {
    console.log("Camera Panning Left", amount, "Steps");
    return CameraAPI.panCamera(this.deviceId, "left", amount);
  };

  panright = amount => {
    console.log("Camera Panning Right", amount, "Steps");
    return CameraAPI.panCamera(this.deviceId, "right", amount);
  };

  pan = (direction, amount) => {
    console.log("Camera Panning", direction.toString(), amount, "Steps");
    return CameraAPI.panCamera(this.deviceId, direction, amount);
  };

  illuminate = () => {
    //toggle using device twin
    console.log("illuminate", this.deviceId);
    return CameraAPI.toggleIlluminator(this.deviceId);
  };

  /** start/stop controls normal scanning function on/off*/
  scan = () => {
    //toggle using device twin
    console.log("scan", this.deviceId);
    return CameraAPI.toggleCameraScan(this.deviceId);
  };

  getState = () => {
    //query device
  };

  getParam = () => {
    //query device
  };

  /**
   * @function setScanningSchedule - Calles the API get request to update the device tags
   *
   * @param {string} onUtc - the time to switch scanning on in the format HH:MM
   * @param {string} offUtc - the time to switch scanning off in the format HH:MM
   *
   * @returns {Promise}
   */
  setScanningSchedule = (onUtc, offUtc) => {
    return CameraAPI.setScanningSchedule(this.deviceId, onUtc, offUtc);
  };

  /**
   * @function setIlluminatingSchedule - Calles the API get request to update the device tags
   *
   * @param {string} onUtc - the time to switch scanning on in the format HH:MM
   * @param {string} offUtc - the time to switch scanning off in the format HH:MM
   *
   * @returns {Promise}
   */
  setIlluminatingSchedule = (onUtc, offUtc) => {
    return CameraAPI.setIlluminatingSchedule(this.deviceId, onUtc, offUtc);
  };

  /**
    @function updateDeviceAttributes
    @description Updates the current camera name and location 
    @author Daniela S.
    @Date 2020-06-09
    @param {string} name - the name of the camera
    @returns {Promise}
  */

  updateDeviceAttributes = (name, organization) => {
    return CameraAPI.updateDeviceAttributes(this.deviceId, {
      name,
      organization
    });
  };

  updateLocation = ({ longitude, latitude }) => {
    return CameraAPI.updateCameraLocation({
      deviceId: this.deviceId,
      longitude,
      latitude
    });
  };

  updateCameraAttributes = attr => ({
    name: attr.name,
    ...(attr.organization && { orgUUID: attr.organization }),
    location: { longitude: attr.longitude, latitude: attr.latitude }
  });
}
