import { styled } from "@mui/system";

export const Root = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  minWidth: 300,
  width: "100%",
  justifyContent: "center",
  alignSelf: "center",
  backgroundColor: "#242E3B"
});
