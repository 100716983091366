import { Warning as WarningIcon } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import SwirCarousel from "./SwirCarousel";

const PREFIX = "ScanResultsForm";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  textField: `${PREFIX}-textField`,
  dense: `${PREFIX}-dense`,
  menu: `${PREFIX}-menu`,
  submitHolder: `${PREFIX}-submitHolder`,
  errorContainer: `${PREFIX}-errorContainer`
};

const Root = styled("div")(({ theme }) => ({
  [`.${classes.root}`]: {
    margin: 50
  },

  [`.${classes.container}`]: {
    display: "flex",
    flexWrap: "wrap"
  },

  [`.${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },

  [`.${classes.dense}`]: {
    marginTop: theme.spacing(2)
  },

  [`.${classes.menu}`]: {
    width: 200
  },

  [`.${classes.submitHolder}`]: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(2)
  },

  [`.${classes.errorContainer}`]: {
    marginTop: 200
  }
}));

const ScanResultsForm = ({ submit, swirs, duration }) => {
  return !swirs?.length ? (
    <Root className={classes.errorContainer}>
      <WarningIcon color="error" fontSize="large" /> <br />
      <h1>No SWIRS Selected!</h1>
    </Root>
  ) : (
    <div className={classes.root}>
      <Formik
        initialValues={{
          selectedBlobContainer: "cloud-results"
        }}
        onSubmit={(values, { setSubmitting }) => {
          // setTimeout(() => {
          submit(values, swirs)
            .then(() => {
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);
            });
          // }, 500);
        }}
        validationSchema={Yup.object().shape({})}
      >
        {props => {
          const { values, handleChange, handleSubmit } = props;

          const handleBlobContainerChange = event => {
            handleChange(event);
          };

          return (
            <form
              onSubmit={handleSubmit}
              className={classes.container}
              id="process_results_form"
            >
              <Grid container spacing={10}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <SwirCarousel swirs={swirs} />
                  <Typography>Duration: {duration / 1000} Seconds</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="standard"
                    className={classes.selectHolder}
                    component="fieldset"
                  >
                    <InputLabel id="sensorConfig-label">
                      Blob Container
                    </InputLabel>
                    <Select
                      variant="standard"
                      className={classes.select}
                      labelId="blobContainerSelect-label"
                      id="selectedBlobContainer"
                      name="selectedBlobContainer"
                      defaultValue={values.selectedBlobContainer}
                      onChange={handleBlobContainerChange}
                    >
                      <MenuItem value="cloud-results">cloud-results</MenuItem>
                      <MenuItem value="cloud-results2">cloud-results2</MenuItem>
                      <MenuItem value="ml-results">ML-results</MenuItem>
                      <MenuItem value="scan-results" disabled>
                        scan-result (disabled)
                      </MenuItem>
                    </Select>
                    {/* <FormHelperText>Help text</FormHelperText> */}
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>

      {/* <MoreResources /> */}
    </div>
  );
};

Formik.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

ScanResultsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  swirs: PropTypes.array.isRequired,
  duration: PropTypes.number.isRequired
};

export default ScanResultsForm;
