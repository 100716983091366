import { Button, Grid, InputLabel, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useState } from "react";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import AlgorithmsAPI from "../../../apis/AlgorithmsAPI";

import { Paper, Picker, PoiInput, Root, Select } from "./ProcSettings.styles";

const settingsInitialValues = {
  odParameters: {
    sensorConfig: "ABDE",
    odHistory: 15,
    minValidPixSum: 0.001,
    ignoreExternalMask: false,
    odOptions: "BillNumber12B_Running_OD_Algorithm"
  },
  imgProcParameters: {
    angleThresh: 15,
    dilationSize: 7,
    imgProcOptions: "ImgMasking_ImprovedSpectralAngleThresh",
    imgProcHistory: 3,
    confPct: 0.7,
    snrMin: 1.5,
    snrMax: 12,
    loosePctMax: 2,
    tightPctMin: 0.25
  },
  detectParameters: {
    blobMeanConfMinThresh: 0.06,
    blobMeanCdMaxThresh: 30000,
    blobMinSize: 15,
    detectOptions: "BlobConfidenceEvaluator"
  }
};

const ProcSettings = ({ cameraTwins }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { camera } = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [poi, setPOI] = useState(0);
  const [selectedBlobContainer, setBlobContainer] = useState("tmp");
  const [settingsJson, setSettingsJson] = useState(settingsInitialValues);

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleBlobContainerChange = e => {
    setBlobContainer(e.target.value);
  };

  const handlePOIChange = e => {
    setPOI(e.target.value);
  };

  const handleEditJson = e => {
    console.log(e);
    setSettingsJson(e.updated_src);
    return true;
  };

  const handleAddJson = e => {
    console.log(e);
    setSettingsJson(e.updated_src);
    return true;
  };

  const handleGo = e => {
    const validate = (
      camera,
      settingsJson,
      selectedBlobContainer,
      startDate,
      endDate
    ) => {
      if (!cameraTwins.map(c => c.deviceId).includes(camera)) {
        // camera not found
        enqueueSnackbar("Camera not found.", { variant: "error" });
        return false;
      }
      if (!selectedBlobContainer) {
        //selected blob container missing
        enqueueSnackbar("Selected blob container missing.", {
          variant: "error"
        });
        return false;
      }
      if (!settingsJson) {
        //settings json missing
        enqueueSnackbar("Settings json missing.", { variant: "error" });
        return false;
      }
      if (startDate > new Date() || endDate > new Date()) {
        // date is later than today
        enqueueSnackbar("Date is later than today.", { variant: "error" });
        return false;
      }
      console.log(endDate.getTime() - startDate.getTime());
      if (endDate.getTime() - startDate.getTime() > 60 * 60 * 24 * 1000) {
        // date is later than today
        enqueueSnackbar("Start and end dates should be within 24h.", {
          variant: "error"
        });
        return false;
      }
      if (startDate >= endDate) {
        // date is later than today
        enqueueSnackbar("Start time is same or later than end date.", {
          variant: "error"
        });
        return false;
      }
      return true;
    };

    console.log(e);
    console.log(settingsJson);
    console.log(selectedBlobContainer);

    if (
      !validate(camera, settingsJson, selectedBlobContainer, startDate, endDate)
    )
      return;

    AlgorithmsAPI.processSwirAndDetect(
      camera,
      startDate.toISOString(),
      endDate.toISOString(),
      {
        ...settingsJson,
        selectedBlobContainer
      },
      poi
    )
      .then(res => {
        console.log(res);
        enqueueSnackbar("Cued processing for 'n' images", {
          variant: "success"
        });

        history.push("/algorithms/longtasks");
      })
      .catch(err => {
        console.error(err);
        // reject(err);
        enqueueSnackbar("Error creating SWIR: " + err.message || err, {
          variant: "error"
        });
      })
      .finally(() => {
        // setSubmitting(false);
      });
  };

  return (
    <Root>
      <Grid container spacing={3}>
        <Grid item xs={3} container spacing={3}></Grid>
        <Grid item xs={6}>
          <Paper>as detection </Paper>
        </Grid>
        <Grid item xs={3} container spacing={3}></Grid>
        <Grid item sm={4} xs={6}>
          <Grid item xs={12} container spacing={3}>
            <Paper>Start - End</Paper>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Paper>
              <Picker
                margin="normal"
                label="START DATE"
                value={new Date(startDate)}
                onChange={handleStartDateChange}
                autoOk={true}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Paper>
              <Picker
                margin="normal"
                label="END DATE"
                value={new Date(endDate)}
                onChange={handleEndDateChange}
                autoOk={true}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Paper>
              <InputLabel id="sensorConfig-label">Blob Container</InputLabel>
              <Select
                variant="standard"
                labelId="blobContainerSelect-label"
                id="selectedBlobContainer"
                name="selectedBlobContainer"
                defaultValue={"tmp"}
                onChange={handleBlobContainerChange}
              >
                <MenuItem value="tmp">tmp</MenuItem>
                <MenuItem value="cloud-results">cloud-results</MenuItem>
                <MenuItem value="cloud-results2">cloud-results2</MenuItem>
                <MenuItem value="ml-results">ML-results</MenuItem>
                <MenuItem value="scan-results" disabled>
                  scan-result (disabled)
                </MenuItem>
              </Select>
            </Paper>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Paper>
              <InputLabel id="sensorConfig-label">POI</InputLabel>
              <PoiInput
                onChange={handlePOIChange}
                type="number"
                inputProps={{ min: -180, max: 180 }}
                value={poi}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Paper>
              <Button variant="outlined" color="primary" onClick={handleGo}>
                Go
              </Button>
            </Paper>
          </Grid>
        </Grid>
        <Grid item sm={8} xs={6}>
          <ReactJson
            src={settingsJson}
            theme="brewer"
            displayObjectSize={false}
            displayDataTypes={true}
            collapsed={3}
            onEdit={handleEditJson}
            onAdd={handleAddJson}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

ProcSettings.propTypes = {
  cameraTwins: PropTypes.array.isRequired
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    cameraTwins: state.cameras
  };
};

export default connect(mapStateToProps)(ProcSettings);
