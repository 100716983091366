import {
  Container,
  Input as MUIInput,
  Paper as MUIPaper,
  Select as MUISelect
} from "@mui/material";
import { styled } from "@mui/system";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export const Root = styled(Container)({
  marginTop: 30,
  marginBottom: 30,
  flexGrow: 1
});

export const Paper = styled(MUIPaper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary
}));

export const Picker = styled(DateTimePicker)({
  minWidth: 180
});

export const Select = styled(MUISelect)({
  minWidth: 180,
  "& input": {
    minWidth: 180
  }
});

export const PoiInput = styled(MUIInput)({
  textAlign: "center",
  "& input": {
    textAlign: "center"
  }
});
