import { Divider, Grid, Slider, TextField, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useState } from "react";

const PREFIX = "ImgProcSettings";

const classes = {
  root: `${PREFIX}-root`,
  formControll: `${PREFIX}-formControll`,
  textField: `${PREFIX}-textField`,
  select: `${PREFIX}-select`
};

const Root = styled("div")(({ theme }) => ({
  [`.${classes.root}`]: {
    width: "100%",
    flexGrow: 1,
    textAlign: "left"
  },

  [`.${classes.formControll}`]: {
    width: "100%"
  },

  [`.${classes.textField}`]: {
    width: "100%"
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1)
  },

  [`.${classes.select}`]: {
    width: "100%"
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1)
  }
}));

// imgProcSettings: {
//   histNumFrames: 15,
//   minThresh: 0.0,
//   minArea: 50,
//   smFiltSize: 15,
//   angleThresh: 40,
//   confPct: 0.6
// }

const ImgProcSettings = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting
  /* and other goodies */
}) => {
  // const {
  //   sensorConfiguration,
  //   histNumFrames,
  //   minValidPixSum
  // } = opticalDepthProcSettings;

  const [history, setHistory] = useState(values.imgProcHistNumFrames);

  const handleHistorySliderChange = (event, x) => {
    event.target.name = "imgProcHistNumFrames";
    event.target.value = x;
    setHistory(x);
    handleChange(event);
  };

  return (
    <Root className={classes.root}>
      <FormControl
        variant="standard"
        className={classes.formControll}
        component="fieldset"
      >
        <FormLabel component="legend">Image Processing Settings</FormLabel>
        <br />
        <br />
        <br />
        <Typography id="history-slider" gutterBottom>
          {`History Frames: ${history}`}
        </Typography>
        <Slider
          id="imgProcHistNumFrames"
          defaultValue={history}
          getAriaValueText={value => `${value} frames`}
          aria-labelledby="history-slider"
          valueLabelDisplay="auto"
          onChange={handleHistorySliderChange}
          step={1}
          marks
          min={1}
          max={20}
        />
        <br /> <br />{" "}
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Typography gutterBottom>Min Threshhold</Typography>
            <TextField // minThresh: 0.0
              id="minThresh"
              label="Min Threshhold"
              disabled
              className={classes.textField}
              error={errors.minThresh && touched.minThresh}
              // className={clsx(classes.textField, errors.email && touched.email) && classes.error)}
              value={values.minThresh}
              type="number"
              name="minThresh"
              margin="normal"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Min Threshhold"
              fullWidth
              helperText={(touched.minThresh && errors.minThresh) || ""}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography gutterBottom>Min Area</Typography>
            <TextField // minArea: 0.0
              id="minArea"
              label="Min Area"
              className={classes.textField}
              error={errors.minArea && touched.minArea}
              // className={clsx(classes.textField, errors.email && touched.email) && classes.error)}
              value={values.minArea}
              type="number"
              name="minArea"
              margin="normal"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Min Area"
              fullWidth
              helperText={(touched.minArea && errors.minArea) || ""}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography gutterBottom>Min Filter Size</Typography>
            <TextField // smFiltSize: 0.0
              id="smFiltSize"
              label="Min Filter Size"
              className={classes.textField}
              error={errors.smFiltSize && touched.smFiltSize}
              // className={clsx(classes.textField, errors.email && touched.email) && classes.error)}
              value={values.smFiltSize}
              type="number"
              name="smFiltSize"
              margin="normal"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Min Filter Size"
              fullWidth
              helperText={(touched.smFiltSize && errors.smFiltSize) || ""}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography gutterBottom>Angle Threshhold</Typography>
            <TextField // angleThresh: 0.0
              id="angleThresh"
              label="Angle Threshhold"
              className={classes.textField}
              error={errors.angleThresh && touched.angleThresh}
              // className={clsx(classes.textField, errors.email && touched.email) && classes.error)}
              value={values.angleThresh}
              type="number"
              name="angleThresh"
              margin="normal"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Angle Threshhold"
              fullWidth
              helperText={(touched.angleThresh && errors.angleThresh) || ""}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography gutterBottom>Confidence Percent</Typography>
            <TextField // confPct: 0.0
              id="confPct"
              label="Confidence Percent"
              className={classes.textField}
              error={errors.confPct && touched.confPct}
              // className={clsx(classes.textField, errors.email && touched.email) && classes.error)}
              value={values.confPct}
              type="number"
              name="confPct"
              margin="normal"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Confidence Percent"
              fullWidth
              helperText={(touched.confPct && errors.confPct) || ""}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography gutterBottom>Confuser Backoff Factor</Typography>
            <TextField
              id="confuserBackFactor"
              label="Confuser Backoff Factor"
              className={classes.textField}
              error={errors.confuserBackFactor && touched.confuserBackFactor}
              // className={clsx(classes.textField, errors.email && touched.email) && classes.error)}
              value={values.confuserBackFactor}
              type="number"
              name="confuserBackFactor"
              margin="normal"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Confidence Percent"
              fullWidth
              helperText={
                (touched.confuserBackFactor && errors.confuserBackFactor) || ""
              }
            />
          </Grid>
        </Grid>
      </FormControl>
      <br />
      <br />
      <Divider />
      <br />
      <br />
      <br />
    </Root>
  );
};

ImgProcSettings.propTypes = {
  values: PropTypes.shape({
    confuserBackFactor: PropTypes.number.isRequired,
    confPct: PropTypes.number.isRequired,
    angleThresh: PropTypes.number.isRequired,
    smFiltSize: PropTypes.number.isRequired,
    minArea: PropTypes.number.isRequired,
    minThresh: PropTypes.number.isRequired,
    imgProcHistNumFrames: PropTypes.number.isRequired
  }),
  errors: PropTypes.shape({
    confuserBackFactor: PropTypes.string,
    confPct: PropTypes.string,
    angleThresh: PropTypes.string,
    smFiltSize: PropTypes.string,
    minArea: PropTypes.string,
    minThresh: PropTypes.string,
    imgProcHistNumFrames: PropTypes.string
  }),
  touched: PropTypes.shape({
    confuserBackFactor: PropTypes.bool,
    confPct: PropTypes.bool,
    angleThresh: PropTypes.bool,
    smFiltSize: PropTypes.bool,
    minArea: PropTypes.bool,
    minThresh: PropTypes.bool,
    imgProcHistNumFrames: PropTypes.bool
  })
};

export default ImgProcSettings;
