import {
  Check as CheckIcon,
  Error as ErrorIcon,
  InfoOutlined as InfoIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import PropTypes from "prop-types";
import { useState } from "react";

const Row = props => {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset"
          }
        }}
      >
        <TableCell>
          {row.fileNames.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.fileNames.length > 0 ? (
            <CheckIcon style={{ color: green[500], fontSize: 40 }} />
          ) : (
            <ErrorIcon style={{ color: red[500], fontSize: 40 }} />
          )}
        </TableCell>
        <TableCell align="right">{row.fileNames.length}</TableCell>
        <TableCell align="right">{row.selectedResultsContainer}</TableCell>
        <TableCell align="right">{row.date}</TableCell>
        <TableCell align="right">{row.time}</TableCell>
        <TableCell align="right">{row.message}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          {row.fileNames.length > 0 && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Scan Results Files uploaded:
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.fileNames.map(name => (
                      <TableRow key={name}>
                        <TableCell component="th" scope="row">
                          {name}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    message: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    fileNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  }).isRequired
};

const CollapsibleTable = ({ data }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "%100",
        minWidth: 600,
        marginBottom: 5
      }}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell align="right">Upload Success</TableCell>
            <TableCell align="right">Results Container</TableCell>
            <TableCell align="right">Upload Date</TableCell>
            <TableCell align="right">Upload Time</TableCell>
            <TableCell align="right">
              <InfoIcon style={{ fontSize: 40 }} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <Row key={i} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CollapsibleTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      selectedResultsContainer: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      fileNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    }).isRequired
  ).isRequired
};

export default CollapsibleTable;
