import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
import SwipeableViews from "react-swipeable-views";

const PREFIX = "FramesCarousel";

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  img: `${PREFIX}-img`
};

const Root = styled("div")(({ theme }) => ({
  [`.${classes.root}`]: {
    width: "100%",
    maxWidth: 800,
    flexGrow: 1
  },

  [`.${classes.header}`]: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },

  [`.${classes.img}`]: {
    height: 255,
    display: "block",
    width: "100%",
    maxWidth: 800,
    overflow: "hidden"
  }
}));

const FramesCarousel = ({ frames }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = frames.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <Root className={classes.root}>
      <Paper square elevation={0} className={classes.header}>
        <Typography>{frames[activeStep].fileName}</Typography>
      </Paper>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {frames.map((frame, index) => (
          <div key={frame.fileName}>
            {Math.abs(activeStep - index) <= 2 && (
              <img
                className={classes.img}
                src={frame.jpg}
                alt={frame.fileName}
              />
            )}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Root>
  );
};

FramesCarousel.propTypes = {
  frames: PropTypes.array.isRequired
};

export default FramesCarousel;
