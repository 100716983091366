import axios from "axios";

class OrganizationAPI {
  constructor() {
    this.baseTime = new Date();
    this.getFramesCallStack = []; // if the REACT_APP_ORG_ENV is set to local, use localhost, otherwise use the cloud server
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_KUVA_API_URL}/organization/v1`,
      timeout: 10000
    });
  }

  static instance = null;

  static Instance = () => {
    if (!this.instance) {
      this.instance = new OrganizationAPI();
    }
    return this.instance;
  };

  // Allow auth header to be initialized after login
  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
    this.axiosInstance.defaults.headers.common["Organization"] = "kuva";
  };

  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
      console.log("OrgAPI url set to: ", this.axiosInstance.defaults.baseURL);
    }
  };

  setBaseOrgHeader = baseOrg => {
    this.axiosInstance.defaults.headers.common["X-Organization"] = baseOrg;
  };

  bootstrapVariables = orgId => {
    console.log("get bootstrap variables for a specific orgId");
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/bootstrap`,
      "color: #9954E3"
    );

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get("bootstrap", {
          timeout: 60000,
          params: {
            orgId: orgId
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  };

  getOrganizations = which => {
    console.log("get orgs for a specific user (by token)");
    console.log(`calling: ${this.baseURL}/organizations/${which ?? ""}`);
    return this.axiosInstance.get(`organizations/${which ?? ""}`);
  };

  listUsers = (orgId, cancelToken) => {
    console.log("get users for a specific organization");
    return this.axiosInstance.get(`users/${orgId}`, {
      cancelToken: cancelToken
    });
  };

  getUser = (orgId, userId) => {
    console.log(`calling: ${this.baseURL}/users/${orgId}/${userId}`);
    return this.axiosInstance.get(`users/${orgId}/${userId}`);
  };

  createUser = (orgId, userData) => {
    console.log("Creating user");
    return this.axiosInstance.post(`users/${orgId}`, userData);
  };

  updateUser = (orgId, userId, userData) => {
    console.log("Updating user");
    return this.axiosInstance.put(`users/${orgId}/${userId}`, userData);
  };

  resetPassword = email => {
    console.log("Resetting password");
    return this.axiosInstance.post(`resetpassword/`, { email });
  };

  deleteUser = (orgId, userId) => {
    console.log("Deleting user");
    return this.axiosInstance.delete(`users/${orgId}/${userId}`);
  };
}

export default OrganizationAPI.Instance();
