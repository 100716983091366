import {
  Check as CheckIcon,
  CloudDownload as DownloadIcon,
  Error as ErrorIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import { green, red, yellow } from "@mui/material/colors";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ReactJson from "react-json-view";

import BlobAPI from "../../../apis/BlobAPI";

import { TableRowRoot } from "./LongProcessTableRow.styles";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const getDownloadLink = async () => {
    try {
      // get the sas token link for this file
      const response = await BlobAPI.getDownloadLink(
        row.resultContainerName,
        row.resultBlobName
      );
      // if the backend returned a url open it in a new tab
      if (typeof response.data === "string")
        window.open(response.data, "_blank");
      else throw new Error("Download URL wrong format");
    } catch (error) {
      console.error("link error:", error);
    }
  };

  const showErrorFields = () => {
    if (row.status === "error") {
      return (
        <TableRow>
          <TableCell component="th" scope="row">
            Error:
          </TableCell>
          <TableCell component="th" scope="row">
            {row.errorMsg ?? "N/A"}
          </TableCell>
        </TableRow>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRowRoot>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.status === "end" ? (
            <CheckIcon style={{ color: green[500], fontSize: 40 }} />
          ) : row.status === "start" ? (
            <CircularProgress style={{ color: yellow[500] }} />
          ) : (
            <ErrorIcon style={{ color: red[500], fontSize: 40 }} />
          )}
        </TableCell>
        {/* <TableCell align="right">{row.status}</TableCell> */}
        <TableCell align="right">{row.deviceId}</TableCell>
        <TableCell align="right">
          {row.startedOn ? moment(row.startedOn).fromNow() : ""}
        </TableCell>
        <TableCell align="right">
          {row.endedOn ? moment(row.endedOn).fromNow() : ""}
        </TableCell>
        <TableCell align="right">
          {row.startedOn && row.endedOn
            ? moment(row.startedOn).from(moment(row.endedOn), true)
            : ""}
        </TableCell>
        <TableCell align="right">
          {row.resultBlobName && (
            <IconButton size="small" onClick={getDownloadLink} target="_blank">
              <DownloadIcon size="large" />
            </IconButton>
          )}

          {/* <DownloadIcon style={{ color: green[500], fontSize: 40 }} /> */}
        </TableCell>
      </TableRowRoot>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          {row.dataJson && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Long Task Values:
                </Typography>
                <Table size="small" aria-label="purchases">
                  {/* <TableHead>
                    <TableRow>
                      <TableCell>name</TableCell>
                    </TableRow>
                  </TableHead> */}
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Last status update:
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.updatedOn}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        Start Date:
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.startDate}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        End Date:
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.endDate}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Target Container:
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.dataJson.selectedBlobContainer ?? "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Job Type:
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.type ?? "Unknown"}
                      </TableCell>
                    </TableRow>
                    <>{showErrorFields()}</>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Download:
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.resultBlobName && (
                          <Button
                            size="small"
                            onClick={getDownloadLink}
                            target="_blank"
                          >
                            {`${row.resultContainerName}${row.resultBlobName}`}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <ReactJson
                  name="settings"
                  src={row.dataJson}
                  theme="brewer"
                  displayObjectSize={false}
                  displayDataTypes={true}
                  collapsed={3}
                />
              </Box>
            </Collapse>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    deviceId: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    dataJson: PropTypes.shape({}).isRequired,
    status: PropTypes.string.isRequired,
    startedOn: PropTypes.string.isRequired,
    updatedOn: PropTypes.string.isRequired,
    endedOn: PropTypes.string.isRequired
  }).isRequired
};

export default Row;
