import { useAuth0 } from "@auth0/auth0-react";
import { useGrowthBook } from "@growthbook/growthbook-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ConfirmProvider } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { HashRouter, Route } from "react-router-dom";

import { useOrganizations } from "~/contentProviders/OrganizationsContextProvider";

import { buildAbilityFor } from "../config/ability";

import { AppError } from "./App.styles";
import AppInitialize from "./account/AppInitialize";
import { AbilityContext } from "./common/Can";
import MainContent from "./common/MainContent";
import Landing from "./landing/Landing";

const ability = buildAbilityFor({ "kcc/roles": "user" });
if (process.env.NODE_ENV !== "production") {
  // expose ability to play around with it in devtools
  window.ability = ability;
}

function App() {
  const featureFlagClient = useGrowthBook();
  const { isAuthenticated, error, logout, user } = useAuth0();
  const { selectedOrg } = useOrganizations();
  const [appInitialized, setAppInitialized] = useState(false);
  const [appInitializing, setAppInitializing] = useState(false);
  const [appError, setAppError] = useState(null);

  useEffect(() => {
    if (!selectedOrg || !user) return;

    featureFlagClient.setAttributes({
      id: user.sub,
      email: user.email,
      organizationId: selectedOrg.id
    });
  }, [featureFlagClient, selectedOrg, user]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="App">
        <AbilityContext.Provider value={ability}>
          <ConfirmProvider>
            <HashRouter>
              <Route
                path="/logout"
                render={() =>
                  logout({
                    logoutParams: {
                      returnTo: window.location.origin
                    }
                  })
                }
              />
              {!isAuthenticated || error || appError ? (
                <>
                  {error ||
                    (appError && (
                      <AppError>
                        <p>⚠️ Oops... {error?.message ?? appError?.message}</p>
                      </AppError>
                    ))}
                  <Landing />
                </>
              ) : !appInitialized || appInitializing ? (
                <AppInitialize
                  appInitialized={appInitialized}
                  setAppError={setAppError}
                  setAppInitialized={setAppInitialized}
                  setAppInitializing={setAppInitializing}
                />
              ) : (
                <MainContent setAppError={setAppError} />
              )}
            </HashRouter>
          </ConfirmProvider>
        </AbilityContext.Provider>
      </div>
    </LocalizationProvider>
  );
}

export default App;
