import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

function getFormInputs(queryParams) {
  if (queryParams === undefined) {
    return null;
  }

  return Object.keys(queryParams).map((name, index) => {
    // console.log(name, queryParams[name]);
    return (
      <input key={index} name={name} type="hidden" value={queryParams[name]} />
    );
  });
}
const FileDownload = ({
  actionPath,
  method = "GET",
  queryParams,
  onDownloadComplete
}) => {
  const ref = useRef(null);

  useEffect(() => {
    console.log(ref);
    ref.current.submit();
    onDownloadComplete();
  }, [onDownloadComplete, ref]);

  // static defaultProps = {
  //   method: "GET"
  // };

  // componentDidMount() {
  //   ReactDOM.findDOMNode(this).submit();
  //   this.props.onDownloadComplete();
  // }

  return (
    <form ref={ref} action={actionPath} className="hidden" method={method}>
      {getFormInputs(queryParams)}
    </form>
  );
};

FileDownload.propTypes = {
  actionPath: PropTypes.string.isRequired,
  method: PropTypes.string,
  onDownloadComplete: PropTypes.func.isRequired,
  queryParams: PropTypes.object
};

export default FileDownload;
