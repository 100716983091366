import { styled } from "@mui/system";

export const AppError = styled("blockquote")({
  position: "absolute",
  zIndex: 99,
  "-webkit-font-smoothing:": "antialiased",
  color: "#fff",
  fontSize: "1rem",
  wordBreak: "break-word",
  fontFamily: '"Roboto, "Helvetica", "Arial", "sans-serif"',
  fontWeight: 400,
  lineHeight: 1.5,
  letterSpacing: "0.00938em",
  boxSizing: "inherit",
  margin: "24px 0",
  padding: "4px 24px",
  borderLeft: "5px solid #ffe564",
  backgroundColor: "rgba(255,229,100,0.2)",
  left: 0,
  bottom: 0
});
