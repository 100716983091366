import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import React from "react";
const PREFIX = "CustomizedSnackbars";

const classes = {
  root: `${PREFIX}-root`,
  bar: `${PREFIX}-bar`,
  message: `${PREFIX}-message`
};

const Root = styled("div")(({ theme }) => ({
  [`.${classes.root}`]: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },

  [`.${classes.bar}`]: {
    // width: '100%'
  },

  [`.${classes.message}`]: {
    // width: '80%'
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomizedSnackbars = ({ message }) => {
  const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return message ? (
    <Root className={classes.root}>
      <Snackbar
        open={true}
        onClose={handleClose}
        className={classes.bar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div>
          <Alert
            severity="error"
            onClose={handleClose}
            className={classes.message}
          >
            {message}
          </Alert>
        </div>
      </Snackbar>
    </Root>
  ) : (
    <div />
  );
};

export default CustomizedSnackbars;
