import { Warning as WarningIcon } from "@mui/icons-material";
import { FormControl, Grid, Slider, Typography } from "@mui/material";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import * as Yup from "yup";

import AlgoStrategySelection from "./AlgoStrategySelection";
import FramesCarousel from "./FramesCarousel";
import ImgProcSettings from "./ImgProcSettings";
import { ErrorContainer, Form, Root } from "./MainForm.styles";
import OpticalDepthProcSettings from "./OpticalDepthProcSettings";
import PipelineSettings from "./PipelineSettings";

const MainForm = ({ submit, selected }) => {
  const [alpha, setAlpha] = useState(80);
  const handleAlphaSliderChange = (event, x) => {
    setAlpha(x);
  };

  return !selected?.length ? (
    <ErrorContainer>
      <WarningIcon color="error" fontSize="large" /> <br />
      <h1>No Images Selected!</h1>
    </ErrorContainer>
  ) : (
    <Root>
      <Formik
        initialValues={{
          overlay_on_color: false,
          overlay_on_rgbcam: false,
          include_od_stats: true,
          debug: false,
          odHistNumFrames: 15,
          minValidPixSum: 0.03,
          sensorConfig: "WBDE",
          imgProcHistNumFrames: 15,
          minThresh: 0.0, //disable
          minArea: 10,
          smFiltSize: 15,
          angleThresh: 35,
          confuserBackFactor: 2.5,
          confPct: 0.7,
          odOptions: "BillNumber12_Running_OD_Algorithm",
          imgProcOptions: "ImgMasking_RunningStats_NonxfmdAngleAndCov"
        }}
        onSubmit={(values, { setSubmitting }) => {
          // this.props.submit()
          // setTimeout(() => {
          submit(values, selected) //submit(values, selected)
            .then(() => {
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);
            });
          // }, 500);
        }}
        validationSchema={Yup.object().shape({
          sensorConfig: Yup.string().required("Required"),
          minValidPixSum: Yup.number()
            .required()
            .positive()
            .min(0, "Too low")
            .max(0.1, "Too high"),
          confuserBackFactor: Yup.number()
            .required()
            .positive()
            .min(1, "Too low")
            .max(3, "Too high"),
          confPct: Yup.number()
            .required()
            .positive()
            .min(0, "Too low")
            .max(1, "Too high"),
          minArea: Yup.number()
            .required()
            .positive()
            .integer()
            .min(0, "Too low")
            .max(50, "Too high"),
          smFiltSize: Yup.number()
            .required()
            .positive()
            .integer()
            .min(3, "Too low")
            .max(33, "Too high"),
          angleThresh: Yup.number()
            .required()
            .positive()
            .integer()
            .min(0, "Too low")
            .max(90, "Too high")
          // userName: Yup.string()
          //   .min(5, "C'mon, your pet's name is longer than that")
          //   .required("Required"),
          // email: Yup.string()
          //   .email("Ey, give us the real thing")
          //   .required("Required"),
          // password: Yup.string().min(
          //   5,
          //   "We pegged you as a little less insecure"
          // )
          //   .required("Required"),
        })}
      >
        {props => {
          const { handleSubmit } = props;
          return (
            <Form id="create_swir_form" onSubmit={handleSubmit}>
              <Grid container spacing={10}>
                <Grid item xs={4}>
                  <FramesCarousel frames={selected} style={{ width: "100%" }} />

                  <AlgoStrategySelection {...props} />
                </Grid>
                <Grid item xs={4}>
                  <OpticalDepthProcSettings {...props} />
                  <br />
                  <PipelineSettings {...props} />

                  <FormControl
                    variant="standard"
                    component="fieldset"
                    sx={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Slider
                      defaultValue={80}
                      getAriaValueText={value => `${value}`}
                      aria-labelledby="alpha-slider"
                      valueLabelDisplay="auto"
                      disabled
                      onChange={handleAlphaSliderChange}
                      step={1}
                      marks
                      min={1}
                      max={100}
                    />
                    <Typography id="discrete-slider" gutterBottom>
                      {`Alpha Adjust: ${alpha}%`}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <ImgProcSettings {...props} />
                  {/* <div className={classes.submitHolder}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </div> */}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      {/* <MoreResources /> */}
    </Root>
  );
};

MainForm.propTypes = {
  submit: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired
};

export default MainForm;
