import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Button, Divider, Typography } from "@mui/material";
import ReactJson from "react-json-view";
import { NavLink } from "react-router-dom";

import { useOrganizations } from "../../contentProviders/OrganizationsContextProvider";
import Loading from "../common/Loading";
import OrganizationSelect from "../common/OrganizationSelect";

import { Root, Spinner } from "./Account.styles";

const Account = () => {
  const { isLoading, user } = useAuth0();
  const { organizations } = useOrganizations();

  if (isLoading || !user) {
    return (
      <Spinner>
        <Loading />
      </Spinner>
    );
  }

  return (
    <Root>
      <div
        style={{
          height: "25%",
          width: "90%",
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <Avatar
          style={{ height: "120px", width: "120px" }}
          alt={user.nickname}
          src={user.picture}
        />
        <div
          style={{
            marginLeft: "2%",
            height: "60%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Typography style={{ fontSize: "60px", fontWeight: "bold" }}>
            {user.name}
          </Typography>
          <div
            style={{
              opacity: ".74",
              height: "10%",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Typography>{user["kcc/roles"]?.[0]}</Typography>
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "10px",
                backgroundColor: user.email_verified ? "#22CC56" : "grey"
              }}
            />
            <Typography>{user.email}</Typography>
          </div>
        </div>
        <NavLink to="/logout">
          <Button
            variant="outlined"
            style={{ position: "absolute", right: "5%", top: "8%" }}
          >
            Logout
          </Button>
        </NavLink>
      </div>
      <Divider variant="middle" />
      <div
        style={{
          height: "75%",
          width: "90%",
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around"
        }}
      >
        <div
          style={{
            height: "97%",
            width: "45%",
            overflow: "auto",
            marginTop: "3%"
          }}
        >
          <Typography style={{ fontSize: "26px", fontWeight: "bold" }}>
            Organizations
          </Typography>

          <OrganizationSelect />
        </div>
        <div style={{ height: "100%", width: "45%", overflow: "auto" }}>
          <ReactJson theme="chalk" src={user} />
          <Divider variant="middle" />
          <ReactJson theme="chalk" src={organizations} />
        </div>
      </div>
    </Root>
  );
};

export default Account;
