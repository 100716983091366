import produce from "immer";

import * as types from "../actions/actionTypes";

import initialState from "./initialState";

export default function deviceAttrReducer(
  state = initialState.deviceAttribute,
  action
) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_IS_LOADING_CAMERA_ATTR:
        draft.isLoading = action.isLoading;
        break;

      case types.FETCH_REPORTED_SUCCESS:
        draft.reported = action.reported;
        break;

      case types.UPSERT_REPORTED_SUCCESS: {
        // get the data from the message
        const deviceId = action.reported.deviceId;
        console.log("updating new camera attributes for device: ", deviceId);
        console.log("+++++++++++++++++++++++++++++++++++++++++\n");
        // find the camera instance to update
        const toUpdate = draft.reported.find(cam => cam.deviceId === deviceId);
        // Support new format
        const newReportedAtrr = {
          deviceId: deviceId,
          ...action.reported?.properties?.reported
        };
        const updated = toUpdate
          ? { ...toUpdate, ...newReportedAtrr }
          : newReportedAtrr;
        //send the updated camera instance back to redux to handle
        draft.reported = [
          ...draft.reported.filter(
            prev => prev.deviceId !== action.reported.deviceId
          ),
          updated
        ];
        break;
      }

      case types.SET_INITIAL_REPORTED_TELEMETRY: {
        // Set initial telemetry data
        draft.telemetry = action.telemetry;
        break;
      }

      case types.UPSERT_REPORTED_TELEMETRY: {
        // upsert telemetry
        draft.telemetry = { ...draft.telemetry, ...action.telemetry };
        break;
      }

      case types.SET_INITIAL_DEVICE_STATUS: {
        // Set initial device status
        draft.deviceStatus = action.deviceStatus;
        break;
      }

      case types.UPSERT_REPORTED_DEVICE_STATUS: {
        // upsert device status
        draft.deviceStatus = { ...draft.deviceStatus, ...action.deviceStatus };
        break;
      }

      case types.SET_INITIAL_SCAN_ACTION_STATUS: {
        draft.deviceAction.scan = { ...action.deviceAction };
        break;
      }

      case types.SET_SCAN_ACTION_STATUS: {
        draft.deviceAction.scan = {
          ...draft.deviceAction.scan,
          ...action.deviceAction
        };
        break;
      }

      case types.SET_INITIAL_ILLUMINATE_ACTION_STATUS: {
        draft.deviceAction.illuminate = { ...action.deviceAction };
        break;
      }
      case types.SET_ILLUMINATE_ACTION_STATUS: {
        draft.deviceAction.illuminate = {
          ...draft.deviceAction.illuminate,
          ...action.deviceAction
        };
        break;
      }

      default:
        break;
    }
  });
}
