import { FunctionsTwoTone as FunctionsIcon } from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const PREFIX = "AlgorithmsSelectNav";

const classes = {
  root: `${PREFIX}-root`,
  selected: `${PREFIX}-selected`,
  drawerIcon: `${PREFIX}-drawerIcon`,
  nestedListItem: `${PREFIX}-nestedListItem`,
  selected2: `${PREFIX}-selected2`
};

const StyledList = styled(List)(({ theme }) => ({
  [`.${classes.drawerIcon}`]: {
    color: "inherit"
    // "&.selected": {
    //   color: "#FFF  !important"
    // }
  },

  [`.${classes.nestedListItem}`]: {
    paddingLeft: theme.spacing(4)
  },

  [`.${classes.selected2}`]: {
    backgroundColor: "#1F45A5",
    color: "#FFF !important"
  }
}));

const StyledListItem = ListItem;

const AlgorithmsSelectNav = ({ base }) => {
  return (
    <StyledList component="div" disablePadding>
      <StyledListItem
        component={NavLink}
        to={`${base}/procnew`}
        button
        className={classes.nestedListItem}
        activeClassName={classes.selected}
        classes={{
          root: classes.root,
          selected: classes.selected
        }}
      >
        <ListItemIcon className={classes.drawerIcon}>
          <FunctionsIcon />
        </ListItemIcon>
        <ListItemText primary="Generate Scan Result" />
      </StyledListItem>
      <StyledListItem
        component={NavLink}
        to={`${base}/uploadscres`}
        button
        className={classes.nestedListItem}
        activeClassName={classes.selected}
        classes={{
          root: classes.root,
          selected: classes.selected
        }}
      >
        <ListItemIcon className={classes.drawerIcon}>
          <FunctionsIcon />
        </ListItemIcon>
        <ListItemText primary="Upload Offline .scres" />
      </StyledListItem>

      <StyledListItem
        component={NavLink}
        to={`${base}/longtasks`}
        button
        className={classes.nestedListItem}
        activeClassName={classes.selected}
        classes={{
          root: classes.root,
          selected: classes.selected
        }}
      >
        <ListItemIcon className={classes.drawerIcon}>
          <FunctionsIcon />
        </ListItemIcon>
        <ListItemText primary="Show Long Tasks" />
      </StyledListItem>

      <StyledListItem
        component={NavLink}
        to={`${base}/quantification`}
        button
        className={classes.nestedListItem}
        activeClassName={classes.selected}
        classes={{
          root: classes.root,
          selected: classes.selected
        }}
      >
        <ListItemIcon className={classes.drawerIcon}>
          <FunctionsIcon />
        </ListItemIcon>
        <ListItemText primary="Quantification" />
      </StyledListItem>
    </StyledList>
  );
};

AlgorithmsSelectNav.propTypes = {
  base: PropTypes.string.isRequired
};

export default AlgorithmsSelectNav;
