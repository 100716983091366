import PropTypes from "prop-types";
import { Tooltip, Fab, CircularProgress } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material/";

const DeleteButton = ({ isDisabled, deleting, handleDelete }) => {
  return (
    <Tooltip title={deleting ? "Deleting" : "Delete"}>
      <div style={{ position: "relative" }}>
        <Fab
          aria-label="delete"
          color="primary"
          onClick={handleDelete}
          disabled={isDisabled}
        >
          <DeleteIcon />
        </Fab>
        {deleting && (
          <CircularProgress
            size={68}
            style={{
              position: "absolute",
              top: -6,
              left: -6
            }}
          />
        )}
      </div>
    </Tooltip>
  );
};

DeleteButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default DeleteButton;
