import { Table, TableCell } from "@mui/material";
import { styled } from "@mui/system";

export const BaseTable = styled(Table)({
  minWidth: 750
});

export const BaseFirstTableCell = styled(TableCell)({
  paddingLeft: 40,
  paddingRight: 20
});

export const BaseLastTableCell = styled(TableCell)({
  paddingRight: 40
});

export const BaseTableThumbnail = styled("img")({
  height: 150
});
