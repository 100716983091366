import { TableCell, TableRow } from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useState } from "react";

const CameraVersions = ({ camera }) => {
  const [PythonAlgos, setPythonAlgos] = useState();
  const [EdgeAgent, setEdgeAgent] = useState();
  const [EdgeHub, setEdgeHub] = useState();
  const [CameraApp, setCameraApp] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const [Azure, setAzure] = useState();
  // const [deviceTwin, setDeviceTwin] = useState();

  /** Whenever the camera changes, get the module twin from the API */
  // useEffect(() => {
  //   if(!camera?.deviceId) return
  //   CameraAPI.getModuleTwinForCamera(camera.deviceId, "$edgeAgent")
  //     .then((response) =>
  //       setEdgeAgent(response?.data?.version?.version && response.data)
  //     )
  //     .catch(() =>
  //       enqueueSnackbar(`Couldn't get edgeAgent for ${camera.deviceId}`, {
  //         variant: "warning",
  //       })
  //     )
  //   CameraAPI.getModuleTwinForCamera(camera.deviceId, "$edgeHub")
  //     .then((response) =>
  //       setEdgeHub(response?.data?.version?.version && response.data)
  //     )
  //     .catch(() =>
  //       enqueueSnackbar(`Couldn't get edgeHub for ${camera.deviceId}`, {
  //         variant: "warning",
  //       })
  //     )
  //   CameraAPI.getModuleTwinForCamera(camera.deviceId, "CameraApp")
  //     .then((response) =>
  //       setCameraApp(response?.data?.camera_app_version && response.data)
  //     )
  //     .catch(() =>
  //       enqueueSnackbar(`Couldn't get CameraApp for ${camera.deviceId}`, {
  //         variant: "warning",
  //       })
  //     )
  //   CameraAPI.getModuleTwinForCamera(camera.deviceId, "PythonAlgos")
  //     .then((response) =>
  //       setPythonAlgos(response?.data?.app_version && response.data)
  //     )
  //     .catch(() =>
  //       enqueueSnackbar(`Couldn't get PythonAlgos for ${camera.deviceId}`, {
  //         variant: "warning",
  //       })
  //     )
  //   // CameraAPI.getModuleTwinForCamera(camera, "azureblobstorageoniotedge")
  //   //   .then(response => setAzure(response.data))
  //   //   .catch(() => snackbar.showMessage("Couldn't get Azure blob storage"));
  // }, [camera?.deviceId])

  return (
    <TableRow
      style={{
        backgroundColor: "#181818"
      }}
    >
      <TableCell
        component="th"
        scope="row"
        style={{
          position: "sticky",
          left: 0,
          backgroundColor: "#181818"
        }}
      >
        {camera.deviceId}
      </TableCell>
      <TableCell>{camera?.name}</TableCell>
      <TableCell>{CameraApp?.camera_app_version}</TableCell>
      <TableCell>{CameraApp?.fpga_fw?.current_version}</TableCell>
      <TableCell>{CameraApp?.supply_board_fw?.current_version}</TableCell>
      <TableCell>{EdgeAgent?.runtime?.platform?.version}</TableCell>
      <TableCell>{EdgeAgent?.version?.version}</TableCell>
      <TableCell>
        {EdgeAgent?.systemModules?.edgeAgent?.runtimeStatus}
      </TableCell>
      <TableCell>
        {EdgeAgent?.systemModules?.edgeAgent?.lastStartTimeUtc}
      </TableCell>
      <TableCell>
        {EdgeAgent?.systemModules?.edgeAgent?.lastExitTimeUtc}
      </TableCell>
      <TableCell>{EdgeHub?.version?.version}</TableCell>
      <TableCell>{/*EdgeHub connectionState FIXME*/}</TableCell>
      <TableCell>{EdgeAgent?.systemModules?.edgeHub?.runtimeStatus}</TableCell>
      <TableCell>
        {EdgeAgent?.systemModules?.edgeHub?.lastStartTimeUtc}
      </TableCell>
      <TableCell>
        {EdgeAgent?.systemModules?.edgeHub?.lastExitTimeUtc}
      </TableCell>
      <TableCell>{/*CameraApp connectionState FIXME*/}</TableCell>
      <TableCell>{EdgeAgent?.modules?.CameraApp?.runtimeStatus}</TableCell>
      <TableCell>{EdgeAgent?.modules?.CameraApp?.lastStartTimeUtc}</TableCell>
      <TableCell>{EdgeAgent?.modules?.CameraApp?.lastExitTimeUtc}</TableCell>
      <TableCell>{CameraApp?.last_scan_completed}</TableCell>
      <TableCell>{CameraApp?.scan_system_status?.msg}</TableCell>
      <TableCell>{CameraApp?.supply_board_status?.msg}</TableCell>
      <TableCell>{CameraApp?.rgb_cam_status?.msg}</TableCell>
      <TableCell>
        {CameraApp?.local_blob_store_connected ? "Connected" : "Disconnected"}
      </TableCell>
      <TableCell>{camera?.$metadata?.$lastUpdated}</TableCell>
      <TableCell>{/*PythonAlgos connectionState FIXME*/}</TableCell>
      <TableCell>{PythonAlgos?.app_version}</TableCell>
      <TableCell>{PythonAlgos?.algos_version}</TableCell>
      <TableCell>{PythonAlgos?.lastReset}</TableCell>
      <TableCell>{PythonAlgos?.numFramesSinceReset}</TableCell>
      <TableCell>{EdgeAgent?.modules?.PythonAlgos?.runtimeStatus}</TableCell>
      <TableCell>{EdgeAgent?.modules?.PythonAlgos?.lastStartTimeUtc}</TableCell>
      <TableCell>{EdgeAgent?.modules?.PythonAlgos?.lastExitTimeUtc}</TableCell>
      <TableCell>{/*Azure connectionState FIXME*/}</TableCell>
      <TableCell>
        {EdgeAgent?.modules?.azureblobstorageoniotedge?.runtimeStatus}
      </TableCell>
      <TableCell>
        {EdgeAgent?.modules?.azureblobstorageoniotedge?.lastStartTimeUtc}
      </TableCell>
      <TableCell>
        {EdgeAgent?.modules?.azureblobstorageoniotedge?.lastExitTimeUtc}
      </TableCell>
    </TableRow>
  );
};

CameraVersions.propTypes = {
  camera: PropTypes.shape({
    deviceId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    "$metadata?": PropTypes.shape({
      $lastUpdated: PropTypes.string
    })
  })
};

export default CameraVersions;
