import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/system";

export const Root = styled("div")({
  width: "75%",
  textAlign: "center"
});

export const ImageButtonBase = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 100,
  width: "20%",
  paddingTop: "20%",
  marginLeft: "5%",
  marginBottom: "5%",
  borderRadius: "15px",
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100
  },
  "&:hover, &$focusVisible": {
    zIndex: 1,
    "& $imageBackdrop": {
      opacity: 0.15
    },
    "& $imageMarked": {
      opacity: 0
    },
    "& $orgTitle": {
      opacity: 1
    }
  }
}));
