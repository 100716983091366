import { useFeatureIsOn } from "@growthbook/growthbook-react";
import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useState } from "react";

import * as OrganizationActions from "~/actions/OrganizationActions";
import OrganizationAPI from "~/apis/OrganizationAPI";
import { setBaseURIs } from "~/apis/api";
import { flags } from "~/constants/feature-flags";

import Organization from "../models/Organization";

export const OrganizationsContext = createContext({});
export const useOrganizations = () => useContext(OrganizationsContext);

export const OrganizationsProvider = props => {
  const isGatewayFeatureOn = useFeatureIsOn(flags.USE_GATEWAY);
  const [organizations, setOrganizations] = useState();

  const [selectedOrg, setSelectedOrg] = useState();

  const handleSelectOrg = useCallback(
    async org => {
      await OrganizationActions.getBaseOrganization(org.id);

      return new Promise((resolve, reject) => {
        if (!isGatewayFeatureOn) {
          OrganizationAPI.bootstrapVariables(org.id)
            .then(bootstrapRes => {
              setBaseURIs(isGatewayFeatureOn, bootstrapRes.data);
              setSelectedOrg(Organization.deserialize(org));
              localStorage.setItem("selected_org", JSON.stringify(org));
              resolve(org);
            })
            .catch(err => {
              console.error("set bootstrap vars failed: ", err);
              reject(err);
            });
        } else {
          setSelectedOrg(org);
          localStorage.setItem("selected_org", JSON.stringify(org));
          resolve(org);
        }
      });
    },
    [isGatewayFeatureOn]
  );

  return (
    <OrganizationsContext.Provider
      value={{
        organizations,
        setOrganizations,
        selectedOrg,
        setSelectedOrg: handleSelectOrg
      }}
    >
      {props.children}
    </OrganizationsContext.Provider>
  );
};

OrganizationsProvider.propTypes = {
  children: PropTypes.object
};
