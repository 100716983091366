import { InputLabel, MenuItem, Typography } from "@mui/material";
import { useCallback } from "react";

import { Select, SelectHolder, Wrapper } from "./AlgoStrategySelection.styles";

const algoOptions = [
  { name: "Basic OD Algorithm", value: "BasicODAlgorithm", default: false },
  {
    name: "Bill Number 11B Running OD Algorithm",
    value: "BillNumber11B_Running_OD_Algorithm",
    default: false
  },
  {
    name: "OD Algorithm 11B Running With MOG BSFilter",
    value: "ODAlg11B_Running_With_MOG_BSFilter",
    default: false
  },
  {
    name: "Bill Number 12 Running OD Algorithm",
    value: "BillNumber12_Running_OD_Algorithm",
    default: true
  }
];

const imgProcOptions = [
  { name: "None", value: "None", default: false },
  {
    name: "Basic Image Processing Algorithm",
    value: "BasicImgProcAlgorithm",
    default: false
  },
  {
    name: "Image Masking Running Sptl Spctl Cov And Spctl Angle Thresh",
    value: "ImgMasking_Running_SptlSpctlCovAndSpctlAngleThresh",
    default: false
  },
  {
    name: "Image Masking Running Stats Nonxfmd Angle And Cov",
    value: "ImgMasking_RunningStats_NonxfmdAngleAndCov",
    default: true
  }
];

const AlgoStrategySelection = ({ values, handleChange }) => {
  const handleAlgoChange = useCallback(
    event => {
      // setSelectedAlgo(event.target.value)

      handleChange(event);
    },
    [handleChange]
  );

  const handleImgProcChange = useCallback(
    event => {
      // setSelectedImgProc(event.target.value)
      handleChange(event);
    },
    [handleChange]
  );

  return (
    <Wrapper>
      <Typography variant="body2">Select OD Strategy</Typography>
      <SelectHolder variant="standard" component="fieldset">
        <InputLabel id="sensorConfig-label">Algorithm</InputLabel>
        <Select
          labelId="odOptions-label"
          id="odOptions"
          name="odOptions"
          color={"secondary"}
          variant="outlined"
          defaultValue={algoOptions[3].value}
          onChange={handleAlgoChange}
        >
          {algoOptions.map(opt => (
            <MenuItem key={opt.name} value={opt.value}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </SelectHolder>
      <Typography variant="body2">Select Img Processing Strategy </Typography>
      <SelectHolder variant="standard" component="fieldset">
        <InputLabel id="sensorConfig-label">Algorithm</InputLabel>
        <Select
          labelId="imgProcOptions-label"
          id="imgProcOptions"
          name="imgProcOptions"
          color={"secondary"}
          variant="outlined"
          defaultValue={imgProcOptions[3].value}
          onChange={handleImgProcChange}
        >
          {imgProcOptions.map(opt => (
            <MenuItem key={opt.name} value={opt.value}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </SelectHolder>
    </Wrapper>
  );
};

export default AlgoStrategySelection;
