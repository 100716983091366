import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Fab,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import {
  deleteInstalledDevice,
  getAllInstalledDevices
} from "~/actions/deviceAction";
import DeleteDialog from "~/components/common/dialogs/DeleteDialog";
import DialogContainer from "~/components/common/dialogs/DialogContainer";

import { serialList } from "./../../../utils/constants";
import SerialForm from "./SerialForm";
import { classes, Table } from "./style";

export default function Serial() {
  const { enqueueSnackbar } = useSnackbar();

  const [deviceRows, setDeviceRows] = useState([]);
  const [isDeleteShowing, setIsDeleteShowing] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({});

  useEffect(() => {
    getDevices();
  }, []);

  const handleDelete = async () => {
    const { serialId, embeddedId } = selectedDevice;
    const isDeleted = await deleteInstalledDevice(selectedDevice);
    if (!isDeleted)
      return enqueueSnackbar("Something went wrong", {
        variant: "error"
      });
    const filtered = deviceRows.filter(
      d => !(d.serialId === serialId && d.embeddedId === embeddedId)
    );
    setDeviceRows(filtered);
    setIsDeleteShowing(false);
    enqueueSnackbar("Delete successfull", {
      variant: "success"
    });
  };

  const toggleDeleteDialog = () => {
    setIsDeleteShowing(isShow => !isShow);
  };

  const openDeleteDialog = ({ serialId, embeddedId }) => {
    setSelectedDevice({ serialId, embeddedId });
    toggleDeleteDialog();
  };

  const getDevices = async () => {
    setDeviceRows(await getAllInstalledDevices());
  };

  const toggleAddDialog = row => {
    setSelectedDevice(row);
    setIsAdd(value => !value);
  };

  const toggleAddDialogInForm = values => {
    console.log("values,selectedDevice", values, selectedDevice);
    if (values && !selectedDevice) setDeviceRows(rows => [...rows, values]);
    if (values && selectedDevice) {
      setDeviceRows(rows =>
        rows.map(r =>
          r.embeddedId === values.embeddedId && r.serialId === values.serialId
            ? values
            : r
        )
      );
    }
    setIsAdd(value => !value);
  };

  return (
    <TableContainer component={Paper}>
      <DialogContainer
        show={isAdd}
        handleClose={toggleAddDialog}
        onCancel={toggleAddDialog}
      >
        <SerialForm
          toggleAddDialog={toggleAddDialogInForm}
          row={selectedDevice}
        />
      </DialogContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {serialList.map(val => (
              <TableCell key={val} align="right">
                {val}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {deviceRows.map(row => (
            <TableRow key={row.serialId + row.embeddedId}>
              <TableCell align="right">{row.serialId}</TableCell>
              <TableCell align="right">{row.embeddedId}</TableCell>
              <TableCell align="right">{row.kuvaAppV}</TableCell>
              <TableCell align="right">{row.algoAppV}</TableCell>
              <TableCell align="right">{row.hardwareV}</TableCell>
              <TableCell align="right">{row.hardwareRev}</TableCell>
              <TableCell align="right">{row.osVersion}</TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => toggleAddDialog(row)}
                  aria-label="delete"
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => openDeleteDialog(row)}
                  aria-label="delete"
                  size="large"
                >
                  <HighlightOffIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DeleteDialog
        show={isDeleteShowing}
        onDelete={handleDelete}
        onCancel={toggleDeleteDialog}
        tile={"Deleting installed device"}
        text={`Are you sure you want to delete serial # '${selectedDevice?.serialId}' with apolis # '${selectedDevice?.embeddedId}'`}
      />
      <Fab className={classes.add} color="primary" aria-label="add">
        <AddIcon onClick={() => toggleAddDialog(undefined)} />
      </Fab>
    </TableContainer>
  );
}
