import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import AlgorithmsAPI from "../../../../apis/AlgorithmsAPI";
import SpinnerButton from "../../../common/widgets/SpinnerButton";

import MainForm from "./form1/MainForm";
import ScanResultsForm from "./form2/ScanResultsForm";
import VarifyResults from "./page3/VarifyResults";
import Tabs from "./Tabs";

/** @function Dialog
 * The Edit Dialog returns a draggable dialog that confirms a Edit function for processed frames.
 * 
 * @param {boolean} show: determines when the dialog box should display
   @param {func} onEdit: function to be called when 'Change' button is pressed
   @param {func} onClose: function to be called when 'Cancel' button is pressed
   @param {string} tile: header for the dialog box
   @param {string} text: text for the dialog box
   @param {Camera} camera: current camera
 */

const ProcessImagesDialog = props => {
  const { enqueueSnackbar } = useSnackbar();
  let { /*algorithm,*/ camera } = useParams();
  let [activeStep, setActiveStep] = useState(0);
  let [duration, setDuration] = useState(0);
  let [submitting, setSubmitting] = useState(false);
  let [swirs, setSwirs] = useState([
    // {
    //   fileName: "190e168e-f82c-11ea-998e-acde48001122_Charlie.swir",
    //   container: "cloud-image-swir",
    //   camera: "Charlie"
    // }
    // ,
    // {
    //   fileName: "1b67682c-f82c-11ea-998e-acde48001122_Charlie.swir",
    //   container: "cloud-image-swir",
    //   camera: "Charlie"
    // }
  ]);
  let [scanResults, setScanResults] = useState([
    // {
    //   blobContainer: "cloud-results",
    //     createdOn: "2020-08-11T19:45:38Z",
    //     detections: [
    //       {
    //         meanColDens: 1830.4619140625,
    //         meanConf: 1.06954026222229,
    //         roicoords: [157, 201, 171, 201, 171, 209, 157, 209],
    //         sumconf: 158.82471466064453,
    //         uuid: "439b3fa2-f887-11ea-82fc-acde48001122",
    //       },
    //       {
    //         meanColDens: 2590.045654296875,
    //         meanConf: 1.5990544557571411,
    //         roicoords: [180, 203, 185, 203, 185, 207, 180, 207],
    //         sumconf: 22.386762619018555,
    //         uuid: "439ba9e2-f887-11ea-82fc-acde48001122"
    //       }
    //     ],
    //     deviceId: "Charlie",
    //     fileName: "Charlie/2020/Aug/11/190e168e-f82c-11ea-998e-acde48001122.scres",
    //     id: "190e168e-f82c-11ea-998e-acde48001122",
    //     jpg: "http://localhost:7072/v1/jpg/cloud-results/Charlie/2020/Aug/11/190e168e-f82c-11ea-998e-acde48001122.jpg",
    //     overallConf: 203.85653686523438,
    //     pk: "Charlie",
    //     type: "cloudScanresult",
    //     uuid: "190e168e-f82c-11ea-998e-acde48001122",
    //     v: 1
    //   }, {
    //     blobContainer: "cloud-results",
    //       createdOn: "2020-08-11T19:45:38Z",
    //       detections: [
    //         {
    //           meanColDens: 1830.4619140625,
    //           meanConf: 1.06954026222229,
    //           roicoords: [157, 201, 171, 201, 171, 209, 157, 209],
    //           sumConf: 58.82471466064453,
    //           uuid: "439b3fa2-f887-11ea-82fc-acde48001122",
    //         },
    //         {
    //           meanColDens: 2590.045654296875,
    //           meanConf: 1.5990544557571411,
    //           roicoords: [180, 203, 185, 203, 185, 207, 180, 207],
    //           sumConf: 22.386762619018555,
    //           uuid: "439ba9e2-f887-11ea-82fc-acde48001122"
    //         }
    //       ],
    //       deviceId: "Charlie",
    //       fileName: "Charlie/2020/Aug/11/190e168e-f82c-11ea-998e-acde48001122.scres",
    //       id: "190e168e-f82c-11ea-998e-acde48001123",
    //       jpg: "http://localhost:7072/v1/jpg/cloud-results/Charlie/2020/Aug/11/190e168e-f82c-11ea-998e-acde48001122.jpg",
    //       overallConf: 203.85653686523438,
    //       pk: "Charlie",
    //       type: "cloudScanresult",
    //       uuid: "190e168e-f82c-11ea-998e-acde48001123",
    //       v: 1
    //     }
  ]);
  const onSubmit = (settings, frames) => {
    setSubmitting(true);
    const timer = new Date().getTime();

    return new Promise((resolve, reject) => {
      AlgorithmsAPI.createSwirsFromFrames(camera, frames, settings)
        .then(res => {
          console.log(res);
          setActiveStep(1);
          setSwirs(res.data);
          setDuration(new Date().getTime() - timer);
          resolve(res);
        })
        .catch(err => {
          console.error(err);
          reject(err);
          enqueueSnackbar("Error creating SWIR: ", err.message || err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const onSubmit2 = (settings, swirFileNames) => {
    return new Promise((resolve, reject) => {
      AlgorithmsAPI.createScanResultsFromSwirs(camera, swirFileNames, settings)
        .then(res => {
          console.log(res);
          setScanResults(res.data);
          setActiveStep(2);
          resolve(res);
        })
        .catch(err => {
          console.error(err);
          reject(err);
          enqueueSnackbar("Error creating scan result: ", err.message || err);
        });
    });
  };

  const onBack = () => {
    setActiveStep(activeStep > 0 ? --activeStep : 0);
  };

  const onFinish = () => {
    setActiveStep(0);
    props.onClose();
  };

  const getNextButton = activeStep => {
    switch (activeStep) {
      case 0:
        return (
          <SpinnerButton
            variant="contained"
            color="primary"
            type="submit"
            submitting={submitting}
            form="create_swir_form"
          >
            Submit
          </SpinnerButton>
        );

      case 1:
        return (
          <SpinnerButton
            variant="contained"
            color="primary"
            type="submit"
            submitting={submitting}
            form="process_results_form"
          >
            Next
          </SpinnerButton>
        );

      default:
        return (
          <Button onClick={onFinish} variant="contained" color="primary">
            Finish
          </Button>
        );
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={props.show}
        onClose={props.onClose}
        aria-labelledby="draggable-dialog-title"
      >
        <Backdrop sx={{ zIndex: "drawer" + 1 }} open={submitting}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogActions>
          <Button
            role="closeEditDialog"
            onClick={props.onClose}
            color="primary"
          >
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogContent>
          <Tabs
            activeStep={activeStep}
            lables={[
              "Select SWIR Configuration",
              "Verify SWIR Results",
              "Scan Results Created"
            ]}
          >
            <MainForm selected={props.selected} submit={onSubmit} />
            <ScanResultsForm
              swirs={swirs}
              submit={onSubmit2}
              duration={duration}
            />
            <VarifyResults scanResults={scanResults} />
          </Tabs>
        </DialogContent>
        <DialogActions>
          <Button disabled={activeStep === 0} onClick={onBack}>
            Back
          </Button>
          {getNextButton(activeStep)}
        </DialogActions>
      </Dialog>
    </>
  );
};

ProcessImagesDialog.propTypes = {
  selected: PropTypes.array.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withRouter(ProcessImagesDialog);
