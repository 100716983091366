import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  LinearProgress,
  MenuItem,
  TextField
} from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import OrganizationSelect from "~/components/common/OrganizationSelect";

import { DeleteBtn, Form, UserId } from "./FormDialog.styles";
import ValidationSchema from "./ValidationSchema";

const FormDialog = ({
  show,
  onClose,
  initialValues,
  handleSubmit,
  loading,
  actionLabel,
  onDeleteUser,
  onResetPassword
}) => {
  /* Let Formik handle all the Form state including custom fields */
  const userFormik = useFormik({
    enableReinitialize: true, // We should reset the form if the wrapped component props change (using deep equality).
    initialValues,
    onSubmit: values => {
      handleSubmit(values);
    },
    validationSchema: ValidationSchema
  });

  return (
    <Dialog open={show} onClose={onClose}>
      {loading && <LinearProgress />}
      <DialogTitle>User Details</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontWeight: "700", fontSize: "20px" }}>
          {userFormik.values?.name}
        </DialogContentText>
        <UserId>
          {"user_id: "}
          {userFormik.values?.id ? userFormik.values?.id : "Auto-generated"}
        </UserId>
        <Form onSubmit={userFormik.handleSubmit}>
          <TextField
            variant="standard"
            id="name"
            label="Name"
            type="text"
            name="name"
            margin="normal"
            value={userFormik.values?.name}
            onChange={userFormik.handleChange}
            onBlur={userFormik.handleBlur}
            error={userFormik.touched.name && Boolean(userFormik.errors.name)}
            helperText={userFormik.touched.name && userFormik.errors.name}
          />

          <TextField
            variant="standard"
            id="email"
            label="Email"
            type="text"
            name="email"
            margin="normal"
            placeholder="user@domain.tld"
            value={userFormik.values?.email}
            onChange={userFormik.handleChange}
            onBlur={userFormik.handleBlur}
            error={userFormik.touched.email && Boolean(userFormik.errors.email)}
            helperText={userFormik.touched.email && userFormik.errors.email}
          />

          <TextField
            variant="standard"
            id="nickname"
            label="Nickname"
            type="text"
            name="nickname"
            margin="normal"
            value={userFormik.values?.nickname}
            onChange={userFormik.handleChange}
          />

          <TextField
            variant="standard"
            id="picture"
            label="Picture URL"
            type="url"
            name="picture"
            margin="normal"
            value={userFormik.values?.picture}
            onChange={userFormik.handleChange}
          />

          <TextField
            variant="standard"
            id="roles"
            label="Role"
            name="roles"
            select
            style={{ minWidth: "167px" }}
            value={userFormik.values?.roles}
            onChange={userFormik.handleChange}
            onBlur={userFormik.handleBlur}
            error={userFormik.touched.roles && Boolean(userFormik.errors.roles)}
            helperText={userFormik.touched.roles && userFormik.errors.roles}
          >
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </TextField>

          {/* Custom field for Organization DropDown Menu */}
          <OrganizationSelect
            onChange={org => userFormik.setFieldValue("org", org?.id)}
          />

          {userFormik.values?.id ? (
            <Container style={{ padding: "5px 15px 5px 0px" }}>
              <Divider />
              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{ margin: "10px", width: "150px" }}
                onClick={() => onResetPassword(userFormik.values)}
              >
                Reset Password
              </Button>
              <DeleteBtn
                variant="outlined"
                size="small"
                onClick={() => onDeleteUser(userFormik.values)}
              >
                Delete user
              </DeleteBtn>
            </Container>
          ) : (
            ""
          )}

          <DialogActions>
            <Button autoFocus onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              /* Disabled until all the fields are valid and if the fields values have been changed from their initial values */
              disabled={!(userFormik.isValid && userFormik.dirty)}
            >
              {loading ? <CircularProgress size={18} /> : actionLabel}
            </Button>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

FormDialog.defaultProps = {
  show: false,
  loading: false,
  actionLabel: "create"
};

FormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  actionLabel: PropTypes.string.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired
};

export default FormDialog;
