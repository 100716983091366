import { Component } from "react";
import ReactMarkdown from "react-markdown";

import README from "../../assets/README.md";

class Documentation extends Component {
  constructor() {
    super();
    this.state = { markdown: "" };
  }

  UNSAFE_componentWillMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(README)
      .then(res => res.text())
      .then(text => this.setState({ markdown: text }));
  }

  render() {
    const { markdown } = this.state;

    return (
      <div style={{ padding: "40px" }}>
        {" "}
        <ReactMarkdown source={markdown} />
      </div>
    );
  }
}

export default Documentation;
