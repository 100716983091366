import {
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import Dropzone from "react-dropzone";

import AlgorithmsAPI from "../../apis/AlgorithmsAPI";

import UploadedTable from "./UploadedTable";

const PREFIX = "FileDrop";

const classes = {
  selectContainer: `${PREFIX}-selectContainer`,
  backdrop: `${PREFIX}-backdrop`
};

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  minWidth: 300,
  width: "100%",
  justifyContent: "center",
  alignSelf: "center",
  backgroundColor: "#242E3B",

  [`.${classes.selectContainer}`]: {
    display: "flex",
    flexDirection: "column",
    margin: 30,
    padding: 30
  },

  [`.${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

/**
 * @description this function is called when files are dropped into the upload box
 * The fils will be added to a FormData object to be sent to the cloud upload function
 * In addition, the result of the selectedResultsContainer selector is also added to form data
 * this will tell the cloud function where to store the data.
 *
 * The upload should only accept files with the .scres.gz endings
 * Each successful file upload is added to the uploadedFiles array as well as upload errors.
 *
 * @author Sean W.
 * @date 2020-11-02
 * @param {*} params
 */
const FileDrop = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedResultsContainer, setSelectedResultsContainer] =
    useState("offline-results");
  const [loading, setLoading] = useState(false);

  const onDrop = async acceptedFiles => {
    console.log(acceptedFiles);
    let formData = new FormData();
    const fileNames = [];

    acceptedFiles.forEach((file, i) => {
      if (file.name.endsWith(".scres.gz")) {
        formData.append(`file-${i + 1}`, acceptedFiles[i]);
        fileNames.push(file.name);
        return file.name;
      } else {
        enqueueSnackbar(
          `Error! wrong file format for ${file.name}, file must end with .scres.gz`
        );
      }
    });
    if (fileNames.length === 0) {
      console.log("0 files to upload");
      return;
    }

    formData.append("selectedResultsContainer", selectedResultsContainer);
    console.log(formData.value);

    // TODO:(sean) check that all files end with .gz
    try {
      setLoading(true);
      const res = await AlgorithmsAPI.uploadOfflineSCRESFiles(formData);
      console.log("success", res);
      setLoading(false);
      setUploadedFiles([
        ...uploadedFiles,
        {
          date: new Date().toDateString(),
          time: new Date().toTimeString(),
          selectedResultsContainer,
          fileNames
        }
      ]);
      enqueueSnackbar("Uploaded files");
    } catch (err) {
      console.error("error", err);
      setLoading(false);
      enqueueSnackbar("Failed to upload files");

      setUploadedFiles([
        ...uploadedFiles,
        {
          date: new Date().toDateString(),
          time: new Date().toTimeString(),
          message: err.message,
          selectedResultsContainer,
          fileNames: []
        }
      ]);
    }
  };

  const handleBlobContainerChange = event =>
    setSelectedResultsContainer(event.target.value);

  return (
    <Root>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Paper className={classes.selectContainer}>
            <Typography variant="body2">RESULTS CONTAINER:</Typography>
            <FormControl
              variant="standard"
              className={classes.selectHolder}
              component="fieldset"
            >
              <InputLabel id="sensorConfig-label">Blob Container</InputLabel>
              <Select
                className={classes.select}
                labelId="blobContainerSelect-label"
                id="selectedBlobContainer"
                name="blobContainerSelect"
                color={"secondary"}
                variant="outlined"
                value={selectedResultsContainer}
                onChange={handleBlobContainerChange}
              >
                <MenuItem value="offline-results">Offline Results</MenuItem>
                <MenuItem value="offline-results2">Offline Results 2</MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </Grid>

        <Grid item sm={8} xs={12}>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section className="dropzone-container">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>
                    Drag &apos;n&apos; drop <b>.scres.gz</b> files here, or
                    click to select files
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
          <UploadedTable data={uploadedFiles} />
        </Grid>
      </Grid>
    </Root>
  );
};

export default FileDrop;
