import {
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import AlgorithmsAPI from "~/apis/AlgorithmsAPI";
import FileDownload from "~/components/common/FileDownload";
import TableToolbar from "~/components/common/TableToolbar";
import DeleteDialog from "~/components/common/dialogs/DeleteDialog";
import {
  BaseFirstTableCell,
  BaseLastTableCell,
  BaseTable,
  BaseTableThumbnail
} from "~/components/common/styles";
import { getComparator, headCellsAlgo, stableSort } from "~/utils/dataUtils";

import ProcessImagesDialog from "./processImagesModal/ProcessImagesDialog";

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <BaseFirstTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </BaseFirstTableCell>
        {headCellsAlgo.map(headCell => (
          <BaseLastTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id && (
                <span style={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              )}
            </TableSortLabel>
          </BaseLastTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const FramesTable = props => {
  const { enqueueSnackbar } = useSnackbar();

  //http://localhost:7071/api/identify?name=seansPi/2020-02-04T15:21:26.660Z&camera=seansPi&history=10
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdOn");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem("rowsPerPage")) || 25
  );
  const [download, setDownload] = useState(null);
  const [highIndex, setHighIndex] = useState(null);
  const [lowIndex, setLowIndex] = useState(null);

  /** Establishes whether the Delete Dialog is open */
  const [isDeleteShowing, setIsDeleteShowing] = useState(false);
  const [isProcessFramesDialogShowing, setIsProcessFramesDialogShowing] =
    useState(false);

  let { /*algorithm,*/ camera } = useParams();

  const handleDelete = () => {
    console.log("selected", props.selected);

    //show toast
    if (!props.selected || !props.selected.length) {
      // set to red toast
      enqueueSnackbar("Error, no files selected!");
      return;
    }

    enqueueSnackbar("Deleting files");

    // const uuids = props.scans
    //   .filter(sc => props.selected.includes(sc.id))
    //   .map(sc => sc.uuid);

    const fileNames = props.scans
      .filter(sc => props.selected.includes(sc.id))
      .map(sc => sc.fileName);

    AlgorithmsAPI.deleteFrames(camera, props.selected, fileNames)
      .then(res => {
        console.log("res", res);
        props.setSelected([]);
        props.setScans(
          props.scans.filter(sc => !props.selected.includes(sc.id))
        );
        //TODO: if success, fillter out deleted frames from state
        // props.setSwirs(
        //   props.swirs.filter(sc => !props.checked.includes(sc.id))
        // );

        enqueueSnackbar("Deleted files");
        setIsDeleteShowing(false);
      })
      .catch(err => {
        enqueueSnackbar("Error, files not deleted!");
        console.error(err);
      });
  };
  const handleDownload = () => {
    console.log("selected", props.selected);

    //show toast
    if (!props.selected || !props.selected.length) {
      // set to red toast
      enqueueSnackbar("Error, no files selected!");

      return;
    }

    enqueueSnackbar("Downloading files");

    const fileNames = props.scans
      .filter(sc => props.selected.includes(sc.id))
      .map(sc => sc.fileName);

    setDownload({ fileNames });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = visibleItems.map(n => n.id);
      props.setSelected(newSelecteds);
      return;
    }
    props.setSelected([]);
  };

  /* Updates Highest/Lowest Checked Row Index for Shift Multi-Select */
  const updateIndex = (index, deselect) => {
    if (!deselect) {
      console.log(index);
      setLowIndex(index);
      setHighIndex(index);
    } else {
      //If highest/lowest row is deselected, we loop through all
      //rows until we find the next hightest/lowest
      if (index === lowIndex) {
        for (let i = lowIndex + 1; i < allItems.length; i++) {
          if (isSelected(allItems[i].id)) {
            setLowIndex(i);
            break;
          } else {
            setLowIndex(null);
          }
        }
      }
      if (index === highIndex) {
        for (let i = highIndex - 1; i >= 0; i--) {
          if (isSelected(allItems[i].id)) {
            setHighIndex(i);
            break;
          } else {
            setHighIndex(null);
          }
        }
      }
    }
  };

  const handleClick = (event, name, index) => {
    const selectedIndex = props.selected.indexOf(name);
    let deselect = true;
    let newSelected = [];
    let pageIndex = index + rowsPerPage * page;

    if (selectedIndex === -1) {
      if (/*event.nativeEvent.shiftKey*/ true && props.selected.length > 0) {
        newSelected = props.selected;
        if (pageIndex > highIndex) {
          for (let i = pageIndex; i >= highIndex; i--) {
            let tempName = allItems[i].id;
            if (!isSelected(tempName)) {
              newSelected.push(tempName);
            } else {
              break;
            }
          }
        } else if (pageIndex < lowIndex) {
          for (let i = pageIndex; i < lowIndex; i++) {
            let tempName = allItems[i].id;
            if (!isSelected(tempName)) {
              newSelected.push(tempName);
            } else {
              break;
            }
          }
        }
      } else {
        newSelected = newSelected.concat(props.selected, name);
      }
      deselect = false;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1)
      );
    }
    updateIndex(pageIndex, deselect);
    props.setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => props.selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.scans.length - page * rowsPerPage);

  const allItems = props.scans
    ? stableSort(props.scans, getComparator(order, orderBy))
    : [];

  const visibleItems = props.scans
    ? stableSort(props.scans, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    : [];

  const toggleDeleteDialog = () => {
    setIsDeleteShowing(!isDeleteShowing);
  };

  const toggleProcessFramesDialog = () => {
    setIsProcessFramesDialogShowing(!isProcessFramesDialogShowing);
  };
  // console.log(`${process.env.REACT_APP_CLOUD_ALGOS_API_URL}/v1/scans/${camera}/download`)

  return (
    <>
      {" "}
      {download && (
        <FileDownload
          actionPath={`${AlgorithmsAPI.getBaseURL()}/scans/${camera}/download`}
          method="POST"
          onDownloadComplete={() => {
            setDownload(null);
            console.log("done");
          }}
          queryParams={download}
        />
      )}
      <ProcessImagesDialog
        role="processImagesDialog"
        show={isProcessFramesDialogShowing}
        selected={props.scans.filter(sc => props.selected.includes(sc.id))}
        onClose={toggleProcessFramesDialog}
        onSubmitted={() => {}}
        // onCancel={toggleDeleteDialog}
      />
      <DeleteDialog
        show={isDeleteShowing}
        onDelete={handleDelete}
        onCancel={toggleDeleteDialog}
        tile={"DELETING " + props.selected.length + " Raw Frames"}
        text={
          "Are you sure you want to delete " + props.selected.length + " swirs?"
        }
      />
      <TableToolbar
        title={"Preprocessed Frames"}
        numSelected={props.selected.length}
        handleDelete={toggleDeleteDialog}
        handleDownload={handleDownload}
        startDate={props.startDate}
        setStartDate={props.setStartDate}
        setEndDate={props.setEndDate}
        endDate={props.endDate}
        processFrames={toggleProcessFramesDialog}
      />
      <TableContainer>
        <BaseTable
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={props.selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={e => handleSelectAllClick(e, visibleItems)}
            onRequestSort={handleRequestSort}
            rowCount={props.selected.length}
          />
          <TableBody>
            {visibleItems.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={event => handleClick(event, row.id, index)}
                  role="frameRow"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <BaseFirstTableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </BaseFirstTableCell>
                  <TableCell id={labelId} scope="row" padding="none">
                    <BaseTableThumbnail src={row.jpg} alt="Thumbnail" />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {row.fileName}
                  </TableCell>
                  <BaseLastTableCell component="th" scope="row" padding="none">
                    {moment.parseZone(row.createdOn).format("MM-DD-YYYY")}
                  </BaseLastTableCell>
                  <BaseLastTableCell
                    component="th"
                    scope="row"
                    padding="none"
                    role="time"
                  >
                    {moment.parseZone(row.createdOn).format("HH:mm:ss")}
                  </BaseLastTableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </BaseTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={props.scans.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

FramesTable.propTypes = {
  scans: PropTypes.array.isRequired,
  setScans: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default withRouter(FramesTable);
