const toExport = {
  cameras: [],
  ajaxCallsInProgress: 0,
  iotMessages: [],
  deviceAttribute: {
    isLoading: false,
    reported: [],
    telemetry: {},
    deviceStatus: {},
    deviceAction: {}
  },
  signalR: {
    isLoading: false,
    connection: null,
    subscriptionDetails: {
      subsciptionType: null,
      subscriptionName: null
    }
  }
};

export default toExport;
