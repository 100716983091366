import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import CameraAPI from "../../../apis/CameraAPI";

import FramesTable from "./FramesTable";
import { Paper, Root, Spinner, SpinnerHolder } from "./SelectFrames.styles";

const SelectFrames = () => {
  const [selected, setSelected] = useState([]);
  const [scans, setScans] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let { camera } = useParams();

  const filterDate = useCallback(
    (startDate, endDate) => {
      let newStart = null;
      let newEnd = null;
      if (startDate) {
        newStart = moment(startDate).format("YYYY-MM-DD[T]HH:mm:ss:SSS[Z]");
      }
      if (endDate) {
        newEnd = moment(endDate).format("YYYY-MM-DD[T]HH:mm:ss:SSS[Z]");
      }

      CameraAPI.getFrames(camera, newStart, newEnd)
        .then(response => {
          console.log("Filtered Raw Frames: ", response.data.length);
          setDataLoading(false);
          setScans(response.data);
        })
        .catch(error => {
          console.error("error", error);
        });
    },
    [camera]
  );

  useEffect(() => {
    setDataLoading(true);
    filterDate(startDate, endDate);
  }, [camera, startDate, endDate, filterDate]);

  return (
    <Root>
      {scans !== null && !dataLoading ? (
        <Paper>
          <FramesTable
            scans={scans}
            setScans={setScans}
            selected={selected}
            setSelected={setSelected}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Paper>
      ) : (
        <SpinnerHolder>
          <Spinner />
        </SpinnerHolder>
      )}
    </Root>
  );
};

SelectFrames.propTypes = {
  goToSet: PropTypes.func.isRequired
};

export default withRouter(SelectFrames);
