import { styled } from "@mui/material/styles";
import { Route, Switch } from "react-router-dom";

import Camera from "~/components/cameras/Loadable";
import Dashboard from "~/components/dashboard/Loadable";

const PREFIX = "CameraSubRoutes";

const classes = {
  map: `${PREFIX}-map`
};

const Root = styled("div")({
  width: "100%",
  position: "relative",

  [`.${classes.map}`]: {
    zIndex: 0,
    position: "absolute",
    top: 0,
    bottom: 0
  }
});

const CameraSubRoutes = () => {
  return (
    <Root>
      <Dashboard />
      <Switch>
        <Route path="/cameras/:camera/:tab" component={Camera} />
      </Switch>
    </Root>
  );
};

export default CameraSubRoutes;
