import axios from "axios";
class DeviceAPI {
  constructor() {
    this.baseTime = new Date();
    this.getFramesCallStack = []; // if the REACT_APP_DEVICE_ENV is set to local, use localhost, otherwise use the cloud server
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_KUVA_API_URL}/device/v1`,
      timeout: 10000
    });
  }
  static instance = null;

  static Instance = () => this.instance ?? new DeviceAPI();

  // Allow auth header to be initialized after login
  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
      console.log(
        "DeviceAPI url set to: ",
        this.axiosInstance.defaults.baseURL
      );
    }
  };

  setBaseOrgHeader = baseOrg => {
    this.axiosInstance.defaults.headers.common["X-Organization"] = baseOrg;
  };

  getAllInstalledDevices = () => {
    console.log(
      `%c calling: getAll ${this.axiosInstance.defaults.baseURL}/installedDevice`,
      "color: #9954E3"
    );
    return this.axiosInstance.get("/installedDevice");
  };

  getInstalledDevice = (serialId, apolisId) => {
    console.log(
      `%c calling: getAll ${this.axiosInstance.defaults.baseURL}/installedDevice/${serialId}/${apolisId}`,
      "color: #9954E3"
    );
    return this.axiosInstance.get(`/installedDevice/${serialId}/${apolisId}`);
  };

  createInstalledDevice = installedDevice => {
    console.log(
      `%c calling: create installed device  ${this.axiosInstance.defaults.baseURL}/newInstalledDevice`,
      "color: #9954E3"
    );
    return this.axiosInstance.post("/newInstalledDevice", installedDevice);
  };

  updateInstalledDevice = installedDevice => {
    console.log(
      `%c calling: update installed device  ${this.axiosInstance.defaults.baseURL}/InstalledDevice`,
      "color: #9954E3"
    );
    return this.axiosInstance.put("/InstalledDevice", installedDevice);
  };

  deleteInstalledDevice = (serialId, apolisId) => {
    console.log(
      `%c calling: delete installed device ${this.axiosInstance.defaults.baseURL}/installedDevice/${serialId}/${apolisId}`,
      "color: #9954E3"
    );
    return this.axiosInstance.delete(
      `/installedDevice/${serialId}/${apolisId}`
    );
  };
}

export default DeviceAPI.Instance();
