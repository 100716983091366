import { Auth0Provider } from "@auth0/auth0-react";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { createBrowserHistory } from "history";
import { SnackbarProvider } from "notistack";
import { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { OrganizationsProvider } from "~/contentProviders/OrganizationsContextProvider";
import * as serviceWorker from "~/serviceWorker";
import configureStore from "~/store/configureStore";
import { featureFlagClient } from "~/utils/feature-flag-client";

import App from "./components/App";
import "./index.css";

const store = configureStore();
const history = createBrowserHistory();

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(appState?.targetUrl ?? window.location.pathname);
};

const Application = () => {
  useEffect(() => {
    featureFlagClient.loadFeatures();
  }, []);

  return (
    <StrictMode>
      <GrowthBookProvider growthbook={featureFlagClient}>
        <Provider store={store}>
          <SnackbarProvider autoHideDuration={10000} maxSnack={7}>
            <OrganizationsProvider>
              <Auth0Provider
                domain={String(process.env.REACT_APP_AUTH0_DOMAIN_URL)}
                clientId={String(process.env.REACT_APP_AUTH0_CLIENT_ID)}
                onRedirectCallback={onRedirectCallback}
                authorizationParams={{
                  redirect_uri: window.location.origin,
                  audience: process.env.REACT_APP_AUTH0_API_ID
                }}
              >
                <App />
              </Auth0Provider>
            </OrganizationsProvider>
          </SnackbarProvider>
        </Provider>
      </GrowthBookProvider>
    </StrictMode>
  );
};

ReactDOM.render(<Application />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
