import { styled } from "@mui/system";

export const ErrorContainer = styled("div")({
  marginTop: 200
});

export const Root = styled("div")({
  marginLeft: 50,
  marginRight: 50
});

export const Form = styled("form")({
  display: "flex",
  flexWrap: "wrap"
});
