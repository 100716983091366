import { FilterList as FilterListIcon } from "@mui/icons-material/";
import {
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { lighten, styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import Can from "~/components/common/Can";
import DeleteButton from "~/components/common/buttons/DeleteButton";
import DownloadButton from "~/components/common/buttons/DownloadButton";
import PoiSelect from "~/components/common/selects/PoiSelect";

const PREFIX = "TableToolbar";

const classes = {
  root: `${PREFIX}-root`,
  highlight: `${PREFIX}-highlight`,
  secondaryText: `${PREFIX}-secondaryText`,
  applyButton: `${PREFIX}-applyButton`,
  clearButton: `${PREFIX}-clearButton`,
  dayButton: `${PREFIX}-dayButton`,
  dayButtons: `${PREFIX}-dayButtons`,
  dayButtonSelected: `${PREFIX}-dayButtonSelected`,
  divider: `${PREFIX}-divider`,
  formControl: `${PREFIX}-formControl`,
  filterSubTitle: `${PREFIX}-filterSubTitle`,
  filterTitle: `${PREFIX}-filterTitle`,
  menu: `${PREFIX}-menu`,
  picker: `${PREFIX}-picker`,
  text: `${PREFIX}-text`,
  title: `${PREFIX}-title`,
  actionButtons: `${PREFIX}-actionButtons`
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [`.${classes.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },

  [`.${classes.highlight}`]:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },

  [`.${classes.secondaryText}`]: {
    fontSize: "17px",
    fontFamily: "Sathu",
    color: "black",
    marginLeft: "48%",
    fontWeight: "bold"
  },

  [`.${classes.applyButton}`]: {
    width: "30%",
    marginLeft: "17.5%",
    display: "inline",
    marginTop: "10%"
  },

  [`.${classes.clearButton}`]: {
    width: "30%",
    marginLeft: "5%",
    display: "inline",
    marginTop: "10%"
  },

  [`.${classes.dayButton}`]: {
    opacity: "0.6"
  },

  [`.${classes.dayButtons}`]: {
    display: "flex",
    justifyContent: "center"
  },

  [`.${classes.dayButtonSelected}`]: {
    opacity: "1"
  },

  [`.${classes.divider}`]: {
    width: "90%",
    margin: "auto"
  },

  [`.${classes.formControl}`]: {
    display: "block",
    marginLeft: "1%",
    marginTop: "4%"
  },

  [`.${classes.filterSubTitle}`]: {
    ontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "16px"
  },

  [`.${classes.filterTitle}`]: {
    display: "block",
    marginLeft: "3%",
    marginTop: "1%",
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "20px"
  },

  [`.${classes.menu}`]: {
    color: "#F5F5F5",
    width: "35%"
  },

  [`.${classes.picker}`]: {
    width: "40%",
    marginLeft: "6.6%"
  },

  [`.${classes.text}`]: {
    fontSize: "15px",
    fontFamily: "Sathu",
    color: "black",
    marginLeft: "3%",
    marginTop: "2%"
  },

  [`.${classes.title}`]: {
    flex: "1 1 100%",
    paddingLeft: 34
  },

  [`.${classes.actionButtons}`]: {
    display: "flex",
    gap: "10px"
  }
}));

const TableToolbar = props => {
  const [radioGroupValue, setRadioGroupValue] = useState("previousWeek");
  const { numSelected } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  let today = new Date();
  const [weekdays, setWeekdays] = useState([]);
  const [weekdates, setWeekdates] = useState([]);
  const defaultDays = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
  const [selectedDay, setSelectedDay] = useState("");

  //These variables allow the user to change the date range freely
  //without the page reloading prematurely
  const [toolbarStartDate, setToolbarStartDate] = useState(props.startDate);
  const [toolbarEndDate, setToolbarEndDate] = useState(props.endDate);

  useEffect(() => {
    if (weekdays.length === 0) {
      initializeWeekdays();
    }
  });

  const initializeWeekdays = () => {
    const tempweek = [];
    const tempweekdates = [];
    for (let i = 6; i >= 0; i--) {
      let date = new Date(today);
      date.setDate(date.getDate() - i);
      tempweekdates.push(date);
      setWeekdates(tempweekdates);
      let day = date.getDay();
      day = numToDay(day);
      tempweek.push(day);
      setWeekdays(tempweek);
    }
  };
  const handleStartDateChange = date => {
    setToolbarStartDate(date);
  };

  const handleEndDateChange = date => {
    setToolbarEndDate(date);
  };

  const handleSingleDateChange = date => {
    const start = new Date(date);
    start.setHours(0, 0, 0, 0);
    const end = new Date(date);
    end.setHours(23, 59, 59, 59);

    props.setStartDate(start);
    props.setEndDate(end);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearFilters = () => {
    setSelectedDay("");
    props.setStartDate(null);
    props.setEndDate(null);
    handleClose();
  };

  const applyFilters = () => {
    props.setStartDate(toolbarStartDate);
    props.setEndDate(toolbarEndDate);
    handleClose();
  };

  const handleRadioChange = radio => {
    setRadioGroupValue(radio);
  };

  const numToDay = day => {
    return defaultDays[((day % 7) + 7) % 7];
  };

  const handleDay = (day, date) => {
    handleSingleDateChange(date);
    setSelectedDay(day);
  };

  return (
    <StyledToolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.title}
        </Typography>
      )}

      <PoiSelect
        pois={props.pois}
        selectedPoi={props.selectedPoi}
        setSelectedPoi={props.setSelectedPoi}
        showTooltip={true}
      />

      {numSelected > 0 ? (
        <div className={classes.actionButtons}>
          {/*props.processFrames && (
            <Tooltip title="Process">
              <IconButton aria-label="process" onClick={props.processFrames}>
                <AddPhotoAlternateIcon />
              </IconButton>
            </Tooltip>
          )*/}
          {props.handleDelete && (
            <Can I="delete" a="Frame">
              <DeleteButton
                isDisabled={props.downloading || props.deleting ? true : false}
                deleting={props.deleting}
                handleDelete={props.handleDelete}
              />
            </Can>
          )}
          {props.handleDownload && (
            <DownloadButton
              isDisabled={props.downloading || props.deleting ? true : false}
              downloading={props.downloading}
              handleDownload={props.handleDownload}
            />
          )}
        </div>
      ) : (
        <div>
          <Tooltip title="Filter list">
            <IconButton
              aria-label="filter list"
              aria-controls="simple-menu"
              onClick={handleClick}
              size="large"
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.menu}
          >
            <Typography className={classes.filterTitle}>
              {" "}
              REFINE RESULTS
            </Typography>
            <Divider className={classes.divider} />
            <RadioGroup
              value={radioGroupValue}
              aria-label="filters"
              name="customized-radios"
            >
              <div
                onClick={() => handleRadioChange("previousWeek")}
                style={{
                  width: "100%",
                  opacity: radioGroupValue === "previousWeek" ? "1" : "0.4"
                }}
              >
                <FormControlLabel
                  value="previousWeek"
                  control={<Radio color="primary" />}
                  label="PREVIOUS WEEK"
                  className={classes.formControl}
                  classes={{
                    label: classes.filterSubTitle
                  }}
                />

                <div className={classes.dayButtons}>
                  {weekdays.map((day, i) => (
                    <Button
                      key={i}
                      onClick={() => handleDay(day, weekdates[i])}
                      className={
                        day === selectedDay
                          ? classes.dayButtonSelected
                          : classes.dayButton
                      }
                    >
                      {i === 6 ? (
                        <span style={{ color: "#2F73E1" }}> TODAY </span>
                      ) : (
                        day
                      )}
                    </Button>
                  ))}
                </div>
              </div>
              <div
                onClick={() => handleRadioChange("singleDate")}
                style={{
                  opacity: radioGroupValue === "singleDate" ? "1" : "0.4"
                }}
              >
                <FormControlLabel
                  value="singleDate"
                  control={<Radio color="primary" />}
                  label="SINGLE DATE"
                  className={classes.formControl}
                  classes={{
                    label: classes.filterSubTitle
                  }}
                />
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  label="DATE"
                  value={new Date(props.startDate)}
                  onChange={handleSingleDateChange}
                  autoOk={true}
                  className={classes.picker}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </div>
              <div
                onClick={() => handleRadioChange("dateRange")}
                style={{
                  opacity: radioGroupValue === "dateRange" ? "1" : "0.4"
                }}
              >
                <FormControlLabel
                  value="dateRange"
                  control={<Radio color="primary" />}
                  label="DATE RANGE"
                  className={classes.formControl}
                  classes={{
                    label: classes.filterSubTitle
                  }}
                />
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  label="START DATE"
                  value={new Date(toolbarStartDate)}
                  onChange={handleStartDateChange}
                  autoOk={true}
                  className={classes.picker}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
                <TimePicker
                  margin="normal"
                  label="START TIME"
                  value={new Date(toolbarStartDate)}
                  onChange={handleStartDateChange}
                  autoOk={true}
                  className={classes.picker}
                  KeyboardButtonProps={{
                    "aria-label": "change time"
                  }}
                />
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  label="END DATE"
                  value={new Date(toolbarEndDate)}
                  onChange={handleEndDateChange}
                  autoOk={true}
                  className={classes.picker}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
                <TimePicker
                  margin="normal"
                  label="END TIME"
                  value={new Date(toolbarEndDate)}
                  className={classes.picker}
                  onChange={handleEndDateChange}
                  autoOk={true}
                  KeyboardButtonProps={{
                    "aria-label": "change time"
                  }}
                />
              </div>
            </RadioGroup>
            <Button
              variant="contained"
              className={classes.applyButton}
              onClick={applyFilters}
              color="primary"
            >
              APPLY
            </Button>
            <Button
              variant="contained"
              className={classes.clearButton}
              onClick={clearFilters}
              color="primary"
            >
              Clear
            </Button>
          </Menu>
        </div>
      )}
    </StyledToolbar>
  );
};

TableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  handleDelete: PropTypes.func,
  handleDownload: PropTypes.func,
  downloading: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired
};

export default TableToolbar;
