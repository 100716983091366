import {
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useState } from "react";

const PREFIX = "OpticalDepthProcSettings";

const classes = {
  root: `${PREFIX}-root`,
  formControll: `${PREFIX}-formControll`,
  textField: `${PREFIX}-textField`,
  select: `${PREFIX}-select`
};

const Root = styled("div")(() => ({
  [`.${classes.root}`]: {
    width: "100%",
    flexGrow: 1,
    textAlign: "left"
  },

  [`.${classes.formControll}`]: {
    width: "100%"
  },

  [`.${classes.textField}`]: {
    width: "100%"
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1)
  },

  [`.${classes.select}`]: {
    width: "100%"
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1)
  }
}));

// sensorConfiguration: "WBDE",
// histNumFrames: 15,
// minValidPixSum: 0.003

const OpticalDepthProcSettings = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isSubmitting
  /* and other goodies */
}) => {
  const [history, setHistory] = useState(values.odHistNumFrames);

  const handleConfigChange = event => {
    handleChange(event);
    // setConfig(event.target.value )
  };

  const handleHistorySliderChange = (event, x) => {
    event.target.name = "odHistNumFrames";
    event.target.value = x;
    setHistory(x);
    handleChange(event);
  };

  return (
    <Root className={classes.root}>
      <FormControl
        variant="standard"
        className={classes.formControll}
        component="fieldset"
      >
        <FormLabel component="legend">
          Optical Depth Processing Settings
        </FormLabel>
        <br />
        <br />
        <br />
        <Typography id="history-slider" gutterBottom>
          {`History Frames: ${history}`}
        </Typography>
        <Slider
          id="odHistNumFrames"
          defaultValue={history}
          getAriaValueText={value => `${value} frames`}
          aria-labelledby="history-slider"
          valueLabelDisplay="auto"
          onChange={handleHistorySliderChange}
          step={1}
          marks
          min={1}
          max={20}
        />
        <br />{" "}
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <FormControl
              variant="standard"
              className={classes.formControll}
              component="fieldset"
            >
              <InputLabel id="sensorConfig-label">
                Sensor Configuration
              </InputLabel>
              <Select
                variant="standard"
                className={classes.select}
                labelId="sensorConfig-label"
                id="sensorConfig"
                name="sensorConfig"
                defaultValue={"ABDE"}
                onChange={handleConfigChange}
              >
                <MenuItem value="ABDE">ABDE</MenuItem>
                <MenuItem value="ABDE_ChooseBDE">ABDE_ChooseBDE</MenuItem>
                <MenuItem value="WBDE">WBDE</MenuItem>
                <MenuItem value="WBDE_ChooseBDE">WBDE_ChooseBDE</MenuItem>
              </Select>
              <FormHelperText>Help text</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography gutterBottom>Min Valid Pix Sum</Typography>

            <TextField
              id="minValidPixSum"
              label="Min Pix"
              className={classes.textField}
              error={errors.minValidPixSum && touched.minValidPixSum}
              // className={clsx(classes.textField, errors.email && touched.email) && classes.error)}
              value={values.minValidPixSum}
              type="number"
              name="minValidPixSum"
              margin="normal"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Min Pix"
              fullWidth
              helperText={
                (touched.minValidPixSum && errors.minValidPixSum) || ""
              }
            />
            <FormHelperText>Be careful</FormHelperText>
          </Grid>
        </Grid>
      </FormControl>
      <br />
      <br />
      <Divider />
      <br />
      <br />
      <br />
    </Root>
  );
};

OpticalDepthProcSettings.propTypes = {
  // submit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    odHistNumFrames: PropTypes.number.isRequired,
    minValidPixSum: PropTypes.number.isRequired
  }),
  errors: PropTypes.shape({
    odHistNumFrames: PropTypes.string,
    minValidPixSum: PropTypes.string
  }),
  touched: PropTypes.shape({
    odHistNumFrames: PropTypes.bool,
    minValidPixSum: PropTypes.bool
  })
};

export default OpticalDepthProcSettings;
