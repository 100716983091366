import { setBaseOrgs } from "~/apis/api";
import { request } from "~/request";

export const getBaseOrganization = async payload => {
  try {
    const httpResult = await request(
      `${process.env.REACT_APP_KUVA_API_URL}/organization/v1/baseorg/${payload}`,
      { withCredentials: false }
    );

    localStorage.setItem("kcc/base_org", httpResult.data);

    setBaseOrgs(httpResult.data);

    return httpResult.data;
  } catch (error) {
    console.error(error);
  }
};
