import {
  FormControl as MUIFormControl,
  Select as MUISelect
} from "@mui/material";
import { styled } from "@mui/system";

export const Wrapper = styled("div")({
  background: "background.paper",
  padding: 20,
  margin: 20
});

export const SelectHolder = styled(MUIFormControl)({
  width: "90%",
  marginTop: 10
});

export const Select = styled(MUISelect)({
  width: "100%"
});
