import { CircularProgress, Paper as MUIPaper } from "@mui/material";
import { styled } from "@mui/system";

export const Root = styled("div")({
  width: "100%",
  height: "100%"
});

export const Paper = styled(MUIPaper)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(2)
}));

export const SpinnerHolder = styled("div")({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "#171f2b",
  textAlign: "center"
});

export const Spinner = styled(CircularProgress)({
  margin: 0,
  position: "absolute",
  bottom: "50%"
});
