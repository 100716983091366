import {
  Button,
  Container,
  Grid,
  Paper,
  Tooltip,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import AlgorithmsAPI from "../../../apis/AlgorithmsAPI";
import CameraAPI from "../../../apis/CameraAPI";
import { getPois } from "../../../utils/dataUtils";
import JsonFileUploader from "../../common/JsonFileUploader";

import InputTypeSelect from "./InputTypeSelect";
import PoiSelect from "./PoiSelect";
import UtcTimer from "./UtcTimer";

const PREFIX = "Quantification";

const classes = {
  dateRangeDiv: `${PREFIX}-dateRangeDiv`,
  picker: `${PREFIX}-picker`,
  paper: `${PREFIX}-paper`,
  select: `${PREFIX}-select`
};

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: 30,
  paddingBottom: 30,
  minHeight: "100vh",

  [`.${classes.dateRangeDiv}`]: {
    opacity: ({ radioGroupValue }) => {
      const op = radioGroupValue === "dateRange" ? "1" : "0.4";
      return op;
    }
  },

  [`.${classes.picker}`]: {
    minWidth: 180
  },

  [`.${classes.paper}`]: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },

  [`.${classes.select}`]: {
    minWidth: 180
  }
}));

const utcZone = "Etc/Greenwich";

const initialJsonValues = {
  name: "quant-job",
  startTime: utcToZonedTime(new Date(), utcZone),
  endTime: utcToZonedTime(new Date(), utcZone),
  geometry: {
    headDeg: 0,
    tiltDeg: 30,
    cameraHeightMeters: 9.9,
    refSurfHeightMeters: 4.57,
    leakX: 0,
    leakY: 0,
    leakZ: 0,
    leakRow: 0,
    leakCol: 0
  },
  camera: {
    focalLengthPixelsX: 530.6,
    focalLengthPixelsY: 488.2,
    farFieldMaxMeters: 50
  },
  GPM: {},
  testing: {
    windSpeedMps: 2.0,
    windDirectionDeg: 0.0,
    windSpeedScale: 5.0
  }
};

const Quantification = ({ cameraTwins }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  let { /*algorithm,*/ camera } = useParams();

  const [startDate, setStartDate] = useState(
    utcToZonedTime(new Date(), utcZone)
  );
  const [endDate, setEndDate] = useState(utcToZonedTime(new Date(), utcZone));
  const [initialJson, setInitialJson] = useState(initialJsonValues);
  const [settingsJson, setSettingsJson] = useState(initialJsonValues);
  const [loading, setLoading] = useState(false);
  const [scanResults, setScanResults] = useState([]);
  const [pois, setPois] = useState([]);
  const [selectedPoi, setSelectedPoi] = useState("");
  const [inputType, setInputType] = useState("scanresult");
  const [allowSelect, setAllowSelect] = useState(false);

  const handleStartDateChange = date => {
    setSettingsJson({ ...settingsJson, startTime: date });
    setSettingsJson({ ...settingsJson, startTime: date });
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setSettingsJson({ ...settingsJson, endTime: date });
    setSettingsJson({ ...settingsJson, endTime: date });
    setEndDate(date);
  };

  const handleEditJson = e => {
    if (e.name === "startTime") {
      setStartDate(e.new_value);
      setSettingsJson({ ...settingsJson, startTime: e.new_value });
    } else if (e.name === "endTime") {
      setEndDate(e.new_value);
      setSettingsJson({ ...settingsJson, endTime: e.new_value });
    }
    return true;
  };

  const validate = () => {
    if (!cameraTwins.map(c => c.deviceId).includes(camera)) {
      enqueueSnackbar("Error: Camera not found!", {
        variant: "error"
      });
      return false;
    }
    if (!settingsJson) {
      enqueueSnackbar("Error: Settings json missing!", {
        variant: "error"
      });
      return false;
    }
    if (
      startDate > utcToZonedTime(new Date(), utcZone) ||
      endDate > utcToZonedTime(new Date(), utcZone)
    ) {
      enqueueSnackbar("Error: Date is later than today!", {
        variant: "error"
      });
      return false;
    }
    if (endDate.getTime() - startDate.getTime() > 60 * 60 * 2 * 1000) {
      enqueueSnackbar(
        "Warning: Start and end dates should ideally be within 2hours!",
        {
          variant: "warning"
        }
      );
    }
    if (startDate >= endDate) {
      enqueueSnackbar("Error: Start time is same or later than end date!", {
        variant: "error"
      });
      return false;
    }
    return true;
  };

  const getScans = () => {
    setScanResults([]);

    if (!validate()) return;

    setLoading(true);

    CameraAPI.getScanResults(
      camera,
      inputType,
      zonedTimeToUtc(startDate, utcZone).toISOString(),
      zonedTimeToUtc(endDate, utcZone).toISOString()
    )
      .then(response => {
        console.log("GET SCANS RESPONSE:", response);
        setScanResults(response.data);
        const length = response.data.length;
        if (length) {
          enqueueSnackbar(
            `Success: Fetched ${length} Scan${length > 1 && "s"}.`,
            {
              variant: "success"
            }
          );
          const poiArray = getPois(response.data);
          setPois(poiArray);
          setAllowSelect(true);
          setSelectedPoi("All");
        } else {
          enqueueSnackbar("No scans found for selected dates", {
            variant: "warning"
          });
        }
      })
      .catch(error => {
        console.log(error);
        enqueueSnackbar(`Error: ${error.message ?? "Fetch scans Failed"}`, {
          variant: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (allowSelect) {
      setAllowSelect(false);
      setSelectedPoi("");
      setScanResults([]);
      setPois([]);
    }
  }, [startDate, endDate]);

  const handleRun = () => {
    if (!validate()) return;

    setLoading(true);

    const poi = typeof selectedPoi === "number" ? selectedPoi : null;

    AlgorithmsAPI.quantifyFrames(
      camera,
      zonedTimeToUtc(startDate, utcZone).toISOString(),
      zonedTimeToUtc(endDate, utcZone).toISOString(),
      settingsJson,
      poi,
      inputType
    )
      .then(response => {
        console.log("QUANTIFY FRAMES RESPONSE:", response);
        enqueueSnackbar("Successfully cued job", {
          variant: "success"
        });
        history.push("/algorithms/longtasks");
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        enqueueSnackbar(
          `Error: ${error.message ?? "Running quantification failed"}`,
          {
            variant: "error"
          }
        );
      });
  };

  useEffect(() => {
    setInitialJson({
      ...initialJson,
      name: `${camera ?? "nocamera"}-quant-job`
    });
    setSettingsJson({
      ...settingsJson,
      name: `${camera ?? "nocamera"}-quant-job`
    });
  }, [camera]);

  return (
    <StyledContainer>
      <Grid container spacing={3}>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1">Run Quantification</Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle2">
                *Calender Dates/Times are in UTC
              </Typography>
              <Typography variant="subtitle2">Current UTC Date/Time</Typography>
              <UtcTimer utcZone={utcZone} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <DateTimePicker
                margin="normal"
                label="START DATE"
                value={new Date(startDate)}
                onChange={handleStartDateChange}
                autoOk={true}
                className={classes.picker}
                keyboardbuttonprops={{
                  "aria-label": "change date"
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <DateTimePicker
                margin="normal"
                label="END DATE"
                value={new Date(endDate)}
                onChange={handleEndDateChange}
                autoOk={true}
                className={classes.picker}
                keyboardbuttonprops={{
                  "aria-label": "change date"
                }}
              />
            </Paper>
          </Grid>
          <Grid>
            <Paper className={classes.paper}>
              <InputTypeSelect
                inputType={inputType}
                setInputType={setInputType}
              />
            </Paper>
          </Grid>
          <Grid>
            <Paper className={classes.paper}>
              <Button
                disabled={loading}
                variant="outlined"
                color="primary"
                onClick={getScans}
              >
                GET SCANS/POIs
              </Button>
            </Paper>
          </Grid>
          <Grid>
            <Paper className={classes.paper}>
              <PoiSelect
                pois={pois}
                selectedPoi={selectedPoi}
                setSelectedPoi={setSelectedPoi}
                allowSelect={allowSelect}
                scanResults={scanResults}
              />
            </Paper>
          </Grid>
          <Grid>
            <Paper className={classes.paper}>
              <Tooltip
                title={!selectedPoi ? "Ensure a valid poi is selected" : ""}
              >
                <span>
                  <Button
                    disabled={loading || !selectedPoi}
                    variant="outlined"
                    color="primary"
                    onClick={handleRun}
                  >
                    RUN
                  </Button>
                </span>
              </Tooltip>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item spacing={3} sm={8} xs={6}>
          <Grid item xs={12}>
            <ReactJson
              name={"settings"}
              src={settingsJson}
              theme="brewer"
              displayObjectSize={false}
              displayDataTypes={true}
              collapsed={3}
              onEdit={handleEditJson}
              onAdd={handleEditJson}
              onDelete={handleEditJson}
            />
          </Grid>
          <Grid item xs={12}>
            <JsonFileUploader
              setJson={setSettingsJson}
              initialJson={initialJson}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

Quantification.propTypes = {
  cameraTwins: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    cameraTwins: state.cameras
  };
};

export default connect(mapStateToProps)(Quantification);
