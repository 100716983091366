import { SignalRService } from "~/services";
import { request } from "~/request";

import AlgorithmsAPI from "./AlgorithmsAPI";
import BlobAPI from "./BlobAPI";
import CameraAPI from "./CameraAPI";
import DeviceApi from "./DeviceApi";
import OrganizationAPI from "./OrganizationAPI";
import TelemetryAPI from "./TelemetryAPI";

//TODO: this file will be removed when we implement APIM
export const setBaseURIs = (isGatewayFeatureOn, newState) => {
  CameraAPI.setBaseURI(
    isGatewayFeatureOn
      ? `${process.env.REACT_APP_KUVA_API_URL}/camera`
      : newState.REACT_APP_API_URL
  );
  AlgorithmsAPI.setBaseURI(
    isGatewayFeatureOn
      ? `${process.env.REACT_APP_KUVA_API_URL}/algo`
      : newState.REACT_APP_CLOUD_ALGOS_API_URL
  );
  TelemetryAPI.setBaseURI(
    isGatewayFeatureOn
      ? `${process.env.REACT_APP_KUVA_API_URL}/telemetry`
      : newState.REACT_APP_TELEMETRY_API_URL
  );
  BlobAPI.setBaseURI(
    isGatewayFeatureOn
      ? `${process.env.REACT_APP_KUVA_API_URL}/blob`
      : newState.REACT_APP_BLOB_API_URL
  );
  DeviceApi.setBaseURI(
    isGatewayFeatureOn
      ? `${process.env.REACT_APP_KUVA_API_URL}/device`
      : newState.REACT_APP_DEVICE_API_URL
  );
};

export const setAuthToken = token => {
  OrganizationAPI.setAuthToken(token);
  CameraAPI.setAuthToken(token);
  DeviceApi.setAuthToken(token);
  BlobAPI.setAuthToken(token);
  AlgorithmsAPI.setAuthToken(token);
  TelemetryAPI.setAuthToken(token);
  SignalRService.setAuthToken(token);
};

export const setSignalRURL = async baseOrg => {
  try {
    // FIXME: (S.W) this is a temporary solution to get the signalR URL for the specific organization now that we're using appConfigs
    const { data: signalRBaseURL } = await request(
      `${process.env.REACT_APP_KUVA_API_URL}/organization/v2/config/${baseOrg}/signalr/url`,
      { headers: { "X-Organization": baseOrg } }
    );
    if (!signalRBaseURL) throw "SignalR URL not found";
    SignalRService.setBaseURI(signalRBaseURL);
  } catch (error) {
    console.error("SignalR URL not found", error);
  }
};

export const setBaseOrgs = baseOrg => {
  OrganizationAPI.setBaseOrgHeader(baseOrg);
  CameraAPI.setBaseOrgHeader(baseOrg);
  DeviceApi.setBaseOrgHeader(baseOrg);
  BlobAPI.setBaseOrgHeader(baseOrg);
  AlgorithmsAPI.setBaseOrgHeader(baseOrg);
  TelemetryAPI.setBaseOrgHeader(baseOrg);
};
