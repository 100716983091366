import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

export const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
  borderRadius: "15px",
  background: "linear-gradient(rgba(0,250,250,0.5),transparent)",
  backgroundColor: "white"
});

export const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
  borderRadius: "15px"
}));

export const ImageButton = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white
}));

export const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: 25,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity")
}));

export const OrgTitle = styled(Typography)({
  color: "white",
  opacity: 0.6,
  marginTop: 22
});
