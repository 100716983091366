import { useAbility } from "@casl/react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import clsx from "clsx";

import { useOrganizations } from "../../contentProviders/OrganizationsContextProvider";

import { AbilityContext } from "./Can";

const PREFIX = "OrganizationSelect";

const classes = {
  root: `${PREFIX}-root`,
  nestedListItem: `${PREFIX}-nestedListItem`,
  selected: `${PREFIX}-selected`
};

const StyledTreeView = styled(TreeView)(({ theme }) => ({
  [`.${classes.root}`]: {
    flexGrow: 1,
    width: "100%",
    marginLeft: 4
  },

  [`.${classes.nestedListItem}`]: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: "inherit"
    // color: "#fff",
  },

  [`.${classes.selected}`]: {
    color: theme.palette.primary.main
  }
}));

const OrganizationSelect = () => {
  const ability = useAbility(AbilityContext);
  const { organizations, selectedOrg, setSelectedOrg } = useOrganizations();

  const onClickOrganization = async node => {
    setSelectedOrg(node);
  };

  const renderTree = nodes => (
    <TreeItem
      component={ListItem}
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <div
          className={clsx(classes.nestedListItem, {
            [classes.selected]: nodes.id === selectedOrg?.id
          })}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
          selected={nodes.id === selectedOrg?.id}
          onClick={e => {
            e.preventDefault();
            onClickOrganization(nodes);
          }}
        >
          <ListItemAvatar>
            <Avatar
              style={{
                height: "30px",
                width: "30px"
              }}
              alt={nodes.orgname}
              src={nodes.logoURL}
            />
          </ListItemAvatar>

          <ListItemText primaryTypographyProps={{ variant: "body2" }}>
            {nodes.orgname || nodes.id}
          </ListItemText>
        </div>
      }
    >
      {nodes.children?.length > 0 &&
        nodes.children.map(node => renderTree(node))}
    </TreeItem>
  );

  return (
    <StyledTreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={organizations.map(o => o.id)}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {organizations.map(o => renderTree(o))}

      {ability.can("manage", "Camera") &&
        renderTree({
          id: "all",
          orgname: "All Cameras",
          logoURL:
            "https://mssdevstorage.blob.core.windows.net/website-images/Kuva_Symbol_Rev.png"
        })}
    </StyledTreeView>
  );
};

export default OrganizationSelect;
