import { TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";

import {
  createInstalledDevice,
  updateInstalledDevice
} from "~/actions/deviceAction";
import LoadingIndicator from "~/components/LoadingIndicator";

import { initialValues } from "../../../../utils/constants";
import KuvaButton from "../../../common/buttons/Button";

import { LoadingDiv, Root, classes } from "./Styles";

export default function SerialForm({ toggleAddDialog, row }) {
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState(row || initialValues);
  const [processing, setProcessing] = useState(false);

  const action = row ? "Update" : "Add";
  const disabled = Boolean(row);

  const handleChange = e => {
    const { name, value } = e.target;
    const newValues = { ...formValues, [name]: value };
    setFormValues(newValues);
  };
  const handleConfirm = async () => {
    try {
      setProcessing(true);
      await setTimeout(() => {}, 5000);

      if (row) await updateInstalledDevice(formValues);
      else await createInstalledDevice(formValues);

      enqueueSnackbar("Save successfull", {
        variant: "success"
      });
      toggleAddDialog(formValues);
    } catch (error) {
      return enqueueSnackbar("Something went wrong", {
        variant: "error"
      });
    } finally {
      setProcessing(false);
    }
  };
  const props = {
    onChange: handleChange,
    InputLabelProps: { shrink: true },
    className: classes.textField
  };
  const {
    serialId,
    embeddedId,
    kuvaAppV,
    algoAppV,
    hardwareV,
    hardwareRev,
    osVersion
  } = formValues;
  if (processing)
    return (
      <LoadingDiv>
        <LoadingIndicator />
      </LoadingDiv>
    );
  return (
    <Root id="form" noValidate autoComplete="off">
      <div className={classes.radioDiv}>
        <Typography className={classes.title}>{action}</Typography>
      </div>
      <TextField
        variant="standard"
        disabled={disabled}
        {...props}
        type="string"
        value={serialId}
        label="Serial #"
        name="serialId"
      />
      <TextField
        variant="standard"
        disabled={disabled}
        {...props}
        type="string"
        value={embeddedId}
        label="Apolis #"
        name="embeddedId"
      />
      <TextField
        variant="standard"
        {...props}
        type="number"
        value={kuvaAppV}
        label="KuvaApp version"
        name="kuvaAppV"
      />
      <TextField
        variant="standard"
        {...props}
        type="number"
        value={algoAppV}
        label="AlgoApp version"
        name="algoAppV"
      />
      <TextField
        variant="standard"
        {...props}
        type="number"
        value={hardwareV}
        label="Hardware version"
        name="hardwareV"
      />
      <TextField
        variant="standard"
        {...props}
        type="number"
        value={hardwareRev}
        label="Hardware revision version"
        name="hardwareRev"
      />
      <TextField
        variant="standard"
        {...props}
        type="number"
        value={osVersion}
        label="Os version"
        name="osVersion"
      />
      <KuvaButton value="save" onClick={handleConfirm} />
    </Root>
  );
}
