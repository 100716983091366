import { styled } from "@mui/material/styles";
import { useScanResults } from "ScanResultsContextProvider";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import useResizeObserver from "use-resize-observer";

const PREFIX = "DetectionImage";

const classes = {
  cyanRectangle: `${PREFIX}-cyanRectangle`,
  redRectangle: `${PREFIX}-redRectangle`,
  mainImg: `${PREFIX}-mainImg`
};

const Root = styled("div")({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  height: "100%",
  maxWidth: "auto",
  position: "relative",

  [`.${classes.mainImg}`]: {
    height: "100%",
    maxWidth: "auto",
    border: "1px solid cyan"
  },
  [`.${classes.cyanRectangle}`]: {
    border: "1px solid cyan",
    position: "absolute"
  },
  [`.${classes.redRectangle}`]: {
    border: "1px solid red",
    position: "absolute"
  }
});

const DetectionImage = ({ scanResult, handleFullscreen, redThreshold }) => {
  const [naturalDimen, setNaturalDimen] = useState([0, 0]);
  const [imageDimen, setImageDimen] = useState([0, 0]);
  const [ratio, setRatio] = useState([0, 0]);

  const { showUIDetections, minThreshold } = useScanResults();

  // Whenever the view is resized we have to recalculate the detection boxes
  const { ref } = useResizeObserver({
    onResize: ({ width, height }) => {
      setImageDimen([width, height]);
    }
  });

  // when the jpg dimentions or image view dimentions change we set them into the component state.
  useEffect(() => {
    setRatio(calculateRatio(naturalDimen, imageDimen));
  }, [naturalDimen, imageDimen]);

  /** @function calculateRatio
   * calculates the jpg vs imageview ratio
   *
   *
   * @param {array} naturalDimen - the original width/height
   * @param {array} imageDimen - the new width/height
   *
   * @returns {array} the width/height aspect ratio
   */
  const calculateRatio = (naturalDimen, imageDimen) => {
    if (
      !imageDimen[0] ||
      !imageDimen[1] ||
      !naturalDimen[0] ||
      !naturalDimen[1]
    )
      return [0, 0];
    return [imageDimen[0] / naturalDimen[0], imageDimen[1] / naturalDimen[1]];
  };

  return (
    <Root ref={ref}>
      <img
        className={classes.mainImg}
        src={scanResult.img || scanResult.jpg}
        alt="SWIR"
        onClick={handleFullscreen}
        onLoad={target => {
          setImageDimen([
            target.currentTarget.width,
            target.currentTarget.height
          ]);
          setNaturalDimen([
            target.currentTarget.naturalWidth,
            target.currentTarget.naturalHeight
          ]);
        }}
      />
      {ratio[0] !== 0 &&
        showUIDetections &&
        scanResult.detections?.map(detect => (
          <DetectBox
            key={detect.uuid}
            detection={detect}
            ratio={ratio}
            redThreshold={redThreshold}
            minThreshold={minThreshold}
          />
        ))}
    </Root>
  );
};

DetectionImage.propTypes = {
  scanResult: PropTypes.object.isRequired
};

const DetectBox = ({ detection, ratio, redThreshold, minThreshold }) => {
  /** translates from an XY coordinate system to css attributes we can work with
   * the original object has an 8 integer array representing 4 pairs of X/Y pixel coordinates
   * We translate the four corners to top, left, hieght and width integers and multiply by the aspect ratio.
   */
  const coords = {
    left: detection.roicoords[0] * ratio[0],
    width: (detection.roicoords[2] - detection.roicoords[0]) * ratio[0],
    top: detection.roicoords[1] * ratio[1],
    height: (detection.roicoords[5] - detection.roicoords[1]) * ratio[1]
  };

  if (detection.sumconf > minThreshold) {
    return (
      <Root
        className={
          detection.sumconf > redThreshold
            ? classes.redRectangle
            : classes.cyanRectangle
        }
        style={coords}
      ></Root>
    );
  } else {
    return <div />;
  }
};

DetectBox.propTypes = {
  detection: PropTypes.shape({
    roicoords: PropTypes.array.isRequired
  }),
  ratio: PropTypes.array.isRequired
};

export default DetectionImage;
