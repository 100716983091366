import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";

import ball from "../../assets/ball.gif";
import ball2 from "../../assets/ball2.gif";
import kuvalogo from "../../assets/kuvalogo.png";
import Config from "../../utils/ConfigurationContainer";
import Loading from "../common/Loading";
import MainContent from "../common/MainContent";

const PREFIX = "Landing";

const classes = {
  root: `${PREFIX}-root`,
  landing: `${PREFIX}-landing`,
  ball: `${PREFIX}-ball`,
  button: `${PREFIX}-button`,
  title: `${PREFIX}-title`,
  comment: `${PREFIX}-comment`,
  container: `${PREFIX}-container`,
  spinnerHold: `${PREFIX}-spinnerHold`,
  spinner2: `${PREFIX}-spinner2`,
  kuvalogo: `${PREFIX}-kuvalogo`,
  version: `${PREFIX}-version`
};

const Root = styled("div")({
  [`.${classes.root}`]: {
    height: "100%",
    width: "100%",
    margin: "0px",
    border: 0,
    position: "absolute",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px"
  },
  [`.${classes.landing}`]: {
    textAlign: "center",
    backgroundColor: "black",
    height: "100%",
    width: "100%",
    margin: "0px",
    border: 0,
    position: "absolute",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px"
  },
  [`.${classes.ball}`]: {
    width: "100%",
    height: "80%",
    zIndex: "0"
  },
  [`.${classes.button}`]: {
    color: "black"
  },
  [`.${classes.title}`]: {
    color: "white",
    fontFamily: "monaco,courier",
    fontSize: "20px",
    marginLeft: "30px",
    float: "left",
    zIndex: "1",
    marginTop: "20px"
  },
  [`.${classes.comment}`]: {
    color: "white",
    fontFamily: "monaco,courier",
    fontSize: "13px",
    marginRight: "20px",
    float: "right",
    marginTop: "20px"
  },
  [`.${classes.container}`]: {
    position: "absolute",
    width: "100%",
    zIndex: "1"
  },
  [`.${classes.kuvalogo}`]: {
    height: 100
  },
  [`.${classes.version}`]: {
    color: "white",
    position: "absolute",
    bottom: 5,
    right: 5
  }
});

const SpinnerHold = styled("div")({
  [`.${classes.spinnerHold}`]: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed"
  },
  [`.${classes.spinner2}`]: {
    transform: "translate(-50%, -50%)",
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%"
  }
});

const Landing = ({ login }) => {
  const { loginWithRedirect, isLoading } = useAuth0();

  let [imgSource, setImgSource] = useState(ball);

  if (isLoading) {
    return (
      <SpinnerHold>
        <div className={classes.spinner2}>
          <Loading />
        </div>
      </SpinnerHold>
    );
  }

  return (
    <Root>
      {Config.get("initialized") ? (
        <MainContent />
      ) : (
        <div className={classes.landing}>
          <div className={classes.container}>
            <span className={classes.title}>
              <img
                alt="Kuva Gas Cloud Imaging"
                src={kuvalogo}
                className={classes.kuvalogo}
              />
            </span>
            <Button
              className={classes.comment}
              onClick={() => setImgSource(imgSource === ball ? ball2 : ball)}
            >
              R E S E A R C H &nbsp; A N D &nbsp; D E V E L O P M E N T
            </Button>
          </div>
          <Button className={classes.button}>
            <img
              id="something"
              className={classes.ball}
              src={imgSource}
              alt="loading..."
              onClick={loginWithRedirect}
            />
          </Button>
        </div>
      )}
      <Typography className={classes.version} variant="subtitle2">
        Version {process.env.REACT_APP_VERSION}
      </Typography>
    </Root>
  );
};

Landing.propTypes = {};

export default Landing;
