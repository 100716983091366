export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";
export const AJAX_CALL_SUCCESS = "AJAX_CALL_SUCCESS";

export const FETCH_CAMERAS_SUCCESS = "FETCH_CAMERAS_SUCCESS";
export const UPDATE_CAMERAS_SUCCESS = "UPDATE_CAMERAS";

export const NEW_IOT_MESSAGE = "NEW_IOT_MESSAGE";
export const CLEAR_IOT_MESSAGES = "CLEAR_IOT_MESSAGES";

export const SET_IS_LOADING_CAMERA_ATTR = "SET_IS_LOADING_CAMERA_ATTR";

export const UPSERT_REPORTED_SUCCESS = "UPSERT_REPORTED";
export const FETCH_REPORTED_SUCCESS = "FETCH_REPORTED_SUCCESS";

export const SET_INITIAL_REPORTED_TELEMETRY = "SET_INITIAL_REPORTED_TELEMETRY";
export const UPSERT_REPORTED_TELEMETRY = "UPSERT_REPORTED_TELEMETRY";

export const SET_INITIAL_DEVICE_STATUS = "SET_INITIAL_DEVICE_STATUS";
export const UPSERT_REPORTED_DEVICE_STATUS = "UPSERT_REPORTED_DEVICE_STATUS";

export const SET_INITIAL_SCAN_ACTION_STATUS = "SET_INITIAL_SCAN_ACTION_STATUS";
export const SET_SCAN_ACTION_STATUS = "SET_SCAN_ACTION_STATUS";

export const SET_ILLUMINATE_ACTION_STATUS = "SET_ILLUMINATE_ACTION_STATUS";
export const SET_INITIAL_ILLUMINATE_ACTION_STATUS =
  "SET_INITIAL_ILLUMINATE_ACTION_STATUS";

export const SET_SIGNALR_ISLOADING = "SET_SIGNALR_ISLOADING";
export const SET_SIGNALR_CONNECTION = "SET_SIGNALR_CONNECTION";
export const SET_SUBSCRIPTION_DETAILS = "SET_SUBSCRIPTION_DETAILS";
