import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import Loading from "../common/Loading";

import { ImageButtonBase, Root } from "./SelectCamera.styles";
import {
  ImageBackdrop,
  ImageButton,
  ImageMarked,
  ImageSrc,
  OrgTitle
} from "./common/styles";

const SelectCamera = ({ cameraTwins }) => {
  if (!cameraTwins?.length) {
    return <Loading />;
  }

  return (
    <Root>
      <div style={{ width: "100%" }}>
        <Typography
          variant="h2"
          component="h1"
          color="secondary"
          align="center"
          style={{
            marginTop: "4%",
            color: "white"
          }}
        >
          Welcome
        </Typography>
        <Typography
          align="center"
          variant="h3"
          style={{
            color: "grey",
            marginBottom: "4%"
          }}
        >
          Select a camera to continue
        </Typography>
      </div>
      {cameraTwins.map(cameraTwin => (
        <ImageButtonBase
          component={NavLink}
          to={location => `${location.pathname}/${cameraTwin.deviceId}`}
          focusRipple
          key={cameraTwin.deviceId}
        >
          <ImageSrc />
          <ImageBackdrop />
          <ImageButton>
            <Typography component="span" variant="h5" color="inherit">
              {cameraTwin.name}
              <ImageMarked />
            </Typography>
          </ImageButton>
          <OrgTitle component="span" variant="subtitle1">
            {cameraTwin.deviceId}
          </OrgTitle>
        </ImageButtonBase>
      ))}
    </Root>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    cameraTwins: state.cameras
  };
};

SelectCamera.propTypes = {
  cameraTwins: PropTypes.array.isRequired
};

export default connect(mapStateToProps)(SelectCamera);
