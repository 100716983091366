import DeviceApi from "~/apis/DeviceApi";

export const getAllInstalledDevices = async () => {
  try {
    const response = await DeviceApi.getAllInstalledDevices();
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createInstalledDevice = async installedDevice => {
  try {
    await DeviceApi.createInstalledDevice(installedDevice);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const updateInstalledDevice = async installedDevice => {
  try {
    await DeviceApi.updateInstalledDevice(installedDevice);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteInstalledDevice = async ({ serialId, embeddedId }) => {
  try {
    await DeviceApi.deleteInstalledDevice(serialId, embeddedId);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
