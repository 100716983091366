import axios from "axios";

import CameraAPI from "../apis/CameraAPI";
import Camera from "../models/Camera";

import * as types from "./actionTypes";
import { beginAjaxCall } from "./ajaxStatusActions";

export function fetchCamerasSuccess(cameras) {
  return { type: types.FETCH_CAMERAS_SUCCESS, cameras };
}

export function insertNewIoTMessage(message) {
  return { type: types.NEW_IOT_MESSAGE, message };
}

export function updateCamerasSuccess(camera) {
  return { type: types.UPDATE_CAMERAS_SUCCESS, camera };
}

export function fetchReportedSuccess(reported) {
  return { type: types.FETCH_REPORTED_SUCCESS, reported };
}

export function updateReportedAttributesSuccess(reported) {
  return { type: types.UPSERT_REPORTED_SUCCESS, reported };
}

export function toggleIlluminator(token, which) {
  const trigger =
    process.env.NODE_ENV === "production" // TODO: (S.W) this doesn't seem right, it should be calling it via the API
      ? "https://mss-camera-api.azurewebsites.net/v1/ToggleIlluminatorHttpTrigger"
      : "http://localhost:7071/v1/ToggleIlluminatorHttpTrigger";
  return new Promise((resolve, reject) => {
    axios
      .get(trigger, {
        params: {
          code: "U57Xgf/mBskT71mKhLjpaCLE/DnahoTnfQzXSr/FAxvLGVj/uymBkQ==",
          deviceId: which
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

/**
 * @export function getCamerasAction
 * @param {string} which - the device id for a camera to be fetched,
 * if null, returns all cameras
 *
 * sets an array of camera class instances in the redux store
 */
export function getCamerasAction(orgId, which) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());

    dispatch({
      type: "SET_IS_LOADING_CAMERA_ATTR",
      isLoading: true
    });

    CameraAPI.get(orgId, which)
      .then(response => {
        if (response.data) {
          const cameras = response.data.map(c => new Camera(c));

          dispatch(fetchCamerasSuccess(cameras));

          let deviceStatus = {};
          let deviceAction = {};

          response.data.map(c => {
            const status = {
              [c.deviceId]: { connectionState: c.connectionState }
            };
            deviceStatus = { ...deviceStatus, ...status };

            const defaultActionState = {
              [c.deviceId]: { isLoading: false, awaitingResponse: false }
            };
            deviceAction = { ...deviceAction, ...defaultActionState };
          });

          /* Dispatch only once */
          /*###########################################################################################*/
          // [ModuleTwin] Set initial Reported Attribute - it will be updated via signalR service
          // TODO (Jan): move this to module twin action once the API is available
          // dispatch(fetchReportedSuccess(initialReported))

          // Set initial device status - it will be updated via signalR service
          dispatch({ type: "SET_INITIAL_DEVICE_STATUS", deviceStatus });

          // Set initial scan action status - it will be updated via signalR service
          dispatch({
            type: "SET_INITIAL_SCAN_ACTION_STATUS",
            deviceAction
          });
          // Set initial illuminate action status - it will be updated via signalR service
          dispatch({
            type: "SET_INITIAL_ILLUMINATE_ACTION_STATUS",
            deviceAction
          });
        }
      })
      .catch(error => {
        console.error(error);
        //TODO: set application error
        throw error;
      })
      .finally(() =>
        dispatch({
          type: "SET_IS_LOADING_CAMERA_ATTR",
          isLoading: false
        })
      );
  };
}

/**
 *
 * @description this function is called when we want to update the name, location, orgId etc...
 * @author Sean W.
 * @date 16/10/2021
 * @export function setCameraReportedAttributes
 * @param {object} newAttributes - the attrributes to update sent from the iot hub
 *
 * Check if the reported properties changed
 * finds the camera object instance  these properties belong too
 * updates the camera object instance properties
 * sends the camera object instance  to redux to update the store
 *
 */
export function setCameraReportedAttributes(newAttributes) {
  return function (dispatch, getState) {
    // get the data from the message
    console.log(
      "saving new camera attributes for device: ",
      newAttributes.deviceId
    );

    //send the updated message to redux to handle
    dispatch(updateReportedAttributesSuccess(newAttributes));
  };
}

/**
 *
 *
 * @description this function is called to set the camera as connected
 * @author Sean W.
 * @date 16/10/2021
 * @export function setCameraLastHeartbeat
 * @param {object} attr - the attrributes to update sent from the iot hub
 *
 * finds the camera object instance  these properties belong too
 * updates the camera object instance properties
 * sends the camera object instance  to redux to update the store
 *
 */
export function setCameraLastHeartbeat(attr) {
  return function (dispatch, getState) {
    console.log("setCameraLastHeartbeat", attr);

    // get the data from the message
    const deviceId = attr.deviceId;
    // find the camera instance to update
    const cameras = getState().cameras;
    if (cameras?.length) {
      const toUpdate = cameras.find(cam => cam.deviceId === deviceId);
      if (toUpdate !== undefined && toUpdate !== null) {
        // update the camera instance
        const attributes = toUpdate.updateHeartbeat(attr);
        console.log("updateHeartbeat attributes:", attributes);
        const updated = { ...toUpdate, ...attributes };

        //send the updated camera instance back to redux to handle
        dispatch(updateCamerasSuccess(updated));
      }
    }
  };
}
/**
 * @description this function is called when we want to update the name, location, orgId etc...
 * @author Sean W.
 * @date 16/10/2021
 * @export function updateCameraAttributes
 * @param {string} deviceId
 * @param {object} attr
 * @return {function} the dispatch function
 */
export function updateCameraAttributes(deviceId, attr) {
  return function (dispatch, getState) {
    // find the camera instance to update
    const cameras = getState().cameras;
    if (cameras?.length) {
      const toUpdate = cameras.find(cam => cam.deviceId === deviceId);
      // update the camera instance
      console.log("VALUES: ", attr);
      const attributes = toUpdate.updateCameraAttributes(attr);
      const updated = { ...toUpdate, ...attributes };
      //send the updated camera instance back to redux to handle
      dispatch(updateCamerasSuccess(updated));
    }
  };
}

/**
 * @name toggleCameraScanAction
 * @description this function is a middleware function that
 * 1. calls the toggleCameraScan api endpoint
 * 2. dispatches an action to redux to update it with the new data
 *
 * @author Jan A.
 * @date 26/09/2022
 *
 * @param deviceId - the id of the camera
 *
 * @returns {function}
 */
export const toggleCameraScanAction = deviceId => {
  return async dispatch => {
    dispatch({
      type: "SET_SCAN_ACTION_STATUS",
      deviceAction: { [deviceId]: { isLoading: true, awaitingResponse: false } }
    });
    return CameraAPI.toggleCameraScan(deviceId)
      .then(response => {
        if (response.status === 200) {
          // const shift = response.data
          dispatch({
            type: "SET_SCAN_ACTION_STATUS",
            deviceAction: {
              [deviceId]: { isLoading: false, awaitingResponse: true }
            }
          });
          return response;
        }
      })
      .catch(error => {
        console.log("toggleCameraScan:", error);
      })
      .finally(() => {
        dispatch({
          type: "SET_SCAN_ACTION_STATUS",
          deviceAction: { [deviceId]: { isLoading: false } }
        });
      });
  };
};

/**
 * @name toggleIlluminatorAction
 * @description this function is a middleware function that
 * 1. calls the toggleIlluminatorAction api endpoint
 * 2. dispatches an action to redux to update it with the new data
 *
 * @author Jan A.
 * @date 26/09/2022
 *
 * @param deviceId - the id of the camera
 *
 * @returns {function}
 */
export const toggleIlluminatorAction = deviceId => {
  return async dispatch => {
    dispatch({
      type: "SET_ILLUMINATE_ACTION_STATUS",
      deviceAction: { [deviceId]: { isLoading: true, awaitingResponse: false } }
    });
    return CameraAPI.toggleIlluminator(deviceId)
      .then(response => {
        if (response.status === 200) {
          // const shift = response.data
          dispatch({
            type: "SET_ILLUMINATE_ACTION_STATUS",
            deviceAction: {
              [deviceId]: { isLoading: false, awaitingResponse: true }
            }
          });
          return response;
        }
      })
      .catch(error => {
        console.log("toggleIlluminator:", error);
      })
      .finally(() => {
        dispatch({
          type: "SET_ILLUMINATE_ACTION_STATUS",
          deviceAction: { [deviceId]: { isLoading: false } }
        });
      });
  };
};
