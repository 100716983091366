export const selectIsLoading = state => {
  return state["deviceAttribute"]?.isLoading;
};

export const selectAttrReported = state => {
  return state["deviceAttribute"]?.reported;
};

export const selectAttrReportedByDeviceId = (state, deviceId) => {
  return (
    state["deviceAttribute"]?.reported?.find(r => r.deviceId === deviceId) || {}
  );
};

export const selectTelemetry = state => {
  return state["deviceAttribute"]?.telemetry;
};

export const selectDeviceStatus = state => {
  return state["deviceAttribute"]?.deviceStatus;
};

export const selectScanActionStatus = state => {
  return state["deviceAttribute"]?.deviceAction.scan;
};

export const selectIlluminateActionStatus = state => {
  return state["deviceAttribute"]?.deviceAction.illuminate;
};
