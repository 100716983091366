import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
const PREFIX = "PipelineSettings";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};

const Root = styled("div")(() => ({
  [`.${classes.root}`]: {
    width: "100%"
  },

  [`.${classes.container}`]: {
    width: "100%"
  }
}));

const PipelineSettings = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting
  /* and other goodies */
}) => {
  const [state, setState] = React.useState({
    overlay_on_color: values.overlay_on_color,
    overlay_on_rgbcam: values.overlay_on_rgbcam,
    include_od_stats: values.include_od_stats,
    debug: values.debug
  });

  const handleSwitchChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
    handleChange(event);
  };

  return (
    <Root className={classes.root}>
      <FormControl
        variant="standard"
        component="fieldset"
        className={classes.container}
      >
        <FormLabel component="legend"> Pipeline Settings</FormLabel>
        <FormGroup>
          {/* <FormControlLabel
            control={
              <Switch
                id="overlay_on_color"
                checked={state.overlay_on_color}
                onChange={handleSwitchChange}
                name="overlay_on_color"
                color="primary"
              />
            }
            label="Color overlay"
          /> */}

          <FormControlLabel
            control={
              <Switch
                id="overlay_on_rgbcam"
                checked={state.overlay_on_rgbcam}
                onChange={handleSwitchChange}
                name="overlay_on_rgbcam"
                color="primary"
              />
            }
            label="RGB Camera Overlay"
          />
          <FormControlLabel
            control={
              <Switch
                id="include_od_stats"
                checked={state.include_od_stats}
                onChange={handleSwitchChange}
                name="include_od_stats"
                color="primary"
              />
            }
            label="Include OD Stats"
          />
          <FormControlLabel
            control={
              <Switch
                id="debug"
                checked={state.debug}
                onChange={handleSwitchChange}
                name="debug"
                color="primary"
              />
            }
            label="Debug"
          />
        </FormGroup>
        <FormHelperText>Be careful</FormHelperText>
      </FormControl>
    </Root>
  );
};

PipelineSettings.propTypes = {
  submit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    overlay_on_color: PropTypes.bool.isRequired,
    include_od_stats: PropTypes.bool.isRequired,
    debug: PropTypes.bool.isRequired
  })
};

export default PipelineSettings;
