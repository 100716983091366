import update from "react-addons-update";

import * as types from "../actions/actionTypes";

import initialState from "./initialState";

export default function camerasReducer(state = initialState.cameras, action) {
  switch (action.type) {
    case types.FETCH_CAMERAS_SUCCESS:
      return action.cameras;
    case types.UPDATE_CAMERAS_SUCCESS:
      return update(
        state.filter(cam => cam.deviceId !== action.camera.deviceId),
        { $push: [action.camera] }
      );
    default:
      return state;
  }
}
