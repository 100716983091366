import { List } from "@mui/material";
import { styled } from "@mui/system";

const PREFIX = "CameraSelectNav";

export const classes = {
  drawerIcon: `${PREFIX}-drawerIcon`,
  drawerIconRight: `${PREFIX}-drawerIconRight`,
  nestedListItem: `${PREFIX}-nestedListItem`,
  selected: `${PREFIX}-selected`,
  small: `${PREFIX}-small`
};

export const Root = styled(List)(({ theme }) => ({
  width: "100%",

  "& .selected $cameraTitle": {
    color: theme.palette.primary.main
  },

  [`.${classes.drawerIcon}`]: {
    color: "inherit",
    minWidth: 40
    // ".selected": {
    //   color: "#FFF  !important"
    // }
  },
  [`.${classes.drawerIconRight}`]: {
    color: "white",
    paddingLeft: 50
  },
  [`.${classes.nestedListItem}`]: {
    paddingLeft: theme.spacing(4),
    backgroundColor: "inherit"
  },
  [`.${classes.selected}`]: {
    color: theme.palette.primary.main
  },
  [`.${classes.small}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}));

export const LoadingHolder = styled("div")({
  position: "relative",
  width: "100%",
  height: 100,
  textAlign: "center"
});
