import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

import { Root, classes } from "./Styles";

const HorizontalScrollSwirs = ({ setSwirById, isSelected, all }) => {
  const [loadedImages, setLoadedImages] = useState(0);
  const [loadImagesUptoIndex, setLoadImagesUptoIndex] = useState(0);
  // whenever an image loads increment the counter and if it's the selected one,
  //set the dimentions to fit this image
  const onImageLoad = () => {
    setLoadedImages(loaded => loaded + 1);
  };

  //if the image doesn't load increment the counter so loading doesn't get stuck
  const onImageError = err => {
    setLoadedImages(loaded => loaded + 1);
    // console.log("image load err",err)
  };

  // // This effect calculates how many more images to load when an image finishes loading
  // useEffect(() => {
  //   // console.log("loadedImages", loadedImages);
  //   //when image is loaded set 5 more images to be loaded
  //   // in increments of 5
  //   //eg: n=6   result=15,  n=10   result=20
  //   const max = (parseInt(loadedImages / 5) + 2)*5;
  //   setLoadImagesUptoIndex(max);
  // }, [loadedImages]);

  return (
    <Root>
      <div className={classes.imgRow}>
        {/* Add buffer for performance issues  */}
        {all.slice(0, loadedImages + 3).map((item, i) => (
          <img
            key={i}
            className={isSelected(item.id) ? classes.selectedImg : classes.img}
            src={item.img || item.jpg}
            alt="Thumbnail"
            onClick={setSwirById.bind(null, item.id)}
            onLoad={onImageLoad}
            onError={onImageError}
          />
        ))}
      </div>
      <div className={classes.loadingStatus}>
        <Typography variant="body2" className={classes.loadingStatusFont}>
          {`Loading ${loadedImages}/${all.length}`}
        </Typography>
      </div>
    </Root>
  );
};

HorizontalScrollSwirs.propTypes = {
  setSwirById: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  all: PropTypes.array.isRequired
};

export default HorizontalScrollSwirs;
