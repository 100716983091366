export const kuvaColors = {
  kuvaOrange: "#FF6A14",
  kuvaGray: "#C8C9C7",
  kuvaBlack: "#2D2926",
  kuvaTurquoise: "#00A499",
  kuvaLightGreen: "#97D700",
  kuvaMagenta: "#D00070",
  kuvaPurple: "#84329B",
  kuvaPrimaryLightOrange: "#FFB81C",
  kuvaPrimaryMainOrange: "#ffa41c",
  kuvaPrimaryDarkOrange: "#ff911c",
  kuvaBlue: "#004B87",
  kuvaLightBlue: "#00A9E0"
};

export const darkTheme = {
  palette: {
    mode: "dark",
    primary: {
      light: "#FFB81C",
      main: "#ffa41c",
      dark: "#ff911c",
      contrastText: "#fff"
    },
    secondary: {
      light: "#03ffff",
      main: "#05ebeb",
      dark: "#02c0c7",
      contrastText: "#000"
    },
    background: {
      paper: "#242E3B"
    },
    text: {
      primary: "#fff",
      secondary: "#ccc"
    },
    divider: "rgba(255, 255, 255, 0.12)"
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#fff"
        }
      }
    }
  }
};
