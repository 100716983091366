import axios from "axios";

/********** Special note!!! **********
 * This itterator is only here because the js protobuf adds the suffex "List" to array properties for some silly reason.
 * Untill there's a better solution, all cosmos results objects should be cleaned of that pesky word
 */
const iterate = (obj, stack, prevType) => {
  for (let property in obj) {
    if (Array.isArray(obj[property])) {
      //console.log(property , "(L="  + obj[property].length + ") is an array  with parent ", prevType, stack);
      iterate(obj[property], stack + property, "array");
      if (property.endsWith("List")) {
        obj[property.substring(0, property.length - 4)] = obj[property];
        delete obj[property];
      }
    } else {
      if (
        typeof obj[property] !== "string" &&
        typeof obj[property] !== "number"
      ) {
        if (prevType === "array") {
          //console.log(stack + "["  + property + "] is an object, item of " , prevType, stack);
          iterate(obj[property], stack + "[" + property + "].", "object");
        } else {
          //console.log(stack +    property  , "is " , typeof obj[property] , " with parent ", prevType, stack );
          iterate(obj[property], stack + property + ".", "object");
        }
      }
    }
  }
  return obj;
};

class AlgoAPI {
  constructor() {
    console.log("OrganizationAPI constructor");
    this.baseTime = new Date();
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_KUVA_API_URL}/algo/v1`,
      timeout: 30000
    });
  }

  static instance = null;

  static Instance = () => {
    if (!this.instance) {
      this.instance = new AlgoAPI();
    }
    return this.instance;
  };

  // Allow auth header to be initialized after login
  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  getBaseURL = () => this.axiosInstance.defaults.baseURL;

  setBaseURI = url => {
    if (this.axiosInstance) {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
      console.log(
        "Algorithms url set to: ",
        this.axiosInstance.defaults.baseURL
      );
    }
  };

  setBaseOrgHeader = baseOrg => {
    this.axiosInstance.defaults.headers.common["X-Organization"] = baseOrg;
  };

  // This will cancel the previous call before exicuting a new one.
  // TODO: make this into an array of calls that we can loop through and cancle each time.
  makeRequestCreator = () => {
    let call;
    return (
      camera,
      blobContainer,
      startDate,
      endDate,
      showDetections = false,
      minThreshold = 0,
      showNonDetections = false,
      dontInterrupt = true
    ) => {
      if (call && !dontInterrupt) {
        call.cancel();
        throw "canceled";
      }
      call = axios.CancelToken.source();

      console.time("getScanResults");
      console.log(
        `%c calling: ${this.baseURL}/swirs?camera=${camera}&container=${blobContainer}`,
        "color: #9954E3"
      );

      return new Promise((resolve, reject) => {
        this.axiosInstance
          .get(`swirs/${camera}/${blobContainer}`, {
            cancelToken: call.token,
            params: {
              ...(startDate ? { start: startDate } : {}),
              ...(endDate ? { end: endDate } : {}),
              ...{ detections: showDetections },
              ...{ minThreshold: minThreshold || 0 },
              ...{ nonDetections: showNonDetections }
            },
            timeout: 60000
          })
          .then(res => {
            if (res.data) {
              iterate(res.data, "", "Array");
              // console.log("\n\ncleaned Obj:\n\n", cleanedObject);
            }

            console.timeEnd("getScanResults");
            resolve(res);
          })
          .catch(err => {
            console.timeEnd("getScanResults");
            if (axios.isCancel(err)) {
              console.log("Request canceled", err);
            } else {
              // handle error
              reject(err);
            }
          });
      });
    };
  };

  //TODO: change to ScanResults
  getScanResults = this.makeRequestCreator();

  deleteFrames = (camera, uuids, fileNames) => {
    console.log("delete raw frames", uuids);
    console.log("delete raw frames", fileNames);
    console.log(`calling: ${this.baseURL}/raw`);

    return this.axiosInstance.delete(`raw/${camera}`, {
      data: {
        uuids,
        fileNames
      }
    });
  };

  deleteProcessedFrames = (camera, uuids, fileNames) => {
    console.log("delete processed frames", uuids);
    console.log("delete processed frames", fileNames);
    console.log(`calling: ${this.baseURL}/processed`);

    return this.axiosInstance.delete(`processed/${camera}`, {
      data: {
        uuids,
        fileNames
      }
    });
  };

  processSwirAndDetect = (camera, startDate, endDate, settings, poi) => {
    console.log(
      `Create scan results from date range ${startDate}-${endDate} for camera: ${camera}, settings: ${settings}`
    );

    return this.axiosInstance.post(
      `${camera}/procnew?start=${startDate}&end=${endDate}&poi=${poi}`,
      { settings },
      {
        timeout: 60 * 1000 * 10
      }
    );
  };

  createSwirsFromFrames = (camera, frames, settings) => {
    console.log(
      `Create swirs from frames, camera: ${camera}, settings: ${settings}, frames: `,
      frames
    );
    return this.axiosInstance.post(
      `${camera}/process`,
      {
        frames,
        settings
      },
      {
        timeout: 60 * 1000 * 10
      }
    );
  };

  createScanResultsFromSwirs = (camera, swirs, settings) => {
    console.log(
      `Create results from swirs, camera: ${camera}, settings: ${settings}, swirs: `,
      swirs
    );

    return this.axiosInstance.post(
      `${camera}/detect`,
      {
        swirs,
        settings
      },
      {
        timeout: 60 * 1000 * 10
      }
    );
  };
  uploadOfflineSCRESFiles = formData => {
    console.log("Upload scan results to cloud: ", formData);

    return this.axiosInstance.post("uploadscres", formData, {
      timeout: 60 * 1000 * 10 //10 minutes timeout!!!
    });
  };

  quantifyFrames = (camera, start, end, settings, poi = null, inputType) => {
    console.log(
      `Quantify Data from date range ${start}-${end} for camera: ${camera}, settings: ${settings}`
    );

    return this.axiosInstance.post(
      `${camera}/quantify`,
      { settings },
      {
        params: {
          start,
          end,
          inputtype: inputType,
          poi
        },
        timeout: 60 * 1000 * 10 //10 minutes timeout!!!
      }
    );
  };

  downloadFrames = (camera, formData) => {
    console.log(`Downloading frames for ${camera}`);

    return this.axiosInstance.post(`scans/${camera}/download`, formData, {
      responseType: "blob",
      timeout: 60 * 1000 * 10 //10 minutes timeout!!!
    });
  };
}

export default AlgoAPI.Instance();
