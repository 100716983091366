import { combineReducers } from "redux";

import ajaxCallsInProgress from "./ajaxStatusReducer";
import cameras from "./camerasReducer";
import deviceAttribute from "./deviceReportedAttrReducer";
import iotMessages from "./iotMessagesReducer";
import signalR from "./SignalReducer";

const rootReducer = combineReducers({
  ajaxCallsInProgress,
  cameras,
  deviceAttribute,
  iotMessages,
  signalR
});

export default rootReducer;
