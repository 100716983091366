import { styled } from "@mui/system";

export const Root = styled("div")({
  width: "100%",
  height: "100vh",
  background: "linear-gradient(to bottom, rgb(58,58,58), #0a0a0a 20%)",
  flex: "1"
});

export const Spinner = styled("div")({
  width: "100%",
  height: "100%",
  textAlign: "center"
});
