import { styled } from "@mui/system";
const PREFIX = "SerialForm";

export const classes = {
  radioDiv: `${PREFIX}-radioDiv`,
  textField: `${PREFIX}-textField`,
  title: `${PREFIX}-title`,
  item: `${PREFIX}-item`
};

export const Root = styled("form")({
  display: "grid",
  padding: "10px 26px",
  borderRadius: 4,
  background: "black",
  textAlign: "center",
  marginLeft: 30,
  width: 450,

  [`.${classes.radioDiv}`]: {
    placeSelf: "center"
  },
  [`.${classes.textField}`]: {
    padding: "5px 0",
    margin: "5px 0"
  },
  [`.${classes.title}`]: {
    fontSize: 20
  },
  [`.${classes.item}`]: {
    cursor: "pointer"
  }
});

export const LoadingDiv = styled("div")({
  width: 450,
  height: 450
});
